import React, { useState, useEffect, useRef, ReactElement } from 'react';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';

import { Toolbar2 as Toolbar } from '../Toolbars2/Toolbar2';
import * as toolbarSelectors from '../../../reducers/toolbarReducer';

import classnames from 'classnames';

import styles from './Toolbar.module.scss';
import ReactDOM from 'react-dom';
import { getMatrixTransform3D } from 'components/base/Popover';
import { setCursorOnToolbar } from 'actions/toolbarActions';
import { getDevice } from 'reducers/uiReducer';

type ToolbarProps = {
  isBorderShown: boolean;
  renderItems: () => void;
  hideTrayTop: boolean;
  toolbarActive: boolean;
  label: string | ReactElement;
  drawToolbar: boolean;
  shouldOnlyShowHover: boolean;
  toolbarType?: string;
  mobileLayout?: string;
};

export default function (props: ToolbarProps) {
  const {
    isBorderShown,
    renderItems,
    hideTrayTop,
    toolbarActive,
    label,
    drawToolbar,
    shouldOnlyShowHover,
    toolbarType,
    mobileLayout,
  } = props;
  const [trayOpen, setTrayOpen] = useState(false);
  const [boxOverlayRef, setReferenceElement] = useState<Element | PopperJS.VirtualElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector((state) => toolbarSelectors.getIsDrawerOpen(state));
  const device = useSelector(getDevice);

  useEffect(() => {
    if (isDrawerOpen) setTrayOpen(false);
  }, [isDrawerOpen]);

  let {
    styles: popperStyles,
    attributes,
    forceUpdate,
  } = usePopper(boxOverlayRef, popperElement, {
    placement: 'top',
  });

  useEffect(() => {
    if (forceUpdate) forceUpdate();
  }, [device]);

  const iframe = document.getElementById('editorIframe');
  if (popperStyles.popper.transform && iframe && popperElement) {
    let left = document.body.clientWidth / 2 - popperElement.getBoundingClientRect().width / 2 + 'px';
    if (!['mobile', 'tablet'].includes(device)) {
      const vals = getMatrixTransform3D(popperStyles.popper.transform);
      const iFrameOffsetLeft = (document.body.clientWidth - iframe.getBoundingClientRect().width) / 2;
      left = vals.x + iFrameOffsetLeft + 'px';
    }

    popperStyles.popper = {
      top:
        device === 'desktop'
          ? boxOverlayRef.getBoundingClientRect().top - 32
          : boxOverlayRef.getBoundingClientRect().top + 6,
      left,
    };
  }

  if (forceUpdate) {
    (window as any).forceToolbarUpdate = forceUpdate;
  }

  const containerRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (device === 'mobile' && mobileLayout === 'slider') {
      const parentElement = containerRef.current?.parentNode as HTMLElement;
      const grandParentElement = parentElement?.parentNode as HTMLElement;
      if (grandParentElement?.classList.contains('products')) {
        grandParentElement.parentNode?.appendChild(containerRef.current);
      }
    }
  }, [device, mobileLayout]);

  return (
    <span ref={containerRef} style={{ display: toolbarType === 'collection' ? 'contents' : '' }}>
      <div
        ref={setReferenceElement}
        className={classnames(styles.boxOverlay, {
          [styles.isBorderShown]: isBorderShown && !toolbarActive,
          [styles.toolbarActive]: toolbarActive,
          [styles.mobile]: ['mobile', 'tablet'].includes(device),
        })}
      />
      {(toolbarActive || isBorderShown) && !['mobile', 'tablet'].includes(device) && (
        <div className={classnames(styles.boxOverlayOuter)} />
      )}
      {(toolbarActive || isBorderShown) && (
        <div
          className={classnames(styles.boxOverlayInner, { [styles.mobile]: ['mobile', 'tablet'].includes(device) })}
        />
      )}
      {(toolbarActive || isBorderShown) && (
        <div
          className={classnames(styles.label, {
            [styles.toolbarActive]: toolbarActive,
            [styles.mobile]: ['mobile', 'tablet'].includes(device),
          })}
        >
          <div className={classnames(styles.innerContent)}>{label}</div>
        </div>
      )}
      <div
        ref={(ref) => {
          if (!boxOverlayRef || !ref || ref.clientWidth > window.innerWidth) {
            return;
          }
          const left = Math.max(ref.getBoundingClientRect().width / 4, boxOverlayRef.getBoundingClientRect().width / 2);
          ref.style.left = `${left}px`;
          const newBoundingRect = ref.getBoundingClientRect();
          const rightmostPos = newBoundingRect.x + ref.offsetWidth;
          if (newBoundingRect.x < 0) {
            ref.style.left = `${left + newBoundingRect.x * -1}px`;
          } else if (rightmostPos > window.innerWidth) {
            const diff = rightmostPos - window.innerWidth;
            ref.style.left = `${left - diff}px`;
          }
        }}
        className={classnames(
          styles.container,
          { [styles.isBorderShown]: isBorderShown },
          { [styles.isTrayOpen]: trayOpen },
          { [styles.toolbarActive]: toolbarActive }
        )}
      >
        {toolbarActive &&
          drawToolbar &&
          !shouldOnlyShowHover &&
          ReactDOM.createPortal(
            <div
              style={{
                ...popperStyles.popper,
                zIndex: 4,
                position: 'absolute',
                marginTop: toolbarType === 'collection' ? '54px' : '6px',
                marginBottom: '60px',
                ...(iframe ? { transform: 'none' } : {}),
              }}
              {...attributes.popper}
              ref={setPopperElement}
              className={styles.toolbarWrapper}
              data-test-id="box-toolbar"
              onMouseEnter={() => dispatch(setCursorOnToolbar(true))}
              onMouseLeave={() => dispatch(setCursorOnToolbar(false))}
            >
              {
                <Toolbar
                  hideTrayTop={hideTrayTop}
                  renderItems={renderItems}
                  setTrayOpen={setTrayOpen}
                  toolbarType={toolbarType}
                />
              }
            </div>,
            document.body
          )}
      </div>
    </span>
  );
}
