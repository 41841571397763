// App settings
// ----------------------------------------------------------------------------

export const SPARK_TLD = process.env.REACT_APP_SPARK_TLD || 'unstack.com';

export const SPARK_API_BASE_URL = process.env.REACT_APP_SPARK_API_BASE_URL || 'https://api.unstack.com/api/v1/';

export const TYPEKIT_PROXY_URL = process.env.REACT_APP_TYPEKIT_PROXY;

export const UNSPLASH_API_BASE_URL = process.env.REACT_APP_UNSPLASH_API_BASE_URL;

export const UNSPLASH_API_KEY = process.env.REACT_APP_UNSPLASH_API_KEY;

export const ICONS8_IMG_DOWNLOAD_URL = process.env.REACT_APP_ICONS8_IMG_DOWNLOAD_URL;

export const ICONS8_API_BASE_URL = process.env.REACT_APP_ICONS8_API_BASE_URL;

export const PEXELS_API_BASE_URL = process.env.REACT_APP_PEXELS_SEARCH;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export const HUBSPOT_CLIENT_ID = process.env.REACT_APP_HUBSPOT_CLIENT_ID;

export const HUBSPOT_REDIRECT_URI = process.env.REACT_APP_HUBSPOT_REDIRECT_URI;

export const HUBSPOT_OAUTH_URL = `https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_CLIENT_ID}&redirect_uri=${HUBSPOT_REDIRECT_URI}&scope=contacts content forms`;

export const MAILCHIMP_CLIENT_ID = process.env.REACT_APP_MAILCHIMP_CLIENT_ID;

export const MAILCHIMP_REDIRECT_URI = process.env.REACT_APP_MAILCHIMP_REDIRECT_URI;

export const MAILCHIMP_OAUTH_URL = `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${MAILCHIMP_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  MAILCHIMP_REDIRECT_URI
)}`;

export const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID;

export const STRIPE_REDIRECT_URI = process.env.REACT_APP_STRIPE_REDIRECT_URI;

export const STRIPE_OAUTH_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${STRIPE_REDIRECT_URI}`;

export const GOOGLE_SC_CLIENT_ID = process.env.REACT_APP_GOOGLE_SC_CLIENT_ID;

export const GOOGLE_SC_REDIRECT_URI = process.env.REACT_APP_GOOGLE_SC_REDIRECT_URI;

export const GOOGLE_SC_OAUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_SC_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  GOOGLE_SC_REDIRECT_URI
)}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fwebmasters.readonly&prompt=consent&access_type=offline&response_type=code`;

export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;

export const SUBSCRIPTION_PLANS = ['upstart', 'solopreneur', 'professional', 'enterprise'];

export const CUSTOM_INTEGRATION_ID = 'custom'; //custom integration id

export const PROTOCOL = window.location.protocol;

// Shopify settings
// ----------------------------------------------------------------------------
export const SHOPIFY_APP_NAME = process.env.REACT_APP_SHOPIFY_APP_NAME;
export const SHOPIFY_CLIENT_ID = process.env.REACT_APP_SHOPIFY_CLIENT_ID;

// Shopify Klevu settings
// ----------------------------------------------------------------------------
export const SHOPIFY_KLEVU_APP_NAME = process.env.REACT_APP_SHOPIFY_KLEVU_APP_NAME;
export const SHOPIFY_KLEVU_CLIENT_ID = process.env.REACT_APP_SHOPIFY_KLEVU_CLIENT_ID;
