import { useSelector } from 'react-redux';
import { selectActiveSite, getIsDnsPendingFromSite } from 'reducers/sitesReducer';
import { selectAccountMetadata } from 'reducers/accountReducer';
import {
  INTEGRATE_UNSTACK_COMPLETE,
  CREATE_OR_EDIT_FIRST_PAGE_COMPLETE,
  CUSTOMIZE_BRANDING_COMPLETE,
  CONFIRM_DOMAIN_SETTINGS_COMPLETE,
  DISMISS_QUICKSTART,
  INVITE_COLLABORATORS_COMPLETE,
  CONFIRM_DOMAIN_SETTINGS_PENDING,
  CUSTOMIZE_BRANDING_PENDING,
  WEBINAR_BANNER_COMPLETE,
  WEBINAR_BANNER_PENDING,
} from 'components/AdminPanel/Quickstart/constants';

export function getIsCustomizeBrandingComplete() {
  const site = useSelector(selectActiveSite) || {};
  const { metadata: { onboarding = [] } = {} } = site;
  if (onboarding.includes(CUSTOMIZE_BRANDING_COMPLETE)) {
    return true;
  }
  if (onboarding.includes(CUSTOMIZE_BRANDING_PENDING)) {
    return false;
  }
  return Boolean(site?.elements?.logo?.src) && Boolean(site?.elements?.logo_inverted?.src);
}

export function getIsWebinarBannerComplete() {
  const site = useSelector(selectActiveSite) || {};
  const { metadata: { onboarding = [] } = {} } = site;
  return onboarding?.includes(WEBINAR_BANNER_COMPLETE);
}

export function getIsFirstPageEditedOrCreated() {
  const accountMetadata = useSelector(selectAccountMetadata);
  return accountMetadata.onboarding?.includes(CREATE_OR_EDIT_FIRST_PAGE_COMPLETE);
}

export function getIsCollaboratorInvited() {
  const accountMetadata = useSelector(selectAccountMetadata);

  return accountMetadata.onboarding?.includes(INVITE_COLLABORATORS_COMPLETE);
}

export function getAreDomainSettingsConfirmed() {
  const activeSite = useSelector(selectActiveSite);
  if (activeSite?.metadata?.onboarding?.includes(CONFIRM_DOMAIN_SETTINGS_COMPLETE)) {
    return true;
  }
  if (activeSite?.metadata?.onboarding?.includes(CONFIRM_DOMAIN_SETTINGS_PENDING)) {
    return false;
  }
  const isDnsPending = activeSite?.domain && getIsDnsPendingFromSite(activeSite);
  return !isDnsPending;
}

export function getAreIntegrationsComplete() {
  const accountMetadata = useSelector(selectAccountMetadata);
  return accountMetadata.onboarding?.includes(INTEGRATE_UNSTACK_COMPLETE);
}

export function getIsQuickstartDismissed() {
  const accountMetadata = useSelector(selectAccountMetadata);
  return accountMetadata.onboarding?.includes(DISMISS_QUICKSTART);
}

export function getShouldRedirectToQuickstart() {
  const isQuickstartDismissed = getIsQuickstartDismissed();
  const pendingSteps = getPendingQuickstartStepsCount();
  if (isQuickstartDismissed) return false;
  return pendingSteps > 0;
}

export function getPendingQuickstartStepsCount() {
  const steps = [
    getIsCustomizeBrandingComplete(),
    getIsFirstPageEditedOrCreated(),
    getIsCollaboratorInvited(),
    getAreDomainSettingsConfirmed(),
    getAreIntegrationsComplete(),
  ];
  return steps.reduce((total, isComplete) => (!isComplete ? (total += 1) : total), 0);
}

export function getCompletedQuickstartStepsCount() {
  const steps = [
    getIsCustomizeBrandingComplete(),
    getIsFirstPageEditedOrCreated(),
    getIsCollaboratorInvited(),
    getAreDomainSettingsConfirmed(),
    getAreIntegrationsComplete(),
  ];
  return steps.reduce((total, isComplete) => (isComplete ? (total += 1) : total), 0);
}
