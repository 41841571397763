import React from 'react';
import { compose } from 'redux';
import withSite from '../../../containers/withSite';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';

import * as uiDomainSettingsActions from '../../../actions/uiDomainSettingsActions';
import { selectLocalState as selectDomainSettingsProps } from '../../../reducers/uiDomainSettingsReducer';
import { selectHasPermission } from '../../../reducers/policyReducer';

import { setDomain, removeDomain, refreshDomain, addSsl } from '../../../actions/siteActions';
import {
  selectSite,
  selectSitesFetching,
  getIsDnsPendingFromSite,
  getUrlSiteFromSite,
  selectIsDomainFailure,
  selectActiveSite,
} from '../../../reducers/sitesReducer';
import { patchOnboarding } from 'actions/siteActions';
import { CONFIRM_DOMAIN_SETTINGS_COMPLETE } from 'components/AdminPanel/Quickstart/constants';

import SideDrawer from '../../base/SideDrawer';
import FormControl from '../../base/FormHelpers/FormControl';
import ValidationMessage from '../../base/FormHelpers/ValidationMessage';

function mapStateToProps(state, ownProps) {
  const isFetching = selectSitesFetching(state);
  return {
    ...selectDomainSettingsProps(state),
    isFetching,
    isError: selectIsDomainFailure(state),
    hasPermission: selectHasPermission(state),
    isDnsPending: getIsDnsPendingFromSite(ownProps.site),
    activeSite: selectActiveSite(state),
  };
}

const mapDispatchToProps = {
  ...uiDomainSettingsActions,
  setDomain,
  removeDomain,
  addSsl,
  refreshDomain: refreshDomain,
  patchOnboarding,
};

class DomainSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      domain: props.site.domain || 'www.',
    };
  }

  handleChange = (e) => {
    this.setState({
      domain: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.props.isFetching) {
      this.props.setDomain(this.props.site.id, this.state.domain);
      this.props.patchOnboarding({
        id: this.props.activeSite.id,
        step: CONFIRM_DOMAIN_SETTINGS_COMPLETE,
      });
    }
  };

  handleAddSslClick = (e) => {
    e.preventDefault();
    if (!this.props.isFetching) {
      this.props.addSsl(this.props.site.id);
    }
  };

  handleRemoveClick = (e) => {
    e.preventDefault();
    if (window.confirm(`Are you sure you want to remove the custom domain ${this.props.site.domain}?`)) {
      if (!this.props.isFetching) {
        this.props.removeDomain(this.props.site.id);
        // this.props.close();
      }
    }
  };

  render() {
    const { isOpen, close, site, isFetching, isError, hasPermission, isDnsPending } = this.props;

    const { domain } = this.state;

    const urlSite = getUrlSiteFromSite(site);

    return (
      <SideDrawer
        isOpen={isOpen}
        close={close}
        title="Custom domain settings"
        render={() => {
          if (!site.domain) {
            return (
              <>
                <form onSubmit={this.handleSubmit}>
                  <fieldset>
                    <label htmlFor="domain_name_input">Domain name</label>
                    <FormControl
                      id="domain_name_input"
                      type="text"
                      name="domain"
                      value={domain}
                      onChange={this.handleChange}
                      autoComplete="off"
                      maxLength={65}
                    />
                    {isError && <ValidationMessage message={isError} />}
                    <small>
                      Please use your full qualified domain, e.g. "www.mysite.com". You can also use an alternate
                      subdomain like "hi.mysite.com", but Unstack doesn't support naked domain sites like "mysite.com".
                    </small>
                  </fieldset>
                  <fieldset>
                    <input
                      type="submit"
                      className="button button-primary button-full"
                      value={isFetching ? 'Loading' : 'Set domain'}
                      disabled={isFetching}
                    />
                  </fieldset>
                  {isFetching && (
                    <p>
                      <small>
                        <strong>This will take several seconds. Please be patient. </strong>
                      </small>
                    </p>
                  )}
                  <p>
                    <small>
                      Once you add this domain to Unstack, you'll need to update your domain's DNS settings to activate
                      it.
                    </small>
                  </p>
                </form>
              </>
            );
          } else {
            let response = !isDnsPending
              ? [
                  <p>
                    <strong>Domain:</strong> {site.domain}
                  </p>,
                ]
              : [
                  <p>
                    You've added <strong data-test-id="domain">{site.domain}</strong> to your Unstack account.
                  </p>,
                  <p>
                    <strong>Update your DNS records</strong> with your Domain Name service provider to finish setting up
                    your domain.
                  </p>,
                  <p>
                    <Link to={`/${urlSite}/settings#domain`} onClick={close}>
                      Visit the settings page
                    </Link>{' '}
                    for instructions on setting up DNS with your domain registrar.
                  </p>,
                  <Link
                    to={`/${urlSite}/settings#domain`}
                    onClick={close}
                    className="button button-secondary button-full"
                  >
                    Close
                  </Link>,
                ];
            if (!site.certificate_id && hasPermission('Site:add_ssl')) {
              response.push(
                <a
                  onClick={this.handleAddSslClick}
                  className="button button-primary button-full"
                  disabled={isFetching}
                  href={'#'}
                >
                  {isFetching ? 'Loading' : 'Add SSL'}
                </a>
              );
            }
            response.push(
              <a
                onClick={this.handleRemoveClick}
                className="button button-danger button-full"
                disabled={isFetching}
                href="#"
              >
                {isFetching ? 'Loading' : 'Remove custom domain'}
              </a>
            );
            return <>{response}</>;
          }
        }}
      />
    );
  }
}

export default compose(withSite, connect(mapStateToProps, mapDispatchToProps))(DomainSettings);
