import React, { useEffect, useState } from 'react';

import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import kebabCase from 'lodash/kebabCase';
import DocumentTitle from 'react-document-title';
import { Search as SearchEngine } from 'js-search';
import { Link, useNavigate, useParams } from 'react-router-dom';

import * as api from '../../services/spark-api';
import { selectHasPermission } from 'reducers/policyReducer';
import { selectSiteByDomain } from '../../reducers/sitesReducer';
import { selectIntegrationBySlug } from 'reducers/integrationsReducer';
import * as templateActions from '../../actions/templateActions';
import * as templateSelectors from '../../reducers/templatesReducer';
import * as integrationActions from '../../actions/integrationActions';
import * as templatetagsActions from '../../actions/templatetagsActions';
import * as templateTagSelectors from '../../reducers/templatetagsReducer';
import * as integrationSelectors from '../../reducers/integrationsReducer';

import Search from '../Search';
import HeaderBar from '../HeaderBar';
import Spinner from '../base/Spinner';
import Tabs from '../AdminPanel/Tabs';
import PageNotFound from '../PageNotFound';
import RequireLogin from '../RequireLogin';
import FixedHeader from '../AdminPanel/FixedHeader';
import { ReactComponent as SvgIconPopOut } from '../../assets/images/icon-pop-out.svg';
import { ReactComponent as SvgIconRemove } from '../../assets/images/icon-remove-section.svg';
import { ReactComponent as SvgIconPlus } from '../../assets/images/icon-circular-plus.svg';

import classnames from 'classnames';
import styles from './Templates.module.scss';
import { OnMount } from 'hooks/mountUnmountHooks';

const ITEM_TYPE = {
  pages: 'site_page',
  'landing-pages': 'landing_page',
};

function Templates() {
  const { urlSite, pageType: urlPageType, tab } = useParams();
  const templates = useSelector(templateSelectors.selectAll);
  const isLoading = useSelector(templateSelectors.selectIsFetching);
  const tags = useSelector(templateTagSelectors.selectTags);
  // @ts-ignore
  const site = useSelector((state) => selectSiteByDomain(state, urlSite));
  // @ts-ignore
  const isShopifyConnected = useSelector((state) => selectIntegrationBySlug(state, site.id, 'shopify'));
  const pageType = urlPageType === 'landing_page' || urlPageType === 'landing-pages' ? 'landing_page' : 'site_page';
  const TABS_OPTIONS = useSelector((state) =>
    // @ts-ignore
    templateTagSelectors.selectTabOptions(state, pageType, isShopifyConnected)
  );
  const hasPermission = useSelector(selectHasPermission);
  const [isCloning, setIsCloning] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [engine, setEngine] = useState<SearchEngine>();
  const backTo = pageType ? (pageType === 'site_page' ? 'pages' : 'landing-pages') : '';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  OnMount(() => {
    dispatch(templateActions.requestAll());
    dispatch(integrationActions.requestAllIfNeeded());
    dispatch(templatetagsActions.requestAllIfNeeded());
    if (!tab) {
      const hasProductsTag = !!tags.filter((tag: any) => tag.id.toLowerCase() === 'products').length;
      navigate(
        !!isShopifyConnected && urlPageType === 'landing-pages' && hasProductsTag
          ? `/${urlSite}/${pageType}/templates/products`
          : `/${urlSite}/${pageType}/templates/all`,
        { replace: true }
      );
    }
  });

  useEffect(() => {
    if (!tab) {
      navigate(`/${urlSite}/${pageType}/templates/all`, { replace: true });
    }
  }, [tab, urlSite, pageType]);

  useEffect(() => {
    if (!engine) setEngine(getSearchEngine(templates));
  }, [engine]);

  const getSearchEngine = (templates: any) => {
    const engine = new SearchEngine('name');
    engine.addIndex('name');
    engine.addIndex('id');
    engine.addDocuments(templates);
    return engine;
  };

  const cloneTemplate = (templateId: string) => {
    setIsCloning(true);

    api
      // @ts-ignore
      .cloneTemplate(templateId, site.id, ITEM_TYPE[pageType])
      .then((response) => {
        const newPageId = response.json.id;
        const data = response.json;
        data.meta = {
          ...data.meta,
          showAddSectionTooltip: true,
        };
        api
          .putPage(data)
          .then((response) => {
            if (response.ok) {
              navigate(`/${urlSite}/editor/${newPageId}`);
            }
          })
          .catch((errors) => {
            console.error('errors', errors);
          });
      })
      .catch((errors) => {
        console.error('errors', errors);
      });
  };

  const getTemplate = (templateId: string) => {
    api
      .getTemplate(templateId, site.id)
      .then((response) => {
        const hasProduct = response?.json?.has_product_tag;
        if (hasProduct) {
          navigate(`/${urlSite}/choose-product`, {
            state: { templateId, pageType },
          });
        } else {
          cloneTemplate(templateId);
        }
      })
      .catch((errors) => {
        console.error('errors', errors);
      });
  };

  function renderedTemplates() {
    const newPageType =
      urlPageType === 'pages'
        ? 'site-pages'
        : urlPageType === 'landing_page'
        ? 'landing-pages'
        : hasPermission('Page:create:::site_page')
        ? 'site-pages'
        : 'landing-pages';

    let newTemplates = searchTerm ? engine.search(searchTerm) : templates;

    const tag = tags.find((tag: any) => {
      return kebabCase(tag.id) === tab;
    });

    const filteredTemplates = newTemplates.filter(
      (template: any) => tab === 'all' || (tab === 'custom' ? template.site_id : template.tags.includes(tag?.id))
    );

    return (
      <>
        <Link to={`/${urlSite}/${newPageType}/new`} className={styles.templateContainer} data-test-id="blank-page">
          <div className={styles.template}>
            <div className={styles.blankPage}>
              <div className={styles.border}>
                <div className={styles.content}>
                  <SvgIconPlus />
                  <div>Blank page</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        {filteredTemplates.map((template: any, i: number) => (
          <Template
            key={i}
            template={template}
            onClick={() => {
              if (template.active) {
                getTemplate(template.id);
                // this.cloneTemplate(template.id);
              }
            }}
            remove={(id) => dispatch(templateActions.remove(id))}
          />
        ))}
      </>
    );
  }

  // Render loading state if templates are loading or if cloning is in process
  const showSpinner = isLoading || isCloning || !engine;

  // Render 404 if pageType is not valid
  if (!Object.keys(TABS_OPTIONS).includes(tab)) {
    // return <PageNotFound />;
    return showSpinner ? (
      <div className="admin">
        <DocumentTitle title="Unstack templates" />
        <HeaderBar
          // fixed
          backTo={`/${urlSite}/${backTo}`}
          center={<div>Choose a template</div>}
          right={
            <Search
              value={searchTerm}
              onChange={(newSearchTerm: string) => setSearchTerm(newSearchTerm)}
              placeholder="Search"
              debounceValue={() => {}}
              containerStyle={{
                marginTop: 0,
              }}
            />
          }
        />
        <Spinner className={styles.spinner} />
      </div>
    ) : (
      <PageNotFound />
    );
  }

  return (
    <RequireLogin>
      <div className="admin">
        <DocumentTitle title="Unstack templates" />

        <HeaderBar
          // fixed
          backTo={`/${urlSite}/${backTo}`}
          center={<div>Choose a template</div>}
          right={
            <Search
              value={searchTerm}
              onChange={(newSearchTerm: string) => setSearchTerm(newSearchTerm)}
              placeholder="Search"
              debounceValue={() => {}}
              containerStyle={{
                marginTop: 0,
              }}
            />
          }
        />

        {showSpinner && <Spinner className={styles.spinner} />}

        {!showSpinner && (
          <>
            <FixedHeader tabs>
              <div>
                {Object.entries(TABS_OPTIONS).map((option, i) => (
                  <Tabs
                    label={option[1]}
                    key={i}
                    isSelected={tab === option[0]}
                    updateTab={() => navigate(`/${urlSite}/${pageType}/templates/${option[0]}`, { replace: true })}
                  />
                ))}
              </div>
            </FixedHeader>
            <section className={styles.templates}>{renderedTemplates()}</section>
            <p className={styles.contactUs}>
              Looking to for something else? Post your idea in{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.unstack.com/hc/en-us/articles/360063140953?utm_source=product&utm_medium=integrations"
              >
                the community
              </a>
              !
            </p>
          </>
        )}
      </div>
    </RequireLogin>
  );
}

type TemplateProps = {
  template: any;
  onClick: (e: any) => void;
  remove: (id: string) => void;
};
const Template = ({ template, onClick, remove }: TemplateProps) => (
  <div className={styles.templateContainer}>
    <div
      className={classnames(styles.template, {
        [styles.disabled]: !template.active,
      })}
      onClick={onClick}
    >
      <div className={styles.thumbnailContainer}>
        {template.thumbnail ? (
          <>
            <img className={styles.staticThumbnail} src={template.thumbnail} alt="" />
            {template.animated_thumbnail && (
              <img className={styles.animatedThumbnail} src={template.animated_thumbnail} alt="" />
            )}
          </>
        ) : (
          <span className={classnames(styles.staticThumbnail, styles.generatingMessage)}>Generating thumbnail</span>
        )}
      </div>
      <div className={styles.caption}>
        <span className={styles.name}>
          {template.name}
          {!template.active && <span className={styles.upgradeTag}>Upgrade</span>}
        </span>
      </div>
    </div>
    {template.site_id && (
      <span onClick={() => remove(template.id)} className={styles.remove}>
        <SvgIconRemove />
      </span>
    )}
  </div>
);

export default Templates;
