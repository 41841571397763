import React from 'react';
import styles from './LogoSimpleBanner.module.scss';
import unstackLogo from '../../assets/images/elastic-path-logo.svg';
import coachupLogo from '../../assets/images/unstack-coachup-logo@2x.png';
import { IS_COACHUP } from '../Coachup/isCoachup';
import { ReactComponent as SepiaBrand } from 'assets/images/elastic-path-logo.svg';

export default function () {
  const logo = IS_COACHUP ? coachupLogo : unstackLogo;

  return (
    <header className={styles.LogoSimpleBanner}>
      <a className={styles.link} href="https://www.unstack.com">
        {IS_COACHUP ? (
          <img src={coachupLogo} alt="logo" className={styles.logo} />
        ) : (
          <SepiaBrand className={styles.logo} />
        )}
      </a>
    </header>
  );
}
