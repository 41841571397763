/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { ReactComponent as Unstackles } from '../../assets/images/icon-sparkles.svg';
import Header from './Header';
// import { ReactComponent as SvgCheveronLeft } from '../../assets/images/icon-cheveron-left.svg';

export default function SignupHeader(props) {
  const {
    step,
    lastStep,
    // gotoNextStep,
  } = props;

  const progress = step / lastStep;

  if (step === 0)
    return (
      <Header
        center="Create a new account"
        right={
          <>
            Have an account? <Link to="/login">Log in</Link>
          </>
        }
        progress={progress}
      />
    );

  if (step === 1) return <Header center="Make it look good" progress={progress} />;

  return (
    <Header
      complete
      center={
        <>
          <Unstackles className={styles.sparkles} />
          Unstack site created
        </>
      }
    />
  );
}
