// ----------------------------------------------------------------------------
// SmartUrl protocols
// ----------------------------------------------------------------------------

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const HTTP_URL_REGEXP = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/; // eslint-disable-line no-useless-escape
export const NAKED_URL_REGEXP = /^(?:(?:https?:)?\/\/)?[\w\d-]+(\.[\w\d-]+)+(?:\/.*)?$/;
export const COUPON_REGEXP = /^coupon:(.+)/i;
export const PAGE_URL_REGEXP = /^page:(.+)/;
export const BLOG_URL_REGEXP = /^blog:(.+)/;
export const ARTICLE_URL_REGEXP = /^article:(.+)/;
export const PRODUCT_URL_REGEXP = /^(product):(.+)/;
export const PRODUCT_COLLECTION_URL_REGEXP = /^(collection):(.+)/;
export const ITEM_URL_REGEXP = /^(page|blog|article|product|collection|data_page):(.+)/;
export const MEDIA_URL_REGEXP = /^media:(.+)/;
export const FORM_URL_REGEXP = /^form:(.+)/;
export const YOUTUBE_URL_REGEXP = /^(http(s)?:\/\/)?((www|m)\.)?youtu(be\.com|\.be)\/\S+(?!.*\s\S.*$)/;
export const VIMEO_URL_REGEXP = /^(http(s)?:\/\/)?((www)\.)?vimeo\.com\/\S+(?!.*\s\S.*$)/;
export const WISTIA_URL_REGEXP = /(?:https?:\/\/)[^.]+\.(?:wistia\.com|wi\.st)\/.*/;
export const YOUTUBE_SHORT_URL_REGEXP = /^(?:http(?:s)?:\/\/)?(?:(?:www|m)\.)?youtu\.be\/([\w.-]+)$/;
export const TRANSISTOR_URL_REGEXP = /^(http(s)?:\/\/)?share\.transistor\.fm\/(s|e)\/\S+(?!.*\s\S.*$)/;
export const BUZZSPROUT_URL_REGEXP = /^(http(s)?:\/\/)?www\.buzzsprout\.com\//;
export const SOUNDCLOUD_URL_REGEXP = /^(http(s)?:\/\/)(w\.)?soundcloud\.com\//;
export const TWITTER_URL_REGEXP = /^(http(s)?:\/\/)(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/status\/\d+/;
export const CAROUSEL_SECTIONS_REGES = /(image)|(text)|(callouts)|(quotes)|(logos)/;

export const PRICING_TIER_COLORS = ['#5DD39E', '#5BC0EB', '#8F9DF5', '#FFBB0D'];

export const IS_VIDEO_REGEX = /\.(mp4|avi|ogg|m4p|m4v|flv)$/;

export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const CSS_UNITS_REGEX = /px|%|em|ex|ch|rem|lh|vw|vh|vmin|vmax/;

(window as any).YOUTUBE_URL_REGEXP = YOUTUBE_URL_REGEXP;
(window as any).YOUTUBE_SHORT_URL_REGEXP = YOUTUBE_SHORT_URL_REGEXP;

// ----------------------------------------------------------------------------
// DNS Status
// ----------------------------------------------------------------------------
export const DNS_ACTIVE = 'active';
export const DNS_PENDING = 'pending';
export const DNS_SSL_PENDING = 'ssl_pending';
export const DNS_WWW_PENDING = 'www_pending';

// ----------------------------------------------------------------------------
// Site Status
// ----------------------------------------------------------------------------

export const SITE_STATUS_SUBDOMAIN = 'subdomain';
export const SITE_STATUS_DOMAIN = 'domain';
export const SITE_STATUS_PROXY = 'proxy';

export const PARTNERS = {
  Shopify: 'shopify',
  CoachUp: 'coachup',
};

export const PAGE_TYPES = {
  LANDING_PAGE: 'landing_page',
  SITE_PAGE: 'site_page',
};

export const ROUTES = {
  allTemplates: (site: string, pageType = PAGE_TYPES.SITE_PAGE) => {
    return `/${site}/${pageType === PAGE_TYPES.SITE_PAGE ? 'pages' : 'landing-pages'}/templates/all/`;
  },
  customTemplates: (site: string, pageType = PAGE_TYPES.SITE_PAGE) => {
    return `/${site}/${pageType === PAGE_TYPES.SITE_PAGE ? 'pages' : 'landing-pages'}/templates/custom/`;
  },
};
