import React from 'react';
import { MediaInstanceTagArgs } from '../../../tag-types';
import Placeholder from './MediaElement/Placeholder';
import AuthenticatedMedia from 'containers/AuthenticatedMedia';

interface MediaInstanceInterface extends ValueOnChangeProps<MediaProps>, MediaInstanceTagArgs {
  value: any;
}

const MediaInstanceElement: React.FunctionComponent<MediaInstanceInterface> = ({ dataRef, ...props }) => {
  const value = dataRef || {};
  const thumbnail = value.featured_image || value.image || value.src || value.url;
  const { aspectRatio, className } = props;
  return thumbnail ? (
    <AuthenticatedMedia className={className} src={thumbnail} />
  ) : (
    <Placeholder
      aspectRatio={aspectRatio}
      label={'image'}
      // brightnessTheme={context.brightnessTheme}
    />
  );
};

export default MediaInstanceElement;
