import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { requestIfNeeded } from '../actions/mediaActions';

import { selectOne } from '../reducers/mediaReducer';
import { HTTP_URL_REGEXP, MEDIA_URL_REGEXP } from '../constants';
import noMediaSvg from '../assets/images/icon-media-removed.svg';
import { ReactComponent as SvgPlay } from '../assets/images/icon-play-video.svg';

const mapDispatchToProps = {
  requestIfNeeded,
};

function mapStateToProps(state, ownProps) {
  let mediaMatch, mediaId, media;
  if (ownProps.src && (mediaMatch = ownProps.src.match(MEDIA_URL_REGEXP))) {
    mediaId = mediaMatch[1];
    media = mediaId ? selectOne(state, mediaId) : null;
  } else if (ownProps.src.match(HTTP_URL_REGEXP)) {
    media = { file: ownProps.src };
  }
  return {
    mediaId,
    src: media ? (media.file ? media.file : noMediaSvg) : null,
    category: media ? media.category_id : 'image',
    poster: media ? (media.poster ? media.poster : noMediaSvg) : null,
  };
}

function AuthenticatedMediaImg(props) {
  const { alt, category, className, mediaId, onClick = () => {}, requestIfNeeded, src, poster, style } = props;
  const [imageNotFound, setImageNotFound] = useState(false);

  useEffect(() => {
    if (mediaId) requestIfNeeded(mediaId);
  }, [requestIfNeeded, mediaId]);

  return category !== 'video' ? (
    <div className={`${className} unstack-media lazyload-placeholder`}>
      <picture>
        <LazyLoadImage
          data-test-id="authenticated-media"
          style={{ ...style, maxHeight: imageNotFound ? 100 : undefined }}
          onClick={() => onClick(category)}
          alt={alt}
          src={src}
          onLoad={() => {
            if (imageNotFound) setImageNotFound(false);
          }}
          onError={() => {
            if (!imageNotFound) setImageNotFound(true);
          }}
        />
      </picture>
    </div>
  ) : (
    <div className={`video-embed ${className}`} style={{ ...style, padding: 0 }}>
      <picture onClick={() => onClick(category)} style={{ position: 'relative' }}>
        <SvgPlay
          style={{
            zIndex: 1,
            height: '50%',
            position: 'absolute',
            width: '50%',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <LazyLoadImage style={style} alt={alt} src={poster} data-test-id="authenticated-media" />
      </picture>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedMediaImg);
