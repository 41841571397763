import { request } from 'actions/accountActions';
import shuffle from 'lodash/shuffle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { selectAccount } from 'reducers/accountReducer';
import * as api from 'services/spark-api';

import * as Klaviyo from 'lib/tracking/klaviyo';

import styles from './IntentSurvey.module.scss';

const GOAL_OPTIONS = shuffle([
  'Create shoppable landing pages',
  'Build a full eCommerce storefront',
  'Add or edit pages for my site',
  'Create shoppable blog posts',
  'Grow my email & SMS lists',
]);

const SIZE = ['Just me', '1-5 people', '6-15 people', '16-25 people', '25-50 people', 'More than 50 employees'];

const CATEGORY = [
  ...shuffle([
    'Fashion & Apparel',
    'Beauty & Cosmetics',
    'Fitness, Sports, & Outdoors',
    'Food & Beverage',
    'Health & Wellness',
    'Lifestyle & Home',
    'Technology',
  ]),
  'Other',
];

const GOAL_MAP_FOR_KLAVIYO: any = {
  'Create shoppable landing pages': 'landing_pages',
  'Build a full eCommerce storefront': 'storefront_builder',
  'Add or edit pages for my site': 'page_builder',
  'Create shoppable blog posts': 'blogging',
  'Grow my email & SMS lists': 'lead_gen',
};

const SIZE_MAP_FOR_KLAVIYO: any = {
  'Just me': '1',
  '1-5 people': '5',
  '6-15 people': '15',
  '16-25 people': '25',
  '25-50 people': '50',
  'More than 50 employees': '>50',
};

export default () => {
  const [fields, setFields] = useState<any>({});
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(request(state.accountId));
  }, []);
  const account = useSelector((state) => selectAccount(state));

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (process.env.NODE_ENV !== 'development') {
        Klaviyo.identify({
          $email: account.users[0].user.email,
          usecase: GOAL_MAP_FOR_KLAVIYO[fields.goal],
          company_size: SIZE_MAP_FOR_KLAVIYO[fields.size],
          sector: fields.category,
        });
      }
    } catch (e) {
      console.log(e);
    }

    const res = await api.updateAccount({ id: account.id, metadata: { ...account.metadata, intent: fields } });
    if (res.ok) {
      window.location.replace(state.redirectTo);
    }
  };

  return (
    <div className="admin">
      <section className={styles.container}>
        <div>
          <span className={styles.tada}>🎉</span>
          <span className={styles.heading}>
            <h2>Thanks for installing Unstack!</h2>
          </span>
          <form onSubmit={onSubmit}>
            <fieldset>
              <label>What's the main thing you want to do with Unstack?</label>
              <select onChange={(e) => setFields({ ...fields, goal: e.target.value })} value={fields.goal}>
                <option value="">Pick one</option>
                {GOAL_OPTIONS.map((g) => (
                  <option value={g} key={g}>
                    {g}
                  </option>
                ))}
              </select>
            </fieldset>
            <fieldset>
              <label>How large is your company?</label>
              <select onChange={(e) => setFields({ ...fields, size: e.target.value })} value={fields.size}>
                <option value="">Pick one</option>
                {SIZE.map((s) => (
                  <option value={s} key={s}>
                    {s}
                  </option>
                ))}
              </select>
            </fieldset>
            <fieldset>
              <label>Which category best describes your brand?</label>
              <select onChange={(e) => setFields({ ...fields, category: e.target.value })} value={fields.category}>
                <option value="">Pick one</option>
                {CATEGORY.map((c) => (
                  <option value={c} key={c}>
                    {c}
                  </option>
                ))}
              </select>
            </fieldset>
            <button
              disabled={Object.values(fields).filter((d) => d).length !== 3}
              type="submit"
              className="button button-full button-primary"
            >
              Let's get started
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};
