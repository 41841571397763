import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import RequireLogin from './RequireLogin';
import DocumentTitle from 'react-document-title';
import { CSSTransition } from 'react-transition-group';

import * as api from '../services/spark-api';
import * as siteSelectors from '../reducers/sitesReducer';
import * as articleSelectors from '../reducers/articlesReducer';
import * as articleActions from '../actions/articleActions';
import * as blogActions from '../actions/blogActions';
import * as blogSelectors from '../reducers/blogsReducer';
import * as mediaActions from '../actions/mediaActions';
import * as accountSelectors from '../reducers/accountUsersReducer';
import * as categoriesActions from '../actions/categoriesActions';
import * as integrationActions from '../actions/integrationActions';

import Spinner from './base/Spinner';
import AdminBar from './Editor/ArticleEditorAdminBar';
import ArticleCanvas from './Editor/ArticleCanvas';
import PageNotFound from './PageNotFound';

import idx from 'lodash/get';

import styles from './Editor.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { OnMount } from 'hooks/mountUnmountHooks';

type Props = {
  isNew?: boolean;
};

function Editor(props: Props) {
  const { urlSite, articleId, blogId } = useParams();
  // @ts-ignore
  const site = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  const article = useSelector((state) => articleSelectors.selectArticle(state, articleId));

  const siteId = site.id;
  const sitesFetching = site == null || site.id == null;
  const articleName = idx(article, 'name');
  const articleIsNull = article == null && !props.isNew;
  const articleIsFetching = article && article.isFetching;
  const articleContentNotLoaded = article && article.content == null;
  const blogsHaveLoaded = useSelector(blogSelectors.selectHasLoaded);
  const currentUser = useSelector(accountSelectors.selectCurrentAccountUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  OnMount(() => {
    dispatch(mediaActions.requestPDFs());
    dispatch(categoriesActions.requestListIfNeeded());
    dispatch(blogActions.requestListIfNeeded());
    if (props.isNew) createPage();
    else dispatch(articleActions.request(articleId));
    dispatch(integrationActions.requestAllIfNeeded());
    dispatch({
      type: 'READ_RESOURCES_REQUEST_IF_NEEDED',
      resourceType: 'sectionTypes',
      requestKey: 'fetch',
    });
  });

  useEffect(() => {
    if (articleId) {
      dispatch(articleActions.request(articleId));
    }
  }, [articleId]);

  function createPage() {
    api
      .createArticle({
        site: siteId,
        blog: blogId,
        slug: null,
        author: currentUser ? currentUser.id : '',
      })
      .then((response) => {
        if (response.ok) {
          dispatch(articleActions.receive(response.json));
          navigate(`/${urlSite}/editor/blog-post/${response.json.id}`, { replace: true });
        }
      });
  }

  // 404 - Page not found
  if (articleIsNull && !articleIsFetching)
    return (
      <RequireLogin>
        <PageNotFound />
      </RequireLogin>
    );

  const isLoading = articleIsFetching || articleContentNotLoaded || sitesFetching || !blogsHaveLoaded || props.isNew;

  const documentTitle = isLoading ? 'Unstack' : `Editing "${articleName || 'New blog post'}"`;

  return (
    <RequireLogin>
      <DocumentTitle title={documentTitle}>
        <div className="Editor">
          <div className="admin">
            <AdminBar articleId={articleId} />
          </div>
          {isLoading && (
            <div className={styles.spinnerContainer}>
              <Spinner size={1} />
            </div>
          )}
          <CSSTransition in={!isLoading} timeout={500} classNames={styles.canvas} mountOnEnter unmountOnExit appear>
            {() => <ArticleCanvas articleId={articleId} />}
          </CSSTransition>
        </div>
      </DocumentTitle>
    </RequireLogin>
  );
}

export default Editor;
