import { UBoxInfo, USectionDevices } from 'types/USection';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import { List, setIn, get } from 'immutable';

type DeviceType = 'base' | 'sm' | 'md' | 'lg';
export type Device = 'mobile' | 'desktop' | 'tablet';

function getContentWithVisibility(content: USectionDevices, combinedContent: UBoxInfo) {
  const allKeys: Set<string> = new Set();
  (['base', 'sm', 'md', 'lg'] as DeviceType[]).forEach((deviceKey: DeviceType) => {
    if (content[deviceKey]) Object.keys(content[deviceKey]).forEach((key) => allKeys.add(key));
  });

  Array.from(allKeys).forEach((key) => {
    const visibilityTracker = { lg: true, md: true, sm: true, base: true };
    Object.keys(visibilityTracker).forEach((deviceKey) => {
      if (
        content[deviceKey as DeviceType] &&
        content[deviceKey as DeviceType][key] &&
        content[deviceKey as DeviceType][key].visible === false
      ) {
        visibilityTracker[deviceKey as DeviceType] = false;
      }
    });
    const visibility = getVisibility(visibilityTracker);
    if (typeof (combinedContent as any)[key] === 'object') (combinedContent as any)[key].visibility = visibility;
  });
  return combinedContent;
}

export function getVisibility(visibilityTracker: { lg: boolean; md: boolean; sm: boolean; base: boolean }) {
  if (!visibilityTracker['lg'] && !visibilityTracker['md'] && visibilityTracker['sm']) {
    return 'mobile-only';
  } else if (!visibilityTracker['lg'] && visibilityTracker['md'] && visibilityTracker['sm']) {
    return 'mobile-tablet';
  } else if (visibilityTracker['lg'] && visibilityTracker['md'] && !visibilityTracker['sm']) {
    return 'tablet-desktop';
  } else if (visibilityTracker['lg'] && !visibilityTracker['md'] && !visibilityTracker['sm']) {
    return 'desktop-only';
  } else if (!visibilityTracker['lg'] && visibilityTracker['md'] && !visibilityTracker['sm']) {
    return 'tablet-only';
  }
  return 'all';
}

// Transpose { base: { backgroundColor: "blue" }, sm: { color: "red" }} to { backgroundColor: "blue", color: "red"}
// based on which device is currently selected
function composeContentFromKeys(content: USectionDevices, deviceTypes: DeviceType[]) {
  const clonedContent = cloneDeep(content);
  const combinedContent: UBoxInfo = {} as UBoxInfo;
  deviceTypes.forEach((deviceType) => {
    if (clonedContent[deviceType]) {
      const deviceContent = clonedContent[deviceType];
      (Object.keys(deviceContent) as (keyof UBoxInfo)[]).forEach((key) => {
        if (!combinedContent[key]) {
          if (deviceTypes.includes('sm') && deviceType !== 'sm' && !Array.isArray(deviceContent[key])) {
            // @ts-ignore-line
            combinedContent[key] = (({ paddingBottom, paddingLeft, paddingRight, paddingTop, maxWidth, ...rest }) =>
              rest)(deviceContent[key]);
          } else {
            // @ts-ignore-line
            combinedContent[key] = deviceContent[key];
          }
        } else {
          if (deviceTypes.includes('sm') && deviceType !== 'sm' && !Array.isArray(deviceContent[key])) {
            // @ts-ignore-line
            combinedContent[key] = merge(
              {
                ...(({ paddingBottom, paddingLeft, paddingRight, paddingTop, maxWidth, ...rest }) => rest)(
                  deviceContent[key]
                ),
              },
              // @ts-ignore-line
              { ...combinedContent[key] }
            );
          } else if (Array.isArray(deviceContent[key])) {
            console.log('DSFSDF');
            // @ts-ignore-line
            combinedContent[key] = merge([...deviceContent[key]], [...combinedContent[key]]);
          } else {
            // @ts-ignore-line
            combinedContent[key] = merge({ ...deviceContent[key] }, { ...combinedContent[key] });
          }
        }
      });
    }
  });
  return getContentWithVisibility(clonedContent, combinedContent);
}

export function getCombinedDeviceContent(content: USectionDevices, device: Device) {
  switch (device) {
    case 'mobile':
      return composeContentFromKeys(content, ['sm', 'md', 'base']);
    case 'tablet':
      return composeContentFromKeys(content, ['md', 'base']);
    case 'desktop':
      return composeContentFromKeys(content, ['lg', 'base']);
  }
}

export function getDeviceTypeToSaveTo(device: Device, hardCodeBase?: boolean, splitKey?: (string | number)[]) {
  if (hardCodeBase) return 'base';
  return device === 'desktop' ? 'base' : device === 'mobile' ? 'sm' : 'md';
}

export function deviceToSize(device: Device) {
  return device === 'desktop' ? 'lg' : device === 'tablet' ? 'md' : 'sm';
}

export function setInDynamic(content: any, path: string[], val: any) {
  let updatedContent = content;

  // Iterate over each segment of the path
  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    // Check if the current segment corresponds to an array index
    //@ts-ignore
    if (!isNaN(key)) {
      // Convert key to integer index
      const index = parseInt(key);

      // Get the current value at this point in the path
      const currentValue = get(updatedContent, path.slice(0, i));

      // If the current value is undefined or null, initialize it as an empty array
      if (!currentValue) {
        updatedContent = setIn(updatedContent, path.slice(0, i), []);
      }

      // If the current value is an Immutable List, ensure it has the necessary length
      else if (List.isList(currentValue)) {
        const newArray = currentValue.setSize(index + 1);
        updatedContent = setIn(updatedContent, path.slice(0, i), newArray);
      }

      // Otherwise, we cannot continue further, so break out of the loop
      else {
        break;
      }
    }
  }

  // Finally, set the value at the specified path
  updatedContent = setIn(updatedContent, path, val);

  return updatedContent;
}
