import React from 'react';
import { connect } from 'react-redux';

import {
  selectLocalState as selectSubscriptionState,
  selectHasSubscription,
  selectSubscriptionHistory,
  selectCurrentPlanName,
} from '../../../reducers/subscriptionReducer';
import * as subscriptionActions from '../../../actions/subscriptionActions';
import * as uiSubscriptionManagerActions from '../../../actions/uiSubscriptionManagerActions';

import styles from './Subscription.module.scss';
import Card from './Subscription/Card';
import Spinner from '../../base/Spinner';

import format from 'date-fns/fp/format';
import parseISO from 'date-fns/parseISO';
import isString from 'lodash/isString';

const formatDate = format('MMMM d, yyyy');

const dateToString = (dateOrStr) => {
  const date = isString(dateOrStr) ? parseISO(dateOrStr) : dateOrStr;
  return formatDate(date);
};

function mapStateToProps(state) {
  return {
    ...selectSubscriptionState(state),
    hasSubscription: selectHasSubscription(state),
    subscriptionHistory: selectSubscriptionHistory(state),
    currentPlanName: selectCurrentPlanName(state),
  };
}

const mapDispatchToProps = {
  requestSubscription: subscriptionActions.request,
  openSubscriptionManager: uiSubscriptionManagerActions.open,
};

class Subscription extends React.PureComponent {
  componentDidMount() {
    this.props.requestSubscription();
  }

  render() {
    if (this.props.isFetching && !this.props.isRequestingCreate) return <Spinner />;

    const {
      subscription,
      isRequestingCreate,
      hasSubscription,
      subscriptionHistory,
      openSubscriptionManager,
      currentPlanName,
    } = this.props;

    return (
      <>
        {!hasSubscription && (
          <section>
            <header>
              <h1>Upgrade</h1>
            </header>
            <article className={styles.upgrade}>
              {!isRequestingCreate && (
                <>
                  <p>Upgrade to level-up your site's inbound marketing features.</p>
                  <button
                    className="button button-primary button-sm"
                    data-test-id="view-payment-plans"
                    onClick={() => openSubscriptionManager()}
                  >
                    View payment plans
                  </button>
                </>
              )}

              {/* - Processing / success message - */}
              {isRequestingCreate && (
                <div className={styles.processing}>
                  <Spinner />
                  Proccessing your payment
                </div>
              )}
            </article>
          </section>
        )}
        {Boolean(subscriptionHistory && subscriptionHistory.length) && (
          <section id="subscription" className={styles.paymentHistory}>
            <header>
              <h1>Payment history</h1>
            </header>
            <article data-test-id="subscription-info">
              <table>
                <tbody>
                  {hasSubscription && (
                    <tr>
                      <td>
                        <Card card={subscription.card} />
                      </td>
                      <td colSpan="3">
                        <small>Subscribed to the</small>
                        <span>{currentPlanName} plan</span>
                      </td>
                    </tr>
                  )}
                  {subscriptionHistory.map((subscription) => (
                    <>
                      {subscription.payments &&
                        subscription.payments.map((payment, i) => (
                          <tr key={i}>
                            <td>
                              <small>Paid</small> {dateToString(payment.date)}
                            </td>
                            <td>Credit card</td>
                            <td>
                              {(payment.amount_paid / 100).toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </td>
                            <td className={styles.textRight}>
                              <a href={payment.pdf}>View receipt</a>
                            </td>
                          </tr>
                        ))}
                    </>
                  ))}
                </tbody>
              </table>
            </article>
          </section>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
