import React, { useState, useMemo, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import DocumentTitle from 'react-document-title';
import createDateFormater from 'date-fns/fp/format';

import ZeroState, { Header, Image, Muted, Group } from 'components/AdminPanel/ZeroState';
import Search from 'components/Search';
import { useProducts, useSyncProducts } from './hooks';
import Spinner from 'components/base/Spinner';
import List from 'components/react-table/List';
import { optimizedSearchQuery } from 'components/unstack-components/Component/elements/block/ProductElement/helpers';

import useUrlSite from 'hooks/useUrlSite';
import useActiveSite from 'hooks/useActiveSite';
import withQueryParams from 'containers/withQueryParams';
import withIntegrations from 'containers/withIntegrations';

import iconShopify from '../../assets/images/icon-shopify.svg';
import iconElasticPath from '../../assets/images/icon-elasticpath.svg';
import { ReactComponent as LinkRedirect } from '../../assets/images/icon-offsite.svg';

import styles from './Storefront.module.scss';

const formatDate = createDateFormater('MMMM do, yyyy');

export default compose(withIntegrations, withQueryParams)((props) => {
  const urlSite = useUrlSite();
  const [query, setQuery] = useState(undefined);

  const params = new URLSearchParams(location.search);
  const [cursor, setCursor] = useState(params.get('cursor') || '');
  const [search, setSearch] = useState(params.get('sku') || undefined);

  const integrationArray = get(props.integrationsBySlug, 'shopify');
  const klevuIntegrationArray = get(props.integrationsBySlug, 'shopify_klevu');
  const shopifyIntegration =
    (integrationArray && integrationArray[0]) || (klevuIntegrationArray && klevuIntegrationArray[0]);
  const epIntegrationArray = get(props.integrationsBySlug, 'elastic_path');
  const epIntegration = epIntegrationArray && epIntegrationArray[0];
  const lastSyncDate = useMemo(() => {
    if (shopifyIntegration) {
      const timeStamp = new Date(shopifyIntegration?.metadata.last_run_at * 1000 || shopifyIntegration.created_at);
      return timeStamp;
    }
  }, [shopifyIntegration]);

  const [paginatedData, setPaginatedData] = useState([]);
  const [data, isLoading] = useProducts(search, 10, cursor);

  const [dataSync, isLoadingSync] = useSyncProducts();

  const prevQueryRef = useRef('');
  const site = useActiveSite();

  const isFetching = props.integrationsFetching || isLoading || isLoadingSync;
  const columns = useMemo(() => getColumns(shopifyIntegration), [data.data, shopifyIntegration]);
  useEffect(() => {
    setPaginatedData([...paginatedData, ...data.data]);
    if (data.data.length === 0 && data.cursorURL) {
      setCursor(data.cursorURL);
    }
  }, [data]);
  const debounceSearch = debounce((search) => {
    setSearch(search);
    setCursor('');
    setPaginatedData([]);
    history.pushState({}, '', location.pathname + '?sku=' + search);
  }, 300);
  return (
    <>
      <DocumentTitle title={'Products'} />
      <section>
        <header className={styles.header}>
          <div>
            <h1>Products</h1>
            {lastSyncDate && (
              <>
                <small>
                  Last sync with Shopify{' '}
                  {formatDistance(lastSyncDate, new Date(), {
                    addSuffix: true,
                  })}
                </small>
                <small>
                  <a href={`https://${shopifyIntegration?.config?.shop}.myshopify.com/admin/products`} target="_blank">
                    Manage Products <LinkRedirect />
                  </a>
                </small>
              </>
            )}
          </div>
          <div className={styles.searchContainer}>
            <Search
              value={query === undefined ? search : query}
              onChange={(query) => {
                const prevValue = prevQueryRef.current;
                setQuery(optimizedSearchQuery(query, prevValue, site.is_shopify));
                prevQueryRef.current = query;
              }}
              placeholder="Search products by title"
              debounceValue={(value) => {
                if (value !== undefined) debounceSearch(value);
              }}
              containerStyle={{
                marginRight: '1rem',
              }}
            />
          </div>
        </header>
      </section>
      <section>
        {isFetching && !paginatedData.length ? (
          <Spinner className="fixed" />
        ) : (
          <div className={styles.productsList}>
            {(Boolean(shopifyIntegration) || Boolean(epIntegration)) &&
              (paginatedData.length ? (
                <>
                  <List
                    className="-highlight"
                    data={paginatedData}
                    columns={columns}
                    defaultPageSize={paginatedData.length}
                    loading={isLoading}
                    showViewAll={false}
                    infiniteScroll
                    updateCursor={() => {
                      if (paginatedData.length > 0) setCursor(data.cursorURL);
                      // history.pushState({}, '', location.pathname + '?cursor=' + data.cursor);
                    }}
                    showHeader
                  />
                  {isLoading && <Spinner style={{ margin: 'auto' }} />}
                </>
              ) : search ? (
                <ZeroState>
                  <Image src={epIntegration ? iconElasticPath : iconShopify} />
                  <Header>No product matches your criteria</Header>
                  <Muted>
                    Your search criteria does not match a title in your products' list. Try a different set of criteria.
                  </Muted>
                </ZeroState>
              ) : data?.data.count === 0 && dataSync?.count !== 0 ? (
                <></>
              ) : (
                <ZeroState>
                  <Image src={epIntegration ? iconElasticPath : iconShopify} />
                  <Header>No products available</Header>
                  <Muted>There are currently no products available on this account.</Muted>
                </ZeroState>
              ))}
            {Boolean(shopifyIntegration === undefined && epIntegration === undefined && !isFetching) && (
              <ZeroState>
                <Image src={iconShopify} />
                <Header>Shopify integration not connected</Header>
                <Muted>You will have your Shopify products listed here when you connect Unstack with Shopify</Muted>
                <Group>
                  <Link to={`/${urlSite}/integrations`} className="button button-primary">
                    Connect to Shopify
                  </Link>
                </Group>
              </ZeroState>
            )}
          </div>
        )}
      </section>
    </>
  );
}, {});

const countVariations = (variations) => {
  let variationCount = 0;
  for (const variant in variations) {
    if (typeof variations[variant] === 'object') {
      variationCount += countVariations(variations[variant]);
    } else {
      variationCount += +1;
    }
  }
  return variationCount;
};

const getColumns = (shopifyIntegration) => {
  const columns = [
    {
      Header: 'Name',
      id: 'name',
      Cell: ({ original: product }) => {
        const media = product?.media?.length && product.media[0];
        return (
          <div className={styles.title}>
            {Boolean(media) && (
              <img src={`${media.url}${!!shopifyIntegration ? '&height=120' : '.h120.webp'}`} alt="Product Thumbnail" />
            )}
            <span data-test-id="product-title">{product.attributes.name}</span>
          </div>
        );
      },
      // sortable: true,
      minWidth: 180,
    },
    {
      Header: 'Inventory',
      id: 'total_inventory',
      Cell: ({ original: product }) => {
        const value = product?.stock?.available || 0;
        return (
          <span data-test-id="inventory">
            {value < 0 ? 0 : value} <span className="muted">in stock</span>
          </span>
        );
      },
      // sortable: true,
    },
    {
      Header: 'Variants',
      id: 'total_variants',
      Cell: ({ original: product }) => {
        let value;
        if (product?.meta?.variation_matrix) {
          value = countVariations(product?.meta?.variation_matrix);
        } else {
          value = 1;
          if (product?.meta?.variations) {
            product?.meta?.variations.forEach((variation) => {
              value = value * variation.options.length;
            });
          }
        }
        if (value > 1)
          return (
            <span data-test-id="variants">
              {value} <span className="muted">variants</span>
            </span>
          );
        return <span>-</span>;
      },
      // sortable: true,
    },
    {
      Header: 'Last Updated',
      id: 'modified_at',
      accessor: 'modified_at',
      Cell: ({ original: product }) => (
        <span className="muted" data-test-id="modified_at">
          {formatDate(new Date(product.attributes.updated_at))}
        </span>
      ),
      // sortable: true,
    },
    // {
    //   Header: 'Actions',
    //   id: 'action',
    //   Cell: ({ original }) => (
    //     <a
    //       href={`https://${shopifyIntegration?.config?.shop}.myshopify.com/admin/products/${original.admin_url}`}
    //       target="_blank"
    //       data-test-id="actions"
    //     >
    //       Edit on Shopify <LinkRedirect height={10} />
    //     </a>
    //   ),
    // },
  ];
  return columns;
};
