// TODO - SGM - Figure out how to integrate this with the policy code to
// TODO - SGM - make this a little more seamless and expandable.
// NOTE: Permissions are currently hardcoded on the frontend, so this file
// contains only selectors – no reducer. In the future, when we add a
// permissions endpoint to the Unstack API, this information will be stored in
// state and a reducer will be added.

import { createSelector } from 'reselect';
import * as subscriptionSelectors from './subscriptionReducer';
import * as pageSelectors from './pagesReducer';

// ----------------------------------------------------------------------------
// Permissions
// ----------------------------------------------------------------------------

const FEATURES = {
  'Site:set_domain': {
    subscriptionTier: 1,
    name: 'Custom Domains',
    actionDescription: 'add a custom domain to your pages',
  },

  // Components
  'Component:create': {
    subscriptionTier: 2,
    name: 'Section Authoring',
    actionDescription: 'add Custom Sections to your sites',
  },

  // Section-types
  'Section::::custom': {
    subscriptionTier: 2,
    name: 'Custom Sections',
    actionDescription: 'add Custom sections to your pages',
  },
  'Section::::pricing': {
    subscriptionTier: 2,
    name: 'Pricing Sections',
    actionDescription: 'add Pricing sections to your pages',
  },

  // Integrations
  'SiteIntegration:create:::activecampaign': {
    subscriptionTier: 3,
    name: 'ActiveCampaign',
    actionDescription: 'integrate your ActiveCampaign account with Unstack',
  },
  'SiteIntegration:create:::custom': {
    subscriptionTier: 2,
    name: 'custom integrations',
    actionDescription: 'integrate your custom script with Unstack',
  },
  'SiteIntegration:create:::drift': {
    subscriptionTier: 1,
    name: 'Drift',
    actionDescription: 'integrate your Drift account with Unstack',
  },
  'SiteIntegration:create:::fb_pixel': {
    subscriptionTier: 1,
    name: 'Facebook',
    actionDescription: 'add Facebook Pixels to your pages',
  },
  'SiteIntegration:create:::google_recaptcha': {
    subscriptionTier: 1,
    name: 'reCAPTCHA',
    actionDescription: 'enable reCAPTCHA on Unstack forms',
  },
  'SiteIntegration:create:::google_tag_manager': {
    subscriptionTier: 2,
    name: 'Google Tag Manager',
    actionDescription: 'enable Tag Manager integrations',
  },
  'SiteIntegration:create:::hotjar': {
    subscriptionTier: 1,
    name: 'Hotjar',
    actionDescription: 'integrate your HotJar account with Unstack',
  },
  'SiteIntegration:create:::hubspot': {
    subscriptionTier: 3,
    name: 'Hubspot',
    actionDescription: 'integrate your Hubspot account with Unstack',
  },
  'SiteIntegration:create:::hubspot': {
    subscriptionTier: 3,
    name: 'Hubspot',
    actionDescription: 'integrate your Hubspot account with Unstack',
  },
  'SiteIntegration:create:::intercom': {
    subscriptionTier: 1,
    name: 'Intercom',
    actionDescription: 'integrate your Intercom account with Unstack',
  },
  'SiteIntegration:create:::klaviyo': {
    subscriptionTier: 2,
    name: 'Klaviyo',
    actionDescription: 'integrate your Klaviyo account with Unstack',
  },
  'SiteIntegration:create:::mailchimp': {
    subscriptionTier: 1,
    name: 'MailChimp',
    actionDescription: 'integrate your MailChimp account with Unstack',
  },
  'SiteIntegration:create:::stripe': {
    subscriptionTier: 3,
    name: 'Stripe',
    actionDescription: 'integrate your Stripe account with Unstack',
  },
  'SiteIntegration:create:::twilio': {
    subscriptionTier: 2,
    name: 'Twilio',
    actionDescription: 'send SMS messages via Twilio',
  },
  'SiteIntegration:create:::zapier': {
    subscriptionTier: 3,
    name: 'Zapier',
    actionDescription: 'start making Zaps in Zapier',
  },

  'Site:debadge': {
    subscriptionTier: 2,
    name: 'removal of Unstack branding',
    actionDescription: "remove Unstack's branding from your footer",
  },
  'Site:sitemap': {
    subscriptionTier: 1,
    name: 'Sitemap',
    actionDescription: 'automatically generate XML sitemap for Google Search Console',
  },
  'Article:list': {
    subscriptionTier: 1,
    name: 'blogs',
    actionDescription: 'author blog articles',
  },
  'Page:create:::landing_page': {
    subscriptionTier: 1,
    name: 'landing pages',
    actionDescription: 'create landing pages',
  },
  'AccountUser:create': {
    subscriptionTier: 2,
    name: 'teams',
    actionDescription: 'add users to your team',
  },
  'Media:create:::video': {
    subscriptionTier: 1,
    name: 'videos',
    actionDescription: 'upload videos',
  },
  'Media:create:::pdf': {
    subscriptionTier: 1,
    name: 'PDFs',
    actionDescription: 'upload PDFs',
  },

  'AMP:*': {
    subscriptionTier: 3,
    name: 'AMP',
    actionDescription: 'enable AMP blog posts',
  },

  'Payments:*': {
    subscriptionTier: 3,
    name: 'payments and membership',
    actionDescription: 'connect your stripe products and subscriptions to Unstack',
  },

  'Tag:*': {
    subscriptionTier: 3,
    name: 'tag',
    actionDescription: `articles' tags`,
  },
  Contact: {
    subscriptionTier: 1,
    name: 'contacts',
    actionDescription: `collect leads and manage contacts`,
  },
  'Contact:download': {
    subscriptionTier: 1,
    name: 'contact CSV export',
    actionDescription: `export your contacts as a CSV`,
  },
  'ItemUrl:*': {
    subscriptionTier: 3,
    name: 'item url',
    actionDescription: `manage site cache and URLs`,
  },
};

// ----------------------------------------------------------------------------
// Selectors
// ----------------------------------------------------------------------------

export function selectFeature(featureId) {
  return FEATURES[featureId];
}

// This selector returns a function that can be used to check if the user has
// permission to use any feature by its featureId.
export const selectCheckPermission = createSelector(
  subscriptionSelectors.selectCurrentTier,
  pageSelectors.selectLandingPageCount,
  (currentTier, landingPageCount) => {
    // Checks the user's subscription tier against the requested feature's
    // subscription tier.
    //  - return null if currentTier is null (indicating the user's
    //    subscription hasn't been fetched or is fetching)
    //  - return true if the user's currentTier is greater than or equal to the
    //    feature's subscriptionTier
    //  - otherwise return false
    return function checkPermission(featureId) {
      if (currentTier == null) return null;

      const feature = FEATURES[featureId];
      if (feature == null) {
        console.error(`Tried to check feature permission, but the featureId, ${featureId}, doesn't exist.`);
        return null;
      } else if (featureId === 'create_landing_page') {
        if (feature.subscriptionTier <= currentTier) {
          return true;
        } else if (landingPageCount == null) {
          console.error('Tried to check create_landing_page feature permission, but pages have not been fetched.');
          return null;
        } else {
          return landingPageCount === 0;
        }
      }

      return feature.subscriptionTier <= currentTier;
    };
  }
);
