/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { deletePage } from '../../../actions/pageActions';
import queryString from 'query-string';
import { requestDelete as deleteBlog } from '../../../actions/blogActions';
import { requestDelete as deleteArticle } from '../../../actions/articleActions';
import * as uiTemplateSettings from '../../../actions/uiTemplateSettingsActions';
import { selectHasPermission } from '../../../reducers/policyReducer';

import styles from './PageActionLinks.module.scss';
import ClonePage from './ClonePage';
import Unpublish from './Unpublish';
import Unschedule from './Unschedule';

import { oxfordJSX } from '../../../lib/oxford';
import UndeletePage from './UndeletePage';
import UndeleteArticle from './UndeleteArticle';
import TemplateDefault from './TemplateDefault';
import { useNavigate, useParams } from 'react-router-dom';

const BACK_TO_PATHS = {
  landing_page: 'landing-pages',
  site_page: 'pages',
  product: 'product-templates',
  collection: 'product-templates',
  data_page: 'dynamic-pages',
};

type Props = {
  entityType: string;
  entity: { [key: string]: any };
  match?: { params: any };
  is_deleted?: boolean;
  close?: () => void;
  updateQueryParams?: (params: any) => void;
  pagesCount?: number;
};

function PageActionLinks(props: Props) {
  const ref = React.createRef<HTMLDivElement>();

  // const mapDispatchToProps = {
  //   deletePage,
  //   deleteBlog,
  //   deleteArticle,
  //   openTemplateSettings: uiTemplateSettings.open,
  // };

  // function mapStateToProps(state) {
  //   return {
  //     hasPermission: selectHasPermission(state),
  //   };
  // }

  const { entityType, entity, is_deleted, close, updateQueryParams, pagesCount } = props;
  const hasPermission = useSelector(selectHasPermission);
  const { urlSite } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const typeName = entityType === 'blog' ? 'blog' : entityType === 'article' ? 'blog post' : 'page';

  // Assemble an array of each action link available
  const actionLinks = [];

  const handleClickTemplate = (e: any) => {
    dispatch(uiTemplateSettings.open(entity.id));
    close();
  };
  const handleClick = (e: any) => {
    const confirm = window.confirm('Are you sure you want to delete this page?');

    if (confirm) {
      ref.current.scrollIntoView();
      confirmDelete();
    }
  };
  const setPageNumber = () => {
    // no more pages left set page param to previous page
    let params = queryString.parse(location.search);
    if (pagesCount < 2 && updateQueryParams) {
      setTimeout(() => {
        updateQueryParams &&
          updateQueryParams({
            page: Number(params.page) - 1,
          });
      }, 1000);
    }
  };
  const confirmDelete = () => {
    setTimeout(() => {
      switch (entityType) {
        case 'page':
          dispatch(deletePage(entity.id));
          // @ts-ignore
          const siteTypePath = BACK_TO_PATHS[entity.item_type];
          const gotoPath = `/${urlSite}/${siteTypePath}`;
          if (location.pathname !== gotoPath) navigate(gotoPath);
          setPageNumber();
          break;

        case 'blog':
          dispatch(deleteBlog(entity.id));
          if (location.pathname !== `${urlSite}/blogs`) navigate(`${urlSite}/blogs`);
          setPageNumber();
          break;

        case 'article':
          dispatch(deleteArticle(entity.id));
          if (!matchPath(location.pathname, `${urlSite}/blogs/:blogId`)) navigate(`/${urlSite}/blogs`);
          setPageNumber();
          break;

        default:
      }
    }, 1000);
  };

  const isStorefront = ['product', 'collection'].includes(entity.item_type);
  if (!is_deleted) {
    if (entity && hasPermission('Template:create') && !isStorefront) {
      actionLinks.push(
        <a key="delete" onClick={handleClickTemplate} data-test-id="save-as-template-control">
          Save as template
        </a>
      );
    }
    if (entityType === 'page' && hasPermission('Page:clone')) {
      actionLinks.push(<ClonePage key="clone" pageId={entity.id} urlSite={urlSite} />);
    }

    if (
      ['page', 'article'].includes(entityType) &&
      entity.status === 'published' &&
      hasPermission('Page:unpublish') &&
      !isStorefront
    ) {
      actionLinks.push(<Unpublish key="unpublish" entityId={entity.id} entityType={entityType} />);
    }

    if (isStorefront && entity.status === 'published') {
      actionLinks.push(<TemplateDefault key="templateDefault" entityId={entity.id} />);
    }

    if (['article'].includes(entityType) && entity.status === 'scheduled' && hasPermission('Page:unschedule')) {
      actionLinks.push(<Unschedule key="unschedule" entityId={entity.id} entityType={entityType} />);
    }

    if (entity && hasPermission('Page:discard') && (entity.slug != '' || entity.status != 'published')) {
      actionLinks.push(
        <a key="delete" onClick={handleClick} data-test-id="delete">
          delete
        </a>
      );
    }
  } else {
    actionLinks.push(
      entityType === 'page' ? <UndeletePage pageId={entity.id} /> : <UndeleteArticle articleId={entity.id} />
    );
  }

  if (!actionLinks.length) {
    return <></>;
  }

  // Render action links as an oxford-comma-separated sentence.
  const renderedActionLinks = oxfordJSX(actionLinks, 'or');

  return (
    <div className={styles.PageActionLinks} ref={ref} data-test-id="action-links">
      <p>{renderedActionLinks}.</p>
    </div>
  );
}

export default PageActionLinks;
