//-----------------------------------------------------------------------------
//  DATA TYPE OPERATORS
//-----------------------------------------------------------------------------

/**
 * TEXT DATA TYPE OPERATORS: {EQUAL, CONTAINS, STARTS_WITH, ENDS_WITH, IS_SET}
 */
const TEXT_OPERATORS: { [key: string]: string } = {
  is_set: 'exists',
  equals: 'equals',
  contains: 'contains',
  starts_with: 'starts with',
  ends_with: 'ends with',
};

const NEGATED_TEXT_OPERATORS: { [key: string]: string } = {
  is_set: 'not exists',
  equals: 'not equal',
  contains: "doesn't contain",
  starts_with: "doesn't start with",
  ends_with: "doesn't end with",
};

/**
 *  NUMBER, DATE AND DATETIME DATA TYPE OPERATORS:
 *  {EQUAL, GREATER_THAN, LESS_THAN, GREATER_THAN_OR_EQUAL, LESS_THAN_OR_EQUAL, BETWEEN, IS_SET}
 */
const NUMBER_AND_DATE_TIME_OPERATORS: { [key: string]: string } = {
  is_set: 'exists',
  equals: 'equals',
  is_greater_than: 'greater than',
  is_less_than: 'less than',
  is_greater_than_or_equal: 'greater than or equal',
  is_less_than_or_equal: 'less than or equal',
  is_between: 'is between',
};

const NEGATED_NUMBER_AND_DATE_TIME_OPERATORS: { [key: string]: string } = {
  is_set: 'not exists',
  equals: 'not equal',
  is_greater_than: 'not greater than',
  is_less_than: 'not less than',
  is_greater_than_or_equal: 'not greater than or equal',
  is_less_than_or_equal: 'not less than or equal',
  is_between: 'is not between',
};

/**
 *  MEDIA DATA TYPE OPERATORS: {IS_SET}
 */
const MEDIA_OPERATORS: { [key: string]: string } = { is_set: 'exists' };
const NEGATED_MEDIA_OPERATORS: { [key: string]: string } = {
  is_set: 'not exists',
};

const BOOLEAN_OPERATORS: { [key: string]: string } = { is_set: 'yes' };
const NEGATED_BOOLEAN_OPERATORS: { [key: string]: string } = {
  is_set: 'no',
};

/**
 *  UTM DATA TYPE OPERATOR: {IS_SET, STARTS_WITH, ENDS_WITH}
 */
const UTM_OPERATORS: { [key: string]: string } = {
  is_set: 'exists',
  starts_with: 'starts with',
  ends_with: 'ends with',
};
const NEGATED_UTM_OPERATORS: { [key: string]: string } = {
  is_set: 'not exists',
  starts_with: "doesn't start with",
  ends_with: "doesn't end with",
};

//-----------------------------------------------------------------------------
//  OPERATORS BASED ON OPERATIONS
//-----------------------------------------------------------------------------

export const UNARY_OPERATOR: Array<string> = ['is_set'];
export const BINARY_OPERATORS: Array<string> = [
  'equals',
  'contains',
  'is_truth',
  'ends_with',
  'starts_with',
  'is_less_than',
  'is_greater_than',
  'is_less_than_or_equal',
  'is_greater_than_or_equal',
];
export const TERNARY_OPERATORS: Array<string> = ['is_between'];

//-----------------------------------------------------------------------------
//  CONDITIONAL OPERATORS
//-----------------------------------------------------------------------------

export const CONDITIONAL_OPERATOR: { [key: string]: string } = {
  any: 'or',
  all: 'and',
};

//-----------------------------------------------------------------------------
//  CONTACT PROPERTIES
//-----------------------------------------------------------------------------
export const PARAM_PROPERTY = {
  id: 'url.query.params',
  slug: 'contact.param',
  label: 'URL query params...',
  defaultOperator: 'equals',
  type: 'params',
  data_type: 'text',
};

export const BASIC_SEGMENTATION = [
  {
    id: 'segment.in_segment',
    slug: 'segment.in_segment',
    label: 'In Klaviyo Segment...',
    defaultOperator: 'is_truth',
    smartInputType: 'segment',
    type: 'segmentation',
  },
  {
    id: 'segment.not_in_segment',
    slug: 'segment.not_in_segment',
    label: 'Not in Klaviyo Segment...',
    defaultOperator: 'is_truth',
    smartInputType: 'segment',
    type: 'segmentation',
  },
];

export const SEGMENT_SEGMENTATION = [
  {
    id: 'segment.audience',
    slug: 'segment.audience',
    label: 'Segment Audience',
    data_type: 'text',
    type: 'segmentation',
    defaultOperator: 'equals',
  },
  {
    id: 'segment.trait',
    slug: 'segment.trait',
    label: 'Segment Trait',
    data_type: 'number',
    type: 'segmentation',
    defaultOperator: 'equals',
  },
];

export const CONTACT_PROPERTIES = [
  {
    id: 'contact.utm',
    slug: 'visit.utm',
    label: 'Visited from...',
    isUtm: true,
    defaultOperator: 'equals',
    type: 'visit',
  },
  {
    id: 'contact.has_visited_a_page',
    slug: 'visit.has_visited_a_page',
    label: 'Visited a page...',
    defaultOperator: 'is_truth',
    smartInputType: 'page',
    type: 'visit',
  },

  {
    id: 'contact.has_previous_visit',
    slug: 'visit.has_previously_visited',
    label: 'Visited previously?',
    defaultOperator: 'is_set',
    data_type: 'boolean',
    type: 'visit',
  },
  {
    id: 'contact.first_visit',
    slug: 'visit.is_first_visit',
    label: 'First visit?',
    data_type: 'boolean',
    defaultOperator: 'is_set',
    type: 'visit',
  },
  {
    id: 'contact.total_visits',
    slug: 'visit.total_visits',
    label: 'Total visits',
    data_type: 'int',
    defaultOperator: 'equals',
    type: 'visit',
  },
  {
    id: 'contact.has_filled_form',
    slug: 'contact.has_filled_form',
    label: 'Submitted a form...',
    defaultOperator: 'is_truth',
    smartInputType: 'form',
    type: 'forms',
  },
  {
    id: 'contact.has_form_submission',
    slug: 'contact.has_filled_form',
    label: 'Submitted any form?',
    defaultOperator: 'is_set',
    data_type: 'boolean',
    type: 'forms',
  },
  // {
  //   id: 'contact.has_purchased_a_product',
  //   slug: 'contact.has_made_a_purchase',
  //   label: 'Purchased a product...',
  //   defaultOperator: 'is_truth',
  //   smartInputType: 'product',
  //   type: 'Purchases',
  // },
  {
    id: 'contact.exists',
    slug: 'contact.exists',
    label: 'Contact exists?',
    defaultOperator: 'is_set',
    data_type: 'boolean',
    type: 'contact',
  },
  {
    id: 'id',
    slug: 'contact.id',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'first_name',
    slug: 'contact.first_name',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'last_name',
    slug: 'contact.last_name',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'full_name',
    slug: 'contact.full_name',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'title',
    slug: 'contact.title',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'email',
    slug: 'contact.email',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'phone',
    slug: 'contact.phone',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'organization',
    slug: 'contact.organization',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'website',
    slug: 'contact.website',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'address1',
    slug: 'contact.address1',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'address2',
    slug: 'contact.address2',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'city',
    slug: 'contact.city',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'state',
    slug: 'contact.state',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'zip',
    slug: 'contact.zip',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
  {
    id: 'country',
    slug: 'contact.country',
    data_type: 'text',
    type: 'contact',
    defaultOperator: 'equals',
  },
];

//-----------------------------------------------------------------------------
//  VARIABLE CONTAINING ALL OPERATORS BASED ON PROPERTY DATA TYPE
//-----------------------------------------------------------------------------

export default {
  text: TEXT_OPERATORS,
  '-text': NEGATED_TEXT_OPERATORS,
  int: NUMBER_AND_DATE_TIME_OPERATORS,
  '-int': NEGATED_NUMBER_AND_DATE_TIME_OPERATORS,
  number: NUMBER_AND_DATE_TIME_OPERATORS,
  '-number': NEGATED_NUMBER_AND_DATE_TIME_OPERATORS,
  datetime: NUMBER_AND_DATE_TIME_OPERATORS,
  '-datetime': NEGATED_NUMBER_AND_DATE_TIME_OPERATORS,
  date: NUMBER_AND_DATE_TIME_OPERATORS,
  '-date': NEGATED_NUMBER_AND_DATE_TIME_OPERATORS,
  time: NUMBER_AND_DATE_TIME_OPERATORS,
  '-time': NEGATED_NUMBER_AND_DATE_TIME_OPERATORS,
  media: MEDIA_OPERATORS,
  '-media': NEGATED_MEDIA_OPERATORS,
  currency: TEXT_OPERATORS,
  '-currency': NEGATED_TEXT_OPERATORS,
  boolean: BOOLEAN_OPERATORS,
  '-boolean': NEGATED_BOOLEAN_OPERATORS,
  utm: UTM_OPERATORS,
  '-utm': NEGATED_UTM_OPERATORS,
};
