import React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import isFunction from 'lodash/isFunction';
import withSiteProps from '../containers/withSiteProps';

function mapSiteToProps(site) {
  return {
    siteName: site.name,
  };
}

function UnstackDocumentTitle(props) {
  const { title, siteName } = props;

  const finalTitle = isFunction(title) ? (siteName ? `${title(siteName)}` : 'Unstack') : `${title} | Unstack`;

  return <DocumentTitle title={finalTitle} />;
}

export default withSiteProps(mapSiteToProps)(UnstackDocumentTitle);
