const QUICKSTART_PENDING = (actionType: string) => `QUICKSTART_${actionType}_PENDING`;
const QUICKSTART_COMPLETE = (actionType: string) => `QUICKSTART_${actionType}_COMPLETE`;

export const DISMISS_QUICKSTART = 'DISMISS_QUICKSTART';
export const CUSTOMIZE_BRANDING = 'CUSTOMIZE_BRANDING';
export const CREATE_OR_EDIT_FIRST_PAGE = 'CREATE_OR_EDIT_FIRST_PAGE';
export const INVITE_COLLABORATORS = 'INVITE_COLLABORATORS';
export const CONFIRM_DOMAIN_SETTINGS = 'CONFIRM_DOMAIN_SETTINGS';
export const INTEGRATE_UNSTACK = 'INTEGRATE_UNSTACK';
export const WEBINAR_BANNER = 'WEBINAR_BANNER';

export const CUSTOMIZE_BRANDING_PENDING = QUICKSTART_PENDING(CUSTOMIZE_BRANDING);
export const CUSTOMIZE_BRANDING_COMPLETE = QUICKSTART_COMPLETE(CUSTOMIZE_BRANDING);
export const CREATE_OR_EDIT_FIRST_PAGE_PENDING = QUICKSTART_PENDING(CREATE_OR_EDIT_FIRST_PAGE);
export const CREATE_OR_EDIT_FIRST_PAGE_COMPLETE = QUICKSTART_COMPLETE(CREATE_OR_EDIT_FIRST_PAGE);

export const INVITE_COLLABORATORS_PENDING = QUICKSTART_PENDING(INVITE_COLLABORATORS);
export const INVITE_COLLABORATORS_COMPLETE = QUICKSTART_COMPLETE(INVITE_COLLABORATORS);

export const CONFIRM_DOMAIN_SETTINGS_PENDING = QUICKSTART_PENDING(CONFIRM_DOMAIN_SETTINGS);
export const CONFIRM_DOMAIN_SETTINGS_COMPLETE = QUICKSTART_COMPLETE(CONFIRM_DOMAIN_SETTINGS);

export const INTEGRATE_UNSTACK_PENDING = QUICKSTART_PENDING(INTEGRATE_UNSTACK);
export const INTEGRATE_UNSTACK_COMPLETE = QUICKSTART_COMPLETE(INTEGRATE_UNSTACK);

export const WEBINAR_BANNER_PENDING = QUICKSTART_PENDING(WEBINAR_BANNER);
export const WEBINAR_BANNER_COMPLETE = QUICKSTART_COMPLETE(WEBINAR_BANNER);
