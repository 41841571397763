import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useUrlSite from 'hooks/useUrlSite';
import { selectHasPermission } from '../../reducers/policyReducer';
import { selectPartner } from '../../reducers/accountReducer';
import { open as openSubscriptionManager } from '../../actions/uiSubscriptionManagerActions';

function mapStateToProps(state) {
  return {
    hasPermission: selectHasPermission(state),
    partner: selectPartner(state),
  };
}

const mapDispatchToProps = {
  openSubscriptionManager: openSubscriptionManager,
};

function CheckPermissionOnClick(props) {
  const {
    action, // could be a single value and an array
    onClick,
    render,
    hasPermission,
    openSubscriptionManager,
    partner,
  } = props;

  const urlSite = useUrlSite();
  const navigate = useNavigate();

  const isPermittedByRole =
    !action || Array.isArray(action) ? action.every((a) => hasPermission(a, 'role')) : hasPermission(action, 'role');

  const isPermittedBySubscription =
    !action || Array.isArray(action)
      ? action.every((a) => hasPermission(a, 'subscription'))
      : hasPermission(action, 'subscription');

  if (!isPermittedByRole || (!isPermittedBySubscription && partner === 'coachup')) return null;

  const handleClick = isPermittedBySubscription
    ? onClick
    : (evt) => {
        evt.preventDefault();
        partner?.id === 'shopify'
          ? navigate(`/${urlSite}/settings/plans`)
          : openSubscriptionManager({ requestedAction: action });
      };

  return render(handleClick, isPermittedBySubscription);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckPermissionOnClick);
