import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { authorizedGet, authorizedPut, authorizedPost, authorizedDelete } from '../../services/spark-api';
import useActiveSite from 'hooks/useActiveSite';
import { prettifyCode } from 'lazy/prettier';

export function useSectionTypes(selectedId) {
  const [sectionTypes, setSectionTypes] = useState();
  const site = useActiveSite();

  // GET section types
  useEffect(() => {
    authorizedGet(`component/?site_id=${site.id}`).then((response) => {
      if (response.ok) {
        setSectionTypes(response.json.results);
      } else {
        console.error('Failed to fetch section types.', response);
      }
    });
  }, [selectedId]);

  return sectionTypes;
}

export function useSectionType(id) {
  const [sectionType, setSectionType] = useState();
  const sectionTypeRef = useRef(sectionType);
  useEffect(() => {
    sectionTypeRef.current = sectionType;
  }, [sectionType]);
  const [isDirty, setIsDirty] = useState(false);
  const site = useActiveSite();

  const dispatch = useDispatch();

  // GET section type
  useEffect(() => {
    setIsDirty(false);

    if (id === 'new') {
      setSectionType({ parent: null, site: null });
    } else {
      authorizedGet(`component/${id}/?site_id=${site.id}`).then((response) => {
        if (response.ok) {
          prettifyCode(response.json?.jsx).then((formattedCode) => {
            setSectionType({
              ...response.json,
              jsx: formattedCode.substring(1),
            });
          });
        } else if (response.status === 404) {
          setSectionType(false);
        } else {
          console.error(`Failed to fetch section type ${id}`, response);
        }
      });
    }
  }, [id]);

  const handleChange = useCallback(
    (name, value) => {
      setIsDirty(true);
      setSectionType({
        ...sectionTypeRef.current,
        [name]: value,
      });
    },
    [sectionType]
  );

  const handleSave = useCallback(() => {
    let fetch;

    sectionType.component_type = sectionType.jsx.includes('<Banner') ? 'banner' : 'section';
    if (sectionType.id) {
      delete sectionType['thumbnail'];
      fetch = (sectionType) =>
        authorizedPut(`component/${sectionType.id}/`, {
          ...sectionType,
          site: site.id,
        });
    } else {
      //  Hard coded 'section' component_type for now. But we want to set it
      //  dynamically in future
      fetch = (sectionType) =>
        authorizedPost('component/', {
          ...sectionType,
          site: site.id,
        });
    }

    return new Promise((resolve, reject) => {
      fetch(sectionType)
        .then((response) => {
          if (response.ok) {
            setSectionType(response.json);
            setIsDirty(false);
            if (response.status === 201) {
              const oldId = sectionType.id;
              const newId = response.json.id;

              if (oldId !== newId) {
                dispatch({
                  type: 'DELETE_RESOURCES_SUCCEEDED',
                  resourceType: 'sectionTypes',
                  resources: [oldId],
                });
                dispatch({
                  type: 'READ_RESOURCES_SUCCEEDED',
                  resourceType: 'sectionTypes',
                  resources: [response.json],
                  requestProperties: {
                    statusCode: response.status,
                  },
                });
              }
            }
            resolve(response.json);
          } else {
            if (response.json) reject(response.json);
            else if (response.text) reject({ message: response.text });
            else reject();
          }
        })
        .catch((err) => reject(err));
    });
  }, [sectionType, site.id, dispatch]);

  const handleDelete = useCallback(() => {
    if (id && sectionType) return authorizedDelete(`component/${id}/`);
  }, [id, sectionType]);

  return [sectionType, isDirty, handleChange, handleSave, handleDelete];
}

export function useComponentCategories() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    authorizedGet('component_category/')
      .then((response) => {
        if (response.ok) {
          setData(response.json.results);
        } else {
          console.error('Failed to fetch categories', response.json);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return [data, isLoading];
}
