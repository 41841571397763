import React from 'react';

import Modal from 'components/base/Modal';

import styles from 'components/AdminPanel/Quickstart/ScheduleTimeLink.module.scss';
import { ReactComponent as PricingIcon } from 'assets/images/pricing-icon.svg';
import { ReactComponent as UnstackIcon } from 'assets/images/unstack-icon.svg';
import { ReactComponent as LifeSaverIcon } from 'assets/images/lifesaver-icon.svg';
import { ReactComponent as CaretUpIcon } from 'assets/images/caret-up-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';

const SCHEDULES = [
  {
    label: 'Get help on a feature',
    link: 'https://meet.unstack.com/meetings/tim-unstack/support-request',
    icon: LifeSaverIcon,
  },
  {
    label: 'Schedule a demo of Unstack',
    link: 'https://meetings.hubspot.com/zach307',
    icon: UnstackIcon,
  },
  {
    label: 'Talk to someone about pricing',
    link: 'https://meetings.hubspot.com/zach307',
    icon: PricingIcon,
  },
];

function ScheduleTime(props) {
  return (
    <Modal isOpen={props.isModalOpen} close={props.setModalClose} className={styles.scheduleTimeModal}>
      <div className={styles.talkToExpertContent}>
        <h1>
          Schedule time with us!
          <CloseIcon className={styles.closeIcon} onClick={props.setModalClose} />
        </h1>

        {SCHEDULES.map(({ label, link, icon: Icon }) => (
          <a href={link} target="_blank" className={styles.scheduleLinkContainer} key={label}>
            <div className={styles.linkLabel}>
              <Icon />
              <span>{label}</span>
            </div>
            <CaretUpIcon className={styles.caretIcon} />
          </a>
        ))}
      </div>
    </Modal>
  );
}

export default ScheduleTime;
