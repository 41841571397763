import React, { memo, useRef, useState } from 'react';

type Props = {
  className?: string;
  width?: number;
  label?: string;
  onClick?: (event: React.MouseEvent<SVGElement>) => void;
  style?: React.CSSProperties;
  brightnessTheme?: BrightnessThemeValue;
  aspectRatio?: any;
};

const calculateAspectRatio = (value: string) => {
  //  @ts-ignore
  if (Number.isFinite(value)) return value;
  else if (value.includes(':')) {
    //  @ts-ignore
    const [antecedent, consequent]: [number, number] = value.split(':');
    return consequent / antecedent;
  }
  if (!isNaN(parseFloat(value))) return Number(value);
};

const clientTextSize = 18;

const Placeholder: React.FunctionComponent<Props> = ({
  className,
  width = 680,
  label = 'Image',
  onClick,
  style,
  brightnessTheme = 'light-bg',
  aspectRatio = 0.56,
}) => {
  //  @ts-ignore
  const height = width * calculateAspectRatio(aspectRatio);

  const [textSize, setTextSize] = useState(0);
  const ref = useRef<SVGSVGElement>();

  if (ref.current) {
    const clientHeight = ref.current && ref.current.clientHeight;
    const newTextSize = (clientTextSize * height) / clientHeight;

    if (newTextSize !== textSize) {
      if (Number.isFinite(newTextSize)) {
        setTextSize(newTextSize);
      } else if (textSize !== 0) {
        setTextSize(0);
      }
    }
  }

  if (!Number.isFinite(width) || !Number.isFinite(height)) {
    return null;
  }

  return (
    <svg
      // height={height} width={width}
      className={className + ' unstack-media'}
      data-label="media-placeholder"
      role={'img'}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
      ref={ref}
    >
      <g>
        <rect
          width={width}
          height={height}
          x="0"
          y="0"
          fill={brightnessTheme === 'light-bg' ? '#eefaff' : '#f8f8f8'}
          stroke="#5bc0eb"
        ></rect>
        <text
          x={width / 2}
          y={(height + textSize) / 2}
          fill="#5bc0eb"
          textAnchor="middle"
          // alignmentBaseline="central"
          fontFamily="sans-serif"
          fontSize={textSize}
        >
          <tspan>{label}</tspan>
        </text>
      </g>
    </svg>
  );
};

export default memo(Placeholder);
