import React, { useEffect, useMemo, useState } from 'react';
import { prettifyCode } from 'lazy/prettier';
import { useSelector } from 'react-redux';
import * as siteReducer from 'reducers/sitesReducer';
// @ts-ignore
import { getResources } from 'redux-resource';
import { IComponent } from 'types/Component';
import { ReinitPlugins } from '../../PluginsRefactored';

interface IProps {
  content: any;
  dataRef: { [key: string]: string };
  service?: string;
  item: any;
  'data-styles': string;
  componentId: string;
  properties: any;
  ['data-product-id']: string;
}

const PROPERTIES_MAP: { [key: string]: string[] } = {
  klevu: ['query_function', 'params', 'queryParam', 'kmcId', 'kmcLogic'],
};

//  @ts-ignore
const SearchResults: React.FunctionComponent<IProps> = (props) => {
  let layoutComponent = useSelector((state: any) =>
    getResources(state.sectionTypes, (_component: IComponent) => {
      return _component.id === props.componentId;
    })
  );

  const [itemTemplate, setItemTemplate] = useState<string>();
  const fullDomain = useSelector(siteReducer.selectSiteFullDomain);

  const service = props.service || 'klevu';

  useEffect(() => {
    if (layoutComponent.length) {
      prettifyCode(layoutComponent[0].jsx).then((formattedCode) => {
        const regex = /item=\{\s*\(\s*result\s*\)\s*=>\s*\(\s*([\s\S]*?)\)\s*\}\s*\/>/;
        const match = formattedCode.match(regex);
        if (props['data-product-id']) return;
        if (match) {
          const templateString = match[1].trim();
          const cleanedTemplateString = templateString.replace(/\{[^{}]*&&\s*\(([^()]*)\)\s*\}/g, '$1');
          setItemTemplate(cleanedTemplateString);
        } else {
          console.debug('No match found.');
        }
      });
    }
  }, [props.content]);

  // const propertiesToUse: string[] = PROPERTIES_MAP[service];
  const dataAttr: { [key: string]: string } = useMemo(() => {
    return Object.entries(props)
      .filter(([key, _value]) => key.includes('data-'))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }, [props]);

  const searchPlugin = dataAttr['data-search-recommendation'] !== undefined ? '' : `data-${service}`;

  useEffect(() => {
    if (itemTemplate && fullDomain) {
      ReinitPlugins();
    }
  }, [itemTemplate, fullDomain]);

  return itemTemplate && fullDomain ? (
    <>
      <div
        {...{ [`data-${service}-search-results`]: '' }}
        className="search-results products"
        data-styles="gap: 3rem;"
        data-item-template="editor__search_results"
        data-full-domain={fullDomain}
        data-widget="hits"
        {...{ [searchPlugin]: '' }}
        {...dataAttr}
      ></div>
      <template
        id="editor__search_results"
        dangerouslySetInnerHTML={{
          __html: `<div data-template-generator="" 
          data-ref="content.${service === 'algolia' ? 'searchResults' : 'results'}" data-item-ref="${
            service === 'algolia' ? 'hit' : 'result'
          }"" data-item-template="editor__search_result"></div>`,
        }}
      ></template>
      <template
        id="editor__search_result"
        dangerouslySetInnerHTML={{
          __html: itemTemplate,
        }}
      ></template>
    </>
  ) : null;
};

export default SearchResults;
