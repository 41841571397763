import React, { useEffect, useState } from 'react';

import Spinner from '../base/Spinner';
import * as api from 'services/spark-api';

import { ReactComponent as IconUnstack } from '../../assets/images/elastic-path-logo.svg';

import styles from './EmailVerification.module.scss';
import { useParams } from 'react-router-dom';

export default (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const { token } = useParams();

  useEffect(() => {
    api
      .verifyEmail(token)
      .then((res) => {
        setIsLoading(false);
        if (res.ok) window.location.href = '/';
        else setError(res.json.error);
      })
      .catch((res) => {
        setError('There was an unexpected error while processing');
        console.error(res);
      });
  }, []);
  return (
    <div className="admin">
      <main className={styles.main}>
        <IconUnstack />
        {isLoading ? (
          <div className={styles.message}>
            <Spinner />
          </div>
        ) : error ? (
          <div className={styles.message}>
            <p>
              {error} <a href="/">Please log in.</a>
            </p>
          </div>
        ) : (
          <div className={styles.message}>Redirecting...</div>
        )}
      </main>
    </div>
  );
};
