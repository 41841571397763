import { useState, useEffect, useRef } from 'react';
import useActiveSite from 'hooks/useActiveSite';
import * as api from 'services/spark-api';
import { ShopifyProductsC, ShopifyProductsListingC } from 'contracts/ShopifyProductC';
import { ShopifyCollectionsListingC } from 'contracts/ShopifyCollectionsC';
import { PcmProduct } from '@moltin/sdk';
import { UProduct } from 'types/UProduct';
import usePrevious from 'hooks/usePrevious';

//  @TODO: Clean all unused hooks after confirming with team

function isValidShopifyQuery(query?: string) {
  return query && query.length >= 1;
}

///////////////////////////////////////////////////////////////////////////
//////////////////// Storefront products hooks ////////////////////////////
///////////////////////////////////////////////////////////////////////////
export function useRecentProduct() {
  const [product, setProduct] = useState<PcmProduct>(undefined);
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  const orderBy = '-updated_at';
  useEffect(() => {
    async function fetch() {
      setLoader(true);
      const res = await api.getProductsInfo('', 1, '', orderBy, site.id, site.is_shopify);
      if (res.ok) setProduct(res.json.data[0] as PcmProduct);
      setLoader(false);
    }

    fetch();
  }, []);

  return [product, isLoading];
}

export function useSyncProducts(): [ShopifyProductsC | {}, boolean] {
  const [data, setData] = useState<ShopifyProductsC | {}>({});
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      if (site.id) {
        setLoader(true);
        await api.getResourceFeedback(site.id);
        const res = await api.getSyncedProducts(site.id);
        if (res.ok) setData(res.json);
        setLoader(false);
      }
    }

    fetch();
  }, [site.id]);

  return [data, isLoading];
}

export function useProduct(id: string) {
  const [data, setData] = useState<UProduct | {}>({});
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      setLoader(true);
      const res = await api.getProductInfo(id, site.id);
      setData(res.json);
      setLoader(false);
    }
    if (id) fetch();
  }, [id]);

  return { data, isLoading };
}

export function useProducts(
  query?: string,
  perPage: number = 10,
  cursorURL?: string,
  orderBy?: string,
  fetchIfValidQuery?: boolean
): [{ data: UProduct[]; cursorURL: string }, boolean] {
  const [data, setData] = useState<{ data: UProduct[]; cursorURL: string }>({ data: [], cursorURL: undefined });
  const [isLoading, setLoader] = useState<boolean>(false);
  const currentQuery = useRef(query);
  const prevQuery = usePrevious(query);
  let cursor = cursorURL;
  if (prevQuery !== query) {
    cursor = 'empty';
  }
  useEffect(() => {
    currentQuery.current = query;
    setData({ data: [], cursorURL: 'empty' });
  }, [query]);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      if ((fetchIfValidQuery && isValidShopifyQuery(query)) || !fetchIfValidQuery) {
        if (cursor !== 'empty') {
          setLoader(true);
          const res = await api.getProductsInfo(query, perPage, cursor, orderBy, site.id, site.is_shopify);
          if (currentQuery.current === query && res.json?.data) {
            setData({
              data: res.json.data.filter((p: any) => !p.attributes.base_product_id),
              cursorURL: res.json.links.next || 'empty',
            });
          }
          setLoader(false);
        }
      }
    }

    fetch();
  }, [query, perPage, cursor, orderBy]);

  return [data, isLoading];
}

export function useShopifyCollections(
  title?: string,
  perPage: number = 10,
  cursor?: string,
  fetchIfValidQuery?: boolean
): [ShopifyProductsListingC | {}, boolean] {
  const [data, setData] = useState<ShopifyCollectionsListingC | {}>({});
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      if ((fetchIfValidQuery && isValidShopifyQuery(title)) || !fetchIfValidQuery) {
        if (cursor !== null) {
          setLoader(true);
          const res = await api.getShopifyCollections(title, perPage, cursor, site.id);
          setData(res.json as ShopifyCollectionsListingC);
          setLoader(false);
        }
      }
    }

    fetch();
  }, [title, perPage, cursor]);

  return [data, isLoading];
}

///////////////////////////////////////////////////////////////////////////
//////////////////// Storefront collection hooks //////////////////////////
///////////////////////////////////////////////////////////////////////////

export function useStorefrontCollections(
  search: string,
  per_page: number,
  parentId: string,
  nextPage: string | undefined
): [ShopifyProductsListingC | {}, boolean] {
  const [data, setData] = useState<ShopifyCollectionsListingC | {}>({});
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      setLoader(true);
      let res = await api.getStorefrontCollections({ search, per_page, parentId, nextPage }, site.id);
      setData(res.json as ShopifyCollectionsListingC);
      setLoader(false);
    }

    fetch();
  }, [search, per_page, parentId, nextPage]);

  return [data, isLoading];
}

export function useStorefrontCollection(id: string) {
  const [data, setData] = useState({});
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      setLoader(true);
      const res = await api.getStorefrontCollection(id, site.id);
      setData(res.json);
      setLoader(false);
    }
    if (id) fetch();
  }, [id]);

  return { data, isLoading };
}

export function fetchFullHierarchy(id: string): any {
  const [list, setList] = useState([]);
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      setLoader(true);
      let nextPage,
        mergedList: Array<any> = [];
      do {
        let res: any = await api.getStorefrontCollections(
          { search: '', per_page: 10, parentId: id, nextPage },
          site.id
        );
        const { data, links } = res.json;
        mergedList = [...mergedList, ...data];
        nextPage = links.next;
      } while (nextPage);
      setList(mergedList);
      setLoader(false);
    }
    if (id) fetch();
  }, [id]);

  return [list, isLoading];
}

export function useStorefrontProducts(
  search: string,
  per_page: number,
  parentId: string,
  nextPage: string | undefined
): [ShopifyProductsListingC | {}, boolean] {
  const [data, setData] = useState<ShopifyProductsListingC | {}>({});
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      setLoader(true);
      const res = await api.getStorefrontProducts({ search, per_page, parentId, nextPage }, site);
      setData(res.json as ShopifyProductsListingC);
      setLoader(false);
    }

    fetch();
  }, [search, per_page, parentId, nextPage]);

  return [data, isLoading];
}

export function useStorefrontProduct(id: string) {
  const [data, setData] = useState<UProduct | {}>({});
  const [isLoading, setLoader] = useState<boolean>(false);
  const site = useActiveSite();
  useEffect(() => {
    async function fetch() {
      setLoader(true);
      const res = await api.getStorefrontProduct(id, site.id);
      setData(res.json);
      setLoader(false);
    }
    if (id) fetch();
  }, [id]);

  return { data, isLoading };
}

///////////////////////////////////////////////////////////////////////////
//////////////////// Storefront template hooks ////////////////////////////
///////////////////////////////////////////////////////////////////////////
export function useProductTemplates(query: string, page: number) {
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<any>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const site = useActiveSite();

  useEffect(() => {
    async function request() {
      setIsLoading(true);
      const res = await api.getItems({
        search: query,
        page,
        item_type: 'product,collection',
        site_id: site.id,
      } as any);

      setData(res.json.results);
      setCount(res.json.count);

      setIsLoading(false);
    }
    request();
  }, [query, page]);

  return [data, count, isLoading];
}

///////////////////////////////////////////////////////////////////////////
//////////////////// Storefront utility hooks ////////////////////////////
///////////////////////////////////////////////////////////////////////////
export function useFetchUsingIds(productsIds: Array<String>, categoriesIds: String[]) {
  const [isFetching, setIsFetching] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const site = useActiveSite();
  useEffect(() => {
    setIsFetching(true);

    // Fetch products and categories concurrently
    const productsPromises = productsIds.map(async (id: String) => {
      const res = await api.getStorefrontProduct(id, site.id);
      if (res.ok) {
        return res.json;
      }
    });

    //  @ts-ignore
    const categoriesPromises = categoriesIds.map(async (id: string) => {
      const res = await api.getStorefrontCollection(id, site.id);
      if (res.ok) {
        return res.json;
      }
    });
    Promise.all([...productsPromises, ...categoriesPromises])
      .then((responses) => {
        const products = responses.slice(0, productsIds.length).filter(Boolean);
        const categories = responses.slice(productsIds.length).filter(Boolean);

        setProductsData((prevProductsData) => [...prevProductsData, ...products]);
        setCategoriesData((prevCategoriesData) => [...prevCategoriesData, ...categories]);
        setIsFetching(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsFetching(false);
      });
  }, [productsIds, categoriesIds]);

  return [isFetching, productsData, categoriesData];
}
