import React from 'react';

import BottomDrawer from '../../base/BottomDrawer';
import MediaDrawerBottom from './Drawer/MediaDrawerBottom';
import AuthenticatedMedia from '../../../containers/AuthenticatedMedia';
import { VimeoEmbed, YoutubeEmbed, WistiaEmbed } from './VideoPlayers';

import { getSourceType } from '../../../lib/process-video-urls';

class MediaEntity extends React.PureComponent {
  state = { isOpen: false, category: '' };

  players = {
    youtube: YoutubeEmbed,
    vimeo: VimeoEmbed,
    wistia: WistiaEmbed,
  };

  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false });
  toggle = (category) => this.setState({ isOpen: !this.state.isOpen, category });

  render() {
    const entity = this.props.entity || {};

    const style = {
      cursor: 'pointer',
      height: this.props.height,
      width: this.props.width,
      ...(entity.boxShadow && { boxShadow: '0 20px 30px rgba(0,0,0,.24)' }),
      ...(entity.borderRadius && {
        borderRadius: `${entity.borderRadius}`,
      }),
    };

    let renderedEntity;
    if (entity.src == null) {
      renderedEntity = React.cloneElement(this.props.placeholder, {
        onClick: () => this.toggle(),
      });
    } else if (this.props.dataTable && entity?.src.includes('{{')) {
      renderedEntity = React.cloneElement(this.props.placeholder, {
        onClick: () => this.toggle(),
      });
    } else if (entity.src.match(/^youtube|vimeo|wistia:(.+)/)) {
      const source = getSourceType(entity.src);
      const PlayerEmbed = this.players[source];
      renderedEntity = (
        <div onClick={() => this.toggle('embed')} className={`video-embed ${this.props.className}`}>
          <PlayerEmbed entity={entity} />
          <div className="video-embed-overlay" />
        </div>
      );
    } else {
      renderedEntity = (
        <AuthenticatedMedia
          className={this.props.className}
          {...entity}
          style={style}
          onClick={(category) => this.toggle(category)}
        />
      );
    }

    return (
      <>
        {renderedEntity}
        <BottomDrawer extendBody hideScroll isOpen={this.state.isOpen} close={this.close}>
          <MediaDrawerBottom
            close={this.close}
            category={this.state.category || this.props.category}
            entity={entity}
            onChange={this.props.onChange}
            enableVideos={this.props.enableVideos}
            showMediaContextOptions={this.props.showMediaContextOptions}
            enableLinking={this.props.enableLinking}
            hideVideo={this.props.hideVideo}
            hideStockMedia={this.props.hideStockMedia}
            hideImage={this.props.hideImage}
            hideEmbeds={this.props.hideEmbeds}
            dataTable={this.props.dataTable}
          />
        </BottomDrawer>
      </>
    );
  }
}

export default MediaEntity;
