import React from 'react';

import { ContactsNavItem } from 'components/AdminPanel/Contacts/ContactsNavItem';
import { QuickStartNavItem } from 'components/AdminPanel/Quickstart/QuickstartPendingStepsCounter';
import { NavOptionItem } from '../NavOption';
import { ReactComponent as SvgIconDesign } from '../../../assets/images/icon-design.svg';
import { ReactComponent as SvgIconSetting } from '../../../assets/images/icon-setting.svg';
import { ReactComponent as SvgIconContent } from '../../../assets/images/icon-content.svg';
import { ReactComponent as SvgIconInsights } from '../../../assets/images/icon-insights.svg';
import { ReactComponent as SvgIconStorefront } from '../../../assets/images/icon-storefront.svg';
import { Integration } from 'types/Integration';

interface Site {
  is_liquid_enabled: boolean;
}

export const CONTENT_NAV_ITEM: NavOptionItem = {
  panel: 'content',
  nestedLinks: ['pages', 'landing-pages', 'dynamic-pages', 'blogs', 'forms', 'media', 'data-tables', 'urls'],
  permissions: [
    {
      action: 'Item:list',
    },
  ],
  children: [
    {
      panel: 'website',
      permissions: [
        {
          action: 'Page:create:::site_page',
        },
      ],
      link: 'pages',
    },
    {
      panel: 'landing pages',
      permissions: [
        {
          action: 'Page:create:::landing_page',
        },
      ],
      link: 'landing-pages',
    },
    {
      panel: 'dynamic pages',
      permissions: [
        {
          action: 'DataTable',
        },
      ],
      link: 'dynamic-pages',
    },
    {
      panel: 'blog',
      permissions: [
        {
          action: 'Article:list',
        },
      ],
      link: 'blogs',
    },
    {
      panel: 'forms',
      permissions: [
        {
          action: 'Form:list',
        },
      ],
      link: 'forms',
    },
    {
      panel: 'media',
      permissions: [
        {
          action: 'Media:list',
        },
      ],
      link: 'media',
    },
    {
      panel: 'database',
      permissions: [
        {
          action: 'DataTable:create',
        },
      ],
      link: 'data-tables',
    },
    {
      panel: 'urls',
      permissions: [
        {
          action: 'Site:flush_cache',
        },
        {
          action: 'ItemUrl:*',
        },
      ],
      link: 'urls',
    },
  ],
  icon: <SvgIconContent />,
};

export const ONBOARDING_BASIC_CONTENT_NAV_ITEM: NavOptionItem = {
  ...CONTENT_NAV_ITEM,
  children: CONTENT_NAV_ITEM.children.filter((child) => ['website', 'landing pages', 'blog'].includes(child.panel)),
};

export const ONBOARDING_SHOPIFY_CONTENT_NAV_ITEM: NavOptionItem = {
  ...CONTENT_NAV_ITEM,
  children: CONTENT_NAV_ITEM.children.filter((child) => ['landing pages', 'forms', 'media'].includes(child.panel)),
};

export const DESIGN_NAV_ITEM = (site: Site): NavOptionItem => ({
  panel: 'design',
  nestedLinks: ['elements', 'styles', 'sections'],
  permissions: [
    {
      action: 'Site:update',
    },
  ],
  children: [
    {
      panel: !site?.is_liquid_enabled ? 'Header & Footer' : 'Branding',
      permissions: [],
      link: 'elements',
    },
    {
      panel: 'style guide',
      permissions: [],
      link: 'styles',
    },
    {
      panel: 'sections',
      permissions: [
        {
          action: 'Component:create',
        },
      ],
      link: 'sections',
    },
  ],
  icon: <SvgIconDesign />,
});

export const ONBOARDING_DESIGN_NAV_ITEM = (site: Site): NavOptionItem => ({
  ...DESIGN_NAV_ITEM(site),
  children: DESIGN_NAV_ITEM(site).children.filter((child) => ['Header & Footer', 'style guide'].includes(child.panel)),
});

export const QUICK_START_NAV_ITEM: NavOptionItem = {
  panel: 'quickstart',
  permissions: [
    {
      action: 'Account:quickstart',
    },
  ],
  children: [],
  render: (props: any) => <QuickStartNavItem {...props} />,
};

export const INSIGHTS_NAV_ITEM: NavOptionItem = {
  panel: 'insights',
  permissions: [],
  nestedLinks: ['insights/traffic', 'insights/test-results', 'insights/conversions', 'insights/utms'],
  children: [
    {
      panel: 'traffic',
      permissions: [
        {
          action: 'Item:summary',
        },
      ],
      link: 'insights/traffic',
    },
    {
      panel: 'A/B test',
      permissions: [
        {
          action: 'Experiment:report',
        },
      ],
      link: 'insights/test-results',
    },
    {
      panel: 'conversions',
      permissions: [
        {
          action: 'Analytics:conversion_path_report',
        },
      ],
      link: 'insights/conversions',
    },
    {
      panel: 'sources',
      permissions: [
        {
          action: 'Analytics:conversion_source_report',
        },
      ],
      link: 'insights/utms',
    },
    { panel: 'SEO', permissions: [], link: 'research' },
  ],
  icon: <SvgIconInsights />,
};

export const CONTACT_NAV_ITEM: NavOptionItem = {
  panel: 'contact',
  permissions: [
    {
      action: 'Contact',
    },
  ],
  children: [],
  render: (props) => <ContactsNavItem {...props} />,
};

export const SETTINGS_NAV_ITEM: NavOptionItem = {
  panel: 'settings',
  permissions: [],
  nestedLinks: ['settings', 'integrations', 'billing'],
  children: [
    {
      panel: 'General',
      permissions: [],
      link: 'settings',
    },
    {
      panel: 'integrations',
      permissions: [
        {
          action: 'SiteIntegration:create',
        },
      ],
      link: 'integrations',
    },
    {
      panel: 'billing',
      permissions: [],
      link: 'settings/plans',
      requiredIntegrations: ['shopify|shopify_klevu'],
    },
  ],
  icon: <SvgIconSetting />,
};

export const STOREFRONT_NAV_ITEM: any = (_site: Site, connectedIntegrations: { [key: string]: Integration[] }): any => {
  const label = connectedIntegrations.elastic_path?.length ? 'categories' : 'collections';
  return {
    panel: 'storefront',
    permissions: [],
    nestedLinks: ['products', label, 'product-templates'],
    requiredIntegrations: ['shopify|elastic_path|shopify_klevu'],
    children: [
      {
        panel: 'products',
        permissions: [],
        link: 'products',
      },
      {
        panel: label,
        permissions: [],
        link: 'collections',
      },
      {
        panel: 'templates',
        permissions: [
          {
            action: 'ProductTemplate',
          },
        ],
        link: 'product-templates',
      },
    ],
    icon: <SvgIconStorefront />,
  };
};

export const NAVIGATION_ITEMS = (site: Site, connectedIntegrations: Integration): Array<NavOptionItem> => [
  { ...QUICK_START_NAV_ITEM },
  { ...INSIGHTS_NAV_ITEM },
  { ...STOREFRONT_NAV_ITEM(site, connectedIntegrations) },
  { ...CONTENT_NAV_ITEM },
  { ...CONTACT_NAV_ITEM },
  { ...DESIGN_NAV_ITEM(site) },
  { ...SETTINGS_NAV_ITEM },
];
