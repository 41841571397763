/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useMemo, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import Footer from './Nav/Footer';
import SitePicker from '../Nav/SitePicker';
import TrialBanner from './Nav/TrialBanner';
import CheckPermissionOnClick from '../SubscriptionManager/CheckPermissionOnClick';

import { selectPolicies } from '../../reducers/accountReducer';
import { selectSiteByDomain } from '../../reducers/sitesReducer';
import { selectShouldRender } from '../../reducers/policyReducer';
import * as integrationSelector from '../../reducers/integrationsReducer';
import { selectNotificationBySiteDomain, selectNotificationBarIsOut } from '../../reducers/notificationReducer';
import { selectSubscription } from 'reducers/subscriptionReducer';

import styles from './Nav.module.scss';
import NavOption from './NavOption';
import { NAVIGATION_ITEMS } from './Nav/NavItems';
import withSite from 'containers/withSite';
import { useNavigate, useParams } from 'react-router-dom';
import { USite } from 'types/USite';

type Props = {
  site: USite;
};

function Nav(props: Props) {
  const { urlSite } = useParams();
  // @ts-ignore
  const siteId = useSelector((state) => selectSiteByDomain(state, urlSite)).id;
  const notification = useSelector((state) => selectNotificationBySiteDomain(state, urlSite));
  const notificationBarIsOut = useSelector((state) => selectNotificationBarIsOut(state, urlSite));
  const shouldRender = useSelector(selectShouldRender);
  // @ts-ignore
  const connectedIntegrations = useSelector((state) => integrationSelector.selectSiteIntegrationsBySlug(state, siteId));
  const policies = useSelector(selectPolicies);
  const subscription = useSelector(selectSubscription);
  const navigate = useNavigate();

  const trialDays = subscription?.ended_on
    ? Math.ceil((Date.parse(subscription?.ended_on) - Date.now()) / (1000 * 3600 * 24))
    : 0;
  const [panel, setPanel] = useState();
  const NAVIGATION_MENU = useMemo(() => {
    const TEMP = NAVIGATION_ITEMS(props.site, connectedIntegrations)
      .filter((i) => {
        return (
          !i.requiredIntegrations ||
          i.requiredIntegrations?.every((integration) => {
            return integration.split('|').some((i) => {
              return connectedIntegrations[i];
            });
          })
        );
      })
      .map((i) => {
        i.children = i.children
          .filter((item) => {
            return item.permissions.every((permission) => {
              return shouldRender(permission.action);
            });
          })
          .filter((item) => {
            return (
              !item.requiredIntegrations ||
              item.requiredIntegrations?.every((integration) => {
                return integration.split('|').some((item) => {
                  return connectedIntegrations[item];
                });
              })
            );
          });
        return i;
      });
    const link = TEMP.find((m) => m.nestedLinks?.some((l) => location.pathname.includes(l)))?.panel;
    setPanel(link as any);
    return TEMP;
  }, [notification, siteId, shouldRender, policies, connectedIntegrations, props.site]);

  return (
    <section
      className={classnames(styles.Nav, {
        [styles.withNotifications]: notificationBarIsOut,
      })}
    >
      <nav data-test-id="navigation-container">
        <h4>Studio</h4>
        <SitePicker />
        {NAVIGATION_MENU.map((item) => {
          return (
            <CheckPermissionOnClick
              action={item.permissions.map((permission) => permission.action)}
              onClick={(e: any, url: string) => {
                e.preventDefault();
                navigate(`${url}`);
              }}
              key={item.panel}
              render={(handleClick: any) => {
                return (
                  <NavOption
                    setPanel={(panel) => setPanel(panel as any)}
                    panel={panel}
                    item={item}
                    handleNavigationItemClick={handleClick}
                  />
                );
              }}
            />
          );
        })}
      </nav>
      {subscription?.plan === 'shop_lp_trial_month' && <TrialBanner trialDays={trialDays} />}
      <Footer />
    </section>
  );
}

export default withSite(Nav);
