import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { useComponentCategories, useSectionType } from '../hooks';

import * as componentActions from 'actions/componentActions';
import * as siteLayoutActions from 'actions/siteLayoutActions';

import { useDispatch } from 'react-redux';

import throttle from 'lodash/throttle';

import styles from './Show.module.scss';
import Controls from './Controls';
import Preview from './Preview';
import Editor from 'lazy/SectionEditor';
import { useNavigate, useParams } from 'react-router-dom';

const Sass = require('./sass.js');
Sass.setWorkerUrl('/sass.worker.js');
const sass = new Sass();
let lastWorkingCss = '';

function ComponentViewer(props: any) {
  const { urlSite, selectedId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  dispatch(componentActions.requestComponentDefaultsIfNeeded());

  const { addToast: _addToast, removeToast } = useToasts();
  const toasts = useRef([]).current;
  const [categories, isLoading] = useComponentCategories();

  const addToast = (...args: any) => {
    // @ts-ignore
    const toast = _addToast(...args);
    toasts.push(toast);
    return toast;
  };

  const removeAllToasts = () => {
    while (toasts.length) {
      removeToast(toasts.pop());
    }
  };

  const [sectionType, isDirty, handleChange, save, deleteIt] = useSectionType(selectedId);
  const [throttledSectionType, setThrottledSectionType] = useState<any>(sectionType);
  const [cssError, setCssError] = useState(null);
  const throttled = useRef(
    throttle((sectionType, cssError) => {
      const parentClass = sectionType.jsx?.includes('<Header')
        ? '.header'
        : sectionType.jsx?.includes('<Footer')
        ? '.footer'
        : '.section';
      sass.compile(parentClass + '{ ' + sectionType.css + '}', function callback(result: any) {
        if (result.status !== 0) {
          // sass compiler error
          sectionType.rendered_css = lastWorkingCss;
          setThrottledSectionType(sectionType);
          setCssError(result);
        } else {
          sectionType.rendered_css = result.text;
          lastWorkingCss = result.text;
          setThrottledSectionType(sectionType);
          if (cssError !== null) setCssError(null);
        }
      });
    }, 2000)
  );
  useEffect(() => sectionType && throttled.current(sectionType, cssError), [sectionType]);

  const close = useCallback(() => {
    navigate(`/${urlSite}/sections`);
  }, [urlSite]);

  const updateComponentIdInUrl = useCallback(
    (id: string) => {
      navigate(`/${urlSite}/sections/${id}`);
    },
    [urlSite]
  );

  const handleSave = useCallback(() => {
    removeAllToasts();

    // TODO Daniel Upgrade is add toast not returning anything now?
    const savingToast = addToast('Saving...', {
      appearance: 'info',
      autoDismiss: false,
    }) as unknown as string;

    // @ts-ignore
    save()
      .then((newSectionType: any) => {
        removeToast(savingToast);

        addToast('Saved successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        if (newSectionType && newSectionType.id && newSectionType.id !== selectedId) {
          updateComponentIdInUrl(newSectionType.id);
          dispatch(siteLayoutActions.fetchSiteLayout());
        }
      })
      .catch((error: any) => {
        removeToast(savingToast);

        let message: any = '';
        if (error && error.message) {
          message = (
            <div>
              <div>
                <b>Error:</b>
              </div>
              <pre style={{ whiteSpace: 'break-spaces' }}>{error.message}</pre>
            </div>
          );
        } else if (error) {
          if (error.slug) message = 'Please enter a valid slug';
          if (error.name) message = 'Please enter a valid name';
        } else {
          message = 'Something went wrong';
        }
        addToast(message, {
          appearance: 'error',
          autoDismiss: false,
        });
      });
  }, [save, selectedId, addToast]);

  const handleDelete = useCallback(() => {
    // @ts-ignore
    if (!sectionType.site) {
      window.alert('You cannot delete a global section');
      return;
    }
    const proceed = window.confirm('Are you sure you want to delete this?');
    // @ts-ignore
    if (proceed) deleteIt().then(() => close());
  }, [deleteIt, close]);

  // full-preview, standard, full-code, full-code-2
  const [editorView, setEditorView] = useState('standard');

  const [jsxError, setJsxError] = useState<{ [key: string]: any }>();
  function jsxErrorCallback(e: any) {
    if ((!e && jsxError) || (e && !jsxError) || (e && jsxError && e.message !== jsxError.message)) {
      setJsxError(e);
    }
  }

  if (!selectedId) return null;

  if (!sectionType) return <p style={{ textAlign: 'center' }}>{sectionType === false ? 'Not found' : 'Loading'}</p>;

  return (
    <div className={styles.container}>
      <Controls
        close={close}
        sectionType={sectionType}
        isDirty={isDirty}
        handleChange={handleChange}
        handleSave={handleSave}
        handleDelete={handleDelete}
        editorView={editorView}
        setEditorView={setEditorView}
        categories={categories}
        jsxError={jsxError}
      />
      {throttledSectionType && (
        <Preview component={throttledSectionType} editorView={editorView} jsxErrorCallback={jsxErrorCallback} />
      )}
      <React.Suspense fallback={<div>Loading...</div>}>
        <Editor
          sectionType={sectionType}
          handleChange={handleChange}
          editorView={editorView}
          jsxError={jsxError}
          cssError={cssError}
        />
      </React.Suspense>
    </div>
  );
}

export default function (props: any) {
  return (
    <ToastProvider placement="top-center">
      <ComponentViewer {...props} />
    </ToastProvider>
  );
}
