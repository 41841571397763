import React from 'react';
import { useSelector } from 'react-redux';

import DocumentTitle from 'components/DocumentTitle';
import ProgressBar from 'components/AdminPanel/Quickstart/ProgressBar';
import ScheduleTimeLink from 'components/AdminPanel/Quickstart/ScheduleTimeLink';
import styles from 'components/AdminPanel/Quickstart/Quickstart.module.scss';

import CustomizeBrandingExpansionPanel from 'components/AdminPanel/Quickstart/CustomizeBrandingExpansionPanel';
import WebinarBanner from 'components/AdminPanel/Quickstart/WebinarBanner';
import InviteCollaboratorsPanel from 'components/AdminPanel/Quickstart/InviteCollaboratorsPanel';
import CreateFirstPagePanel from 'components/AdminPanel/Quickstart/CreateFirstPagePanel';
import ConfirmDomainSettingsPanel from 'components/AdminPanel/Quickstart/ConfirmDomainSettingsPanel';
import IntegrateContentPanel from 'components/AdminPanel/Quickstart/IntegrateContentPanel';
import { getCompletedQuickstartStepsCount } from 'components/AdminPanel/Quickstart/helpers';
import HelpLinks from 'components/AdminPanel/Quickstart/HelpLinks';
import DismissQuickstartLink from 'components/AdminPanel/Quickstart/DismissQuickstartLink';
import ConnectedModal from './Integrations/ConnectedModal';
import { selectHasPermission } from 'reducers/policyReducer';

export default function Quickstart() {
  const quickstartProgress = getCompletedQuickstartStepsCount() * 20;
  const hasPermission = useSelector(selectHasPermission);
  const pointsToShopify =
    hasPermission('Site:set_proxy', 'subscription') && !hasPermission('Site:set_subdomain', 'subscription');

  return (
    <>
      {/* @ts-ignore */}
      <DocumentTitle title={(siteName: string) => `${siteName}'s quickstart`} />
      <ConnectedModal />
      <div className={styles.container}>
        <section className={styles.quickstart}>
          <header className={styles.pagesHeader}>
            <h1>Quickstart</h1>
            <small>You can complete the steps in any order!</small>
          </header>
          <ProgressBar progress={quickstartProgress} />
          <div className={styles.expansionPanelContainer} data-test-id="quickstart-expansions-container">
            <CustomizeBrandingExpansionPanel />
            <CreateFirstPagePanel />
            {hasPermission('AccountUser:create') && <InviteCollaboratorsPanel />}
            {!pointsToShopify && <ConfirmDomainSettingsPanel />}
            <IntegrateContentPanel />
          </div>
          <DismissQuickstartLink />
        </section>
        <aside>
          <ScheduleTimeLink />
          <HelpLinks />
        </aside>
      </div>
    </>
  );
}
