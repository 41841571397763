import React, { useState, useEffect, Dispatch } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as api from '../../services/spark-api';
import * as accountSelectors from '../../reducers/accountReducer';
import * as accountActions from '../../actions/accountActions';
import * as siteActions from '../../actions/siteActions';
import queryString from 'query-string';
import * as Klaviyo from '../../lib/tracking/klaviyo';
import * as Hubspot from '../../lib/tracking/hubspot';
import { gtag_report_conversion } from '../../lib/tracking/google';
import styles from './CreateAccount.module.scss';
import Aside from './CreateAccount/Aside';
import Quote from './CreateAccount/Quote';
import idx from 'lodash/get';

(window as any).g = gtag_report_conversion;

const BLACKLISTED_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'inbox.com',
  'icloud.com',
  'mail.com',
  'zoho.com',
  'yandex.com',
  'protonmail.com',
];

type Props = {
  gotoNextStep: (metaData: any, account?: any) => void;
};

function CreateAccountForm(props: Props) {
  const location = useLocation();
  const initEmail = getEmailFromQueryParams(location);
  const dispatch: Dispatch<any> = useDispatch();

  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    email: initEmail || '',
    phone: '',
    domain: '',
    showDomainField: false,
    password: '',
    errors: {} as { [key: string]: any },
    isFetching: false,
  });

  let hubSpotScript: any;

  useEffect(() => {
    createAndAppendHubSpotScript();
    if (email) {
      try {
        if (process.env.NODE_ENV !== 'development') {
          Klaviyo.identify({ $email: email });
        }
      } catch (e) {
        console.log(e);
      }
    }
    return () => {
      document.body.removeChild(hubSpotScript);
    };
  }, []);

  const createAndAppendHubSpotScript = () => {
    hubSpotScript = document.createElement('script');
    hubSpotScript.id = 'hs-script-loader';
    hubSpotScript.async = true;
    hubSpotScript.defer = true;
    hubSpotScript.src = '//js.hs-scripts.com/4958261.js';
    document.body.appendChild(hubSpotScript);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    const newState: { [key: string]: any } = {
      [name]: value,
    };

    if (name === 'email' && value) {
      let [, domain] = value.split('@');
      if (domain && BLACKLISTED_DOMAINS.includes(domain)) {
        newState.showDomainField = true;
        newState.domain = '';
      } else {
        newState.showDomainField = false;
        newState.domain = domain;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const { first_name, last_name, company_name, email, password, domain } = state;
    const payload = {
      meta: {
        domain,
        onboarding_step: 0,
      },
      users: [
        {
          role: 'owner',
          account: '',
          user: {
            first_name,
            last_name,
            company_name,
            email,
            password,
          },
        },
      ],
    };

    if (!state.isFetching) {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));

      api
        .createAccount(payload)
        .then((response) => {
          if (response.ok || response.status === 201) {
            Hubspot.setPagePathAndTrackPageView('/signup');
            gtag_report_conversion();
            dispatch(accountActions.loginSuccess(response.json.token, response.json.policies));
            dispatch(accountActions.receive(response.json));
            setTimeout(() => {
              props.gotoNextStep({ domain: response.json.meta.domain }, response.json);
            }, 1000);
          } else if (response.status === 400) {
            setState((prevState) => ({
              ...prevState,
              isFetching: false,
              errors: {
                first_name: idx(response.json, 'first_name[0]') || idx(response.json, 'user.first_name[0]'),
                last_name: idx(response.json, 'last_name[0]') || idx(response.json, 'user.last_name[0]'),
                company_name: idx(response.json, 'company_name[0]') || idx(response.json, 'user.company_name[0]'),
                domain: idx(response.json, 'domain[0]'),
                email:
                  idx(response.json, 'username[0]') ||
                  idx(response.json, 'email[0]') ||
                  idx(response.json, 'user.username[0]') ||
                  idx(response.json, 'user.email[0]'),
                password: idx(response.json, 'password') || idx(response.json, 'user.password'),
              },
            }));
          } else {
            console.warn('Unhandled CreateAccount error', response);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          phone: 'This field may not be blank.',
        },
      }));
    }
  };

  const { first_name, last_name, company_name, email, phone, domain, password, errors, isFetching } = state;

  return (
    <>
      <section className={styles.container} data-test-id="signup-page-container">
        <Aside />
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formRow}>
            <fieldset>
              <label>First name</label>
              <input
                id="first_name_input"
                aria-label="first_name"
                name="first_name"
                value={first_name}
                type="text"
                placeholder="Robin"
                onChange={handleChange}
                autoComplete="first_name"
                className={errors.first_name ? 'fieldWithErrors' : ''}
              />
              {errors.first_name && (
                <small className="errorMessage" data-test-id="error">
                  {errors.first_name}
                </small>
              )}
            </fieldset>
            <fieldset>
              <label>Last name</label>
              <input
                id="last_name_input"
                aria-label="last_name"
                name="last_name"
                value={last_name}
                type="text"
                placeholder="Cruz"
                onChange={handleChange}
                autoComplete="last_name"
                className={errors.last_name ? 'fieldWithErrors' : ''}
              />
              {errors.last_name && (
                <small className="errorMessage" data-test-id="error">
                  {errors.last_name}
                </small>
              )}
            </fieldset>
          </div>
          <fieldset>
            <label>Company name</label>
            <input
              id="company_name_input"
              aria-label="company_name"
              name="company_name"
              value={company_name}
              type="text"
              placeholder="Cruz Clothing"
              onChange={handleChange}
              autoComplete="company_name"
              className={errors.company_name ? 'fieldWithErrors' : ''}
            />
            {errors.company_name && (
              <small className="errorMessage" data-test-id="error">
                {errors.company_name}
              </small>
            )}
          </fieldset>
          <fieldset>
            <label>Email</label>
            <input
              id="email_input"
              aria-label="email"
              name="email"
              value={email}
              type="email"
              placeholder="robin.cruz@cruzclothing.com"
              onChange={handleChange}
              autoComplete="email"
              className={errors.email ? 'fieldWithErrors' : ''}
            />
            {errors.email && (
              <small className="errorMessage" data-test-id="error">
                {errors.email}
              </small>
            )}
            {errors.domain && (
              <small className="errorMessage" data-test-id="error">
                {errors.domain}
              </small>
            )}
          </fieldset>
          {state.showDomainField && (
            <fieldset>
              <label>Domain</label>
              <input
                id="domain_input"
                name="domain"
                aria-label="domain"
                value={domain}
                type="text"
                onChange={handleChange}
                className={errors.domain ? 'fieldWithErrors' : ''}
                placeholder="www.cruzclothing.com"
              />
              {errors.domain && (
                <small className="errorMessage" data-test-id="error">
                  {errors.domain}
                </small>
              )}
            </fieldset>
          )}
          <fieldset>
            <label>Password</label>
            <input
              id="password_input"
              aria-label="password"
              name="password"
              value={password}
              type="password"
              placeholder="Minimum 8 characters."
              onChange={handleChange}
              autoComplete="new-password"
              className={errors.password ? 'fieldWithErrors' : ''}
            />
            {errors.password && (
              <small className="errorMessage" data-test-id="error">
                {errors.password}
                <br />
              </small>
            )}
          </fieldset>
          <input
            type="submit"
            className="button button-success button-full disable-silently"
            value={isFetching ? 'Loading...' : 'Create account'}
            disabled={isFetching}
          />
          <small className={styles.terms}>
            By creating an Unstack account you agree to its{' '}
            <a href="https://www.unstack.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.unstack.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </small>
        </form>
      </section>
      <Quote />
    </>
  );
}

export default CreateAccountForm;

// Extract email param from a location's querystring
function getEmailFromQueryParams(location: any) {
  const queryParams = queryString.parse(location.search);
  return queryParams.email;
}
