import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import RequireLogin from './RequireLogin';
import DocumentTitle from 'react-document-title';

import * as api from '../services/spark-api';
import * as videosActions from '../actions/videosActions';
import * as siteActions from '../actions/siteActions';
import * as siteSelectors from '../reducers/sitesReducer';
import * as pageSelectors from '../reducers/pagesReducer';
import * as pageActions from '../actions/pageActions';
import * as mediaSelectors from '../reducers/mediaReducer';
import * as mediaActions from '../actions/mediaActions';
import * as integrationsReducer from 'reducers/integrationsReducer';
import * as integrationActions from '../actions/integrationActions';
import * as subscriptionActions from '../actions/subscriptionActions';
import * as contentTestSelectors from '../reducers/contentTestsReducer';
import * as contentTestActions from '../actions/contentTestActions';
import * as paymentAction from '../actions/paymentActions';
import * as templateActions from '../actions/templateActions';
import * as componentActions from '../actions/componentActions';
import * as siteLayoutActions from '../actions/siteLayoutActions';
import * as uiPageSettingsActions from '../actions/uiPageSettingsActions';
import * as accountUserSelectors from 'reducers/accountUsersReducer';
import * as accountUserActions from '../actions/accountUserActions';
import * as uiSelectors from '../reducers/uiReducer';
import * as siteLayoutSelectors from '../reducers/siteLayoutReducer';
import * as subscriptionSelectors from '../reducers/subscriptionReducer';

import defaultSectionProps from '../actions/sections/default-section-props';

import Spinner from './base/Spinner';
import PageNotFound from './PageNotFound';
import { ErrorBar } from './DynamicPageEditor';
import NeedHelpPrompt from './NeedHelpPrompt';
import IntroEditorModal from './IntroEditorModal';
import EditorAdminBar from './Editor/EditorAdminBar';
import PageSettings from './Pages/PageSettings';
import TemplateSettings from './Pages/CreateTemplate';
import { selectDynamicContentErrors } from 'reducers/uiPageSettingsReducer';

import idx from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

import styles from './Editor.module.scss';
import EditorErrorBoundary from './EditorErrorBoundary';
import UnstackUniversity from './Pages/UnstackUniversity';
import { isDeviceMode } from 'types/LegacyEditor';
import PageCanvas from './Editor/PageCanvas';
import { selectHasPermission } from 'reducers/policyReducer';
import { compose } from 'redux';
import withIntegrations from 'containers/withIntegrations';
import { isEqual } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { OnMount, OnUnmount } from 'hooks/mountUnmountHooks';
import { useResources } from 'lib/redux-resource/hooks';

type Props = {
  integrationsBySlug: { [key: string]: any };
  isNew: boolean;
  pageType: string;
};

const Editor = React.memo((props: Props) => {
  const { urlSite, pageId } = useParams();
  const dispatch: Dispatch<any> = useDispatch();
  // @ts-ignore
  const site = useSelector((state) => siteSelectors.selectSiteByDomain(state, urlSite));
  const page = useSelector((state) => pageSelectors.selectPage(state, pageId));
  let autoShowUnstackUniversity = siteSelectors.getAutoShowUnstackUniversity(site);
  const navigate = useNavigate();

  const components = useResources('sectionTypes');
  const pageName = idx(page, 'name');
  const dataTable = idx(page, 'data_table');
  const pageType = props.pageType || idx(page, 'item_type');
  const pageIsNull = page == null && !props.isNew;
  const pageIsFetching = page && page.isFetching;
  const pageContentNotLoaded = page && page.content == null;
  const pageStatus = page && page.status;
  const pageVersionCount = page && page.version_count;
  const pageIsChanged = page && page.is_changed;
  const pageCreatedDate = page && page.created_at;
  const errors = useSelector(selectDynamicContentErrors);
  const isShopifyConnected = useSelector((state) =>
    // @ts-ignore
    integrationsReducer.selectIntegrationBySlug(state, site.id, 'shopify')
  );
  const isFetchingSite = useSelector(siteSelectors.selectSitesFetching);
  const uiState = useSelector(uiSelectors.selectUiState);
  autoShowUnstackUniversity = autoShowUnstackUniversity === undefined ? true : autoShowUnstackUniversity;
  const subscriptionHistory = useSelector(subscriptionSelectors.selectSubscriptionHistory);
  const accountUser = useSelector(accountUserSelectors.selectCurrentAccountUser);
  const isFetchingLayout = useSelector(siteLayoutSelectors.selectLayoutIsFetching);
  const hasPermission = useSelector(selectHasPermission);
  const [loadedIframe, setLoadedIframe] = useState(!isDeviceMode());
  const [unstackUniversityOpen, setUnstackUniversityOpen] = useState(autoShowUnstackUniversity);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [sidebarWidth, setSidebarWidth] = useState<number>(440);
  const [introForEditorIsOpen, setIntroForEditorIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [needHelpIsOpen, setNeedHelpIsOpen] = useState(false);
  const [pendingCreation, setPendingCreation] = useState(true);

  const { isNew, integrationsBySlug } = props;

  const iframe = document.getElementById('editorIframe');
  const myDocument = iframe ? (iframe as any).contentWindow.document : document;
  const doResize = () => setWindowWidth(window.innerWidth);

  function createPage() {
    const isShopifyConnected = site['is_shopify'];
    const siteId = site.id;

    const page: { [key: string]: any } = {
      site: siteId,
      item_type: pageType,
      slug: null,
      content: undefined,
    };

    if (pageType === 'landing_page') {
      page.content = {
        sections: [
          {
            id: uuidv4(),
            content: {},
            type: isShopifyConnected ? 'product' : 'cta',
          },
        ],
      };
    }

    api.createPage(page).then((response) => {
      if (response.ok) {
        const page = response.json;
        const entities = { pages: { [page.id]: page } };
        dispatch(pageActions.receivePage(entities));
        navigate(`/${urlSite}/editor/${page.id}`);
      }
    });
  }

  function handleClick() {
    if (accountUser) {
      dispatch(
        accountUserActions.update({
          ...accountUser,
          metadata: {
            ...accountUser.metadata,
            dismissHelpPromptDate: new Date(),
          },
        })
      );
    }
    setNeedHelpIsOpen(false);
  }

  function handleGetStarted() {
    setIntroForEditorIsOpen(false);
  }

  function preventShowIntroEditModal() {
    if (accountUser) {
      dispatch(
        accountUserActions.update({
          ...accountUser,
          metadata: {
            ...accountUser.metadata,
            isIntroEditModalDoNotShow: true,
          },
        })
      );
    }
    setIsChecked(!isChecked);
  }

  function loadAlgoliaScript(config: any) {
    if (!myDocument.getElementById('editor-algolia-script')) {
      const algolia = myDocument.createElement('script');
      algolia.id = 'editor-algolia-script';
      algolia.dataset.appId = config.application_id;
      algolia.dataset.apiKey = config.search_only_api_key;
      algolia.dataset.index = config.index;
      algolia.src = 'https://cdn.jsdelivr.net/npm/algoliasearch@4.17.0/dist/algoliasearch-lite.umd.js';
      algolia.charset = 'utf-8';
      algolia.type = 'text/javascript';

      myDocument.body.appendChild(algolia);

      const instantSearch = myDocument.createElement('script');
      instantSearch.id = `${config.application_id}-instant-search`;
      instantSearch.src = 'https://cdn.jsdelivr.net/npm/instantsearch.js@4.54.1/dist/instantsearch.production.min.js';
      instantSearch.charset = 'utf-8';
      instantSearch.type = 'text/javascript';

      myDocument.body.appendChild(instantSearch);
    }
  }

  OnMount(() => {
    dispatch(siteLayoutActions.fetchHeaderSections());
    dispatch(siteLayoutActions.fetchFooterSections());
    hasPermission('SiteLayout:list') && dispatch(siteLayoutActions.fetchSiteLayoutIfNeeded());
    window.addEventListener('resize', doResize);
    dispatch(componentActions.requestComponentDefaultsIfNeeded());
    dispatch(accountUserActions.requestAllIfNeeded());
    dispatch(mediaActions.requestPDFs());
    dispatch(paymentAction.requestProductsIfNeeded());
    dispatch(templateActions.requestAll());
    if (isNew) {
      if (!isFetchingSite) {
        setPendingCreation(false);
        createPage();
      }
    } else {
      setPendingCreation(false);
      dispatch(pageActions.requestPage(pageId));
    }

    if (autoShowUnstackUniversity) {
      setUnstackUniversityOpen(autoShowUnstackUniversity);
      dispatch(videosActions.requestAllIfNeeded());
    }

    dispatch(subscriptionActions.requestIfNeeded());
    dispatch(integrationActions.requestAllIfNeeded());
    dispatch({
      type: 'READ_RESOURCES_REQUEST_IF_NEEDED',
      resourceType: 'sectionTypes',
      requestKey: 'fetch',
    });

    if (accountUser) {
      if (accountUser.metadata?.isIntroEditModalDoNotShow || accountUser.metadata?.editorVisitCount > 10) {
        setIntroForEditorIsOpen(false);
        return;
      }

      if (accountUser.metadata?.isIntroEditModalDoNotShow && accountUser.metadata?.editorVisitCount <= 10) {
        const editorCount = accountUser.metadata.editorVisitCount;
        dispatch(
          accountUserActions.update({
            ...accountUser,
            metadata: {
              ...accountUser.metadata,
              editorVisitCount: editorCount + 1,
            },
          })
        );
        setIntroForEditorIsOpen(true);
        return;
      }

      setIntroForEditorIsOpen(true);
      dispatch(
        accountUserActions.update({
          ...accountUser,
          metadata: {
            ...accountUser.metadata,
            editorVisitCount: 1,
            isIntroEditModalDoNotShow: false,
          },
        })
      );
    }
  });

  useEffect(() => {
    if (pageId) dispatch(pageActions.requestPage(pageId));
  }, [pageId]);

  useEffect(() => {
    if (subscriptionHistory && isShopifyConnected) {
      let hasSub = subscriptionHistory.length > 0;
      let subEnded = hasSub && subscriptionHistory[0].ended_on;
      let now = new Date();
      if (!hasSub || (subEnded && new Date(subEnded) < now)) {
        navigate(`/${urlSite}/trial-end`);
      }
    }
  }, [subscriptionHistory, isShopifyConnected]);

  useEffect(() => {
    let today = new Date();
    let pageCreated = new Date(pageCreatedDate);
    let differenceCreated = today.getTime() - pageCreated.getTime();
    let TotalDaysCreated = Math.floor(differenceCreated / (1000 * 3600 * 24));

    if (!pageIsFetching && accountUser) {
      const dismissHelpPromptDate = accountUser.metadata?.dismissHelpPromptDate;
      const disable_needs_help_popup = accountUser.metadata?.disable_needs_help_popup;
      let DaysSinceDismissed;
      if (dismissHelpPromptDate) {
        const date = new Date();
        const dateDismiss = new Date(dismissHelpPromptDate);
        date.setHours(0, 0, 0, 0);
        dateDismiss.setHours(0, 0, 0, 0);
        let difference = date.getTime() - dateDismiss.getTime();
        DaysSinceDismissed = Math.floor(difference / (1000 * 3600 * 24));
      }

      if (
        !disable_needs_help_popup &&
        ((pageStatus === 'draft' && TotalDaysCreated >= 1 && (DaysSinceDismissed > 0 || !dismissHelpPromptDate)) ||
          (pageStatus === 'published' &&
            pageIsChanged &&
            pageVersionCount < 10 &&
            (DaysSinceDismissed >= 7 || !dismissHelpPromptDate)))
      ) {
        setNeedHelpIsOpen(true);
      }
    }
  }, [pageCreatedDate, pageIsFetching, accountUser, pageStatus, pageVersionCount]);

  const prevIntegrationsBySlug = useRef(integrationsBySlug);

  useEffect(() => {
    if (
      integrationsBySlug &&
      integrationsBySlug.algolia &&
      !isEqual(prevIntegrationsBySlug.current?.algolia, integrationsBySlug.algolia)
    ) {
      const [algolia] = integrationsBySlug.algolia;
      loadAlgoliaScript(algolia.config);
    }
    prevIntegrationsBySlug.current = integrationsBySlug;
  }, [integrationsBySlug]);

  OnUnmount(() => {
    dispatch(uiPageSettingsActions.resetState());
    window.removeEventListener('resize', doResize);
  });

  // 404 - Page not found
  if (pageIsNull && !pageIsFetching)
    return (
      <RequireLogin>
        <PageNotFound />
      </RequireLogin>
    );

  const isLoading = pageContentNotLoaded || isNew || isFetchingSite || isFetchingLayout || !components.length;

  const documentTitle = isLoading ? 'Unstack' : `Editing "${pageName || 'New page'}"`;

  return (
    <RequireLogin>
      <DocumentTitle title={documentTitle}>
        <div
          className="Editor"
          style={{
            width: unstackUniversityOpen && !isLoading && windowWidth > 1300 ? windowWidth - sidebarWidth : 'inherit',
          }}
        >
          {introForEditorIsOpen && (
            <IntroEditorModal
              closeModal={handleGetStarted}
              isChecked={isChecked}
              handleOnChange={preventShowIntroEditModal}
            />
          )}
          {needHelpIsOpen && !introForEditorIsOpen && <NeedHelpPrompt onClick={handleClick} />}
          <div className="admin">
            <EditorAdminBar
              toggleUniversity={() => setUnstackUniversityOpen(true)}
              pageType={pageType}
              pageId={pageId}
              isLoadingPage={isLoading}
              width={unstackUniversityOpen && !isLoading && windowWidth > 1300 ? sidebarWidth : undefined}
              sidebarOpen={unstackUniversityOpen}
            />
          </div>
          {(isLoading || !loadedIframe) && !uiState.connectionError && (
            <div className={styles.spinnerContainer}>
              <Spinner size={1} />
            </div>
          )}
          {uiState.connectionError && <EditorErrorBoundary />}
          {!isLoading && (
            <div className={styles.canvas}>
              <>
                <PageCanvas
                  setIFrameLoaded={() => setLoadedIframe(true)}
                  loadedIframe={loadedIframe}
                  pageId={pageId}
                  itemType={pageType}
                  {...(pageType === 'data_page' && { dataTable })}
                />
                {pageType === 'data_page' && <ErrorBar errors={errors} />}
                <PageSettings />
                {!(window as any).Cypress && (
                  <UnstackUniversity
                    open={unstackUniversityOpen}
                    setOpen={(open) => setUnstackUniversityOpen(open)}
                    width={sidebarWidth}
                    setWidth={(sidebarWidth) => setSidebarWidth(sidebarWidth)}
                  />
                )}
                <TemplateSettings />
              </>
            </div>
          )}
        </div>
      </DocumentTitle>
    </RequireLogin>
  );
});

export default compose(withIntegrations)(Editor);
