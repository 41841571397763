import React from 'react';

import { ReactComponent as SvgVideoOption } from '../assets/images/unstack-mark-logo.svg';
import { ReactComponent as SvgIconCancel } from '../assets/images/new-small-x.svg';
import { ReactComponent as SvgIconMagicWand } from '../assets/images/magic-wand.svg';
import { ReactComponent as SvgIconProperties } from '../assets/images/properties.svg';
import { ReactComponent as SvgIconDeviceMobile } from '../assets/images/device-mobile.svg';

import styles from './IntroEditorModal.module.scss';

function IntroEditorModal({ closeModal, isChecked, handleOnChange }: any) {
  return (
    <div>
      <div className={styles.cover} />
      <div className={styles.container}>
        <div className={styles.header}>
          <SvgVideoOption height="48px" width="48px" />
          <span>A different kind of page builder...</span>
          <div className={styles.iconButton}>
            <SvgIconCancel className={styles.icon} onClick={closeModal} />
          </div>
        </div>
        <div className={styles.columns}>
          <div className={styles.step}>
            <div className={styles.head}>
              <SvgIconMagicWand />
              <div className={styles.title}>The Goldilocks philosophy</div>
            </div>
            <div className={styles.description}>
              Unstack is made with the Goldilocks <b>“just right”</b> philosophy. It delivers just what you need to
              quickly build pages <b>without the hassle of traditional page builders.</b>
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.head}>
              <SvgIconProperties />
              <div className={styles.title}>Component-driven design</div>
            </div>
            <div className={styles.description}>
              Unstack pages are made from easy-to-use, pre-built section components that{' '}
              <b>handle hundreds of common use cases.</b> Customize or add new components (with your own HTML) in the{' '}
              <b>component editor.</b>
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.head}>
              <SvgIconDeviceMobile />
              <div className={styles.title}>Mobile-ready by default</div>
            </div>
            <div className={styles.description}>
              All Unstack sections look great on Mobile + Desktop by default. <b>Have questions?</b> Open help with the
              question mark icon (?) in upper right corner of the editor.
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.checkboxWrapper}>
            <input
              type="checkbox"
              name="introModal"
              className={styles.checkbox}
              checked={isChecked}
              onChange={handleOnChange}
              id="dont-show-checkbox"
            />
            <label className={styles.checkboxLabel} htmlFor="dont-show-checkbox">
              Don’t show this again
            </label>
          </div>
          <button className={styles.button} onClick={closeModal}>
            <span className={styles.buttonText}>{`Get started ->`}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default IntroEditorModal;
