import React, { ReactNode, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import * as api from '../../services/spark-api';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import withSite from '../../containers/withSite';
import { getFullDomainFromSite } from '../../reducers/sitesReducer';

import styles from './NewCategory.module.scss';

import Spinner from '../base/Spinner';
import FormControl from '../base/FormHelpers/FormControl';
import SlugFormControl from '../base/FormHelpers/SlugFormControl';
import NonFieldErrors from '../base/FormHelpers/NonFieldErrors';
import PrefixedInput from '../base/FormHelpers/PrefixedInput';
import DocumentTitle from 'react-document-title';

import sparkLogo from '../../assets/images/elastic-path-logo-wht.svg';
import { ReactComponent as SvgIconCancel } from '../../assets/images/icon-cancel.svg';

import { set, del, insert, push, assign } from 'object-path-immutable';
import withBlogs from '../../containers/withBlogs';
import * as categoriesActions from '../../actions/categoriesActions';
import LinkField from 'components/Pages/LinkField';
import { USite } from 'types/USite';
import { OnMount } from 'hooks/mountUnmountHooks';

type Props = {
  site: USite;
  blogsById: { [key: string]: any };
  siteIsFetching: boolean;
};

function NewCategory(props: Props) {
  const { urlSite } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { site, blogsById, siteIsFetching } = props;
  const fullDomain = getFullDomainFromSite(props.site);

  const [isFetching, setIsFetching] = useState(false);
  const [category, setCategory] = useState<{ [key: string]: any }>({ slug: null, meta: {} });
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [isFieldsEmpty, setIsFieldsEmpty] = useState(false);

  const blog = blogsById[category.blog] || {};

  const blogSlug =
    blog.config && blog.config.urls.category === 'blog/category' ? `${blogsById[category.blog].slug}/` : '';

  OnMount(() => {
    const blogs = Object.values(blogsById);
    if (blogs.length === 1) {
      setCategory(set(category, 'blog', blogs[0].id));
    }
  });

  useEffect(() => {
    const blogs = Object.values(blogsById);
    if (blogs.length === 1) {
      setCategory(set(category, 'blog', blogs[0].id));
    }
  }, [blogsById]);

  const handleChange = (nameOrEvent: any, value: any) => {
    // Accept (name, value) argument pair or event
    let name;
    if (nameOrEvent.target) {
      name = nameOrEvent.target.name;
      value = name === 'prepend_slug' ? nameOrEvent.target.checked : nameOrEvent.target.value;
    } else {
      name = nameOrEvent;
    }

    setCategory(set(category, name, value));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateThanCreate();
  };

  const handleCreateBlogAndArticle = (e: any) => {
    e.preventDefault();
    validateThanCreate(true);
  };

  const validateThanCreate = (withArticle?: boolean) => {
    const { slug, meta, blog, name } = category;
    const isValid = Boolean(slug && meta.title && name && blog);
    if (isValid) createCategory(withArticle);
    else setIsFieldsEmpty(true);
  };

  function createCategory(withArticle = false) {
    api.createCategory({ ...category, site: site.id }).then((response) => {
      if (response.ok) {
        dispatch(categoriesActions.requestList());

        if (withArticle) {
          api
            .createArticle({
              site: site.id,
              slug: null,
              categories: [response.json.id],
              blog: response.json.blog,
            })
            .then((response) => {
              if (response.ok) {
                navigate(`/${urlSite}/editor/blog-post/${response.json.id}`);
              }
            });
        } else {
          navigate(`/${urlSite}/blogs`);
        }
      } else {
        setIsFetching(false);
        setErrors(response.json);
      }
    });
  }
  // const {
  //   site,
  //   fullDomain,
  //   siteIsFetching,
  //   blogsById,
  //   match: {
  //     params: { urlSite },
  //   },
  // } = this.props;
  /*

  return {
    fullDomain: getFullDomainFromSite(site),
  };
}

const mapDispatchToProps = {
  requestCategories: categoriesActions.requestList,
};*/

  return (
    <div className={styles.NewBlog + ' admin'} data-test-id="category-creation-container">
      <DocumentTitle title={`Create a blog for ${site.name}`} />
      <header>
        <div className={styles.left}>
          <Link className={styles.logo} to={`/${urlSite}/blogs`}>
            <img src={sparkLogo} alt="Unstack logo" />
          </Link>
        </div>
        <h1>Create a new category</h1>
        <div className={styles.right}>
          <Link className={styles.close} to={`/${urlSite}/blogs`}>
            <SvgIconCancel />
          </Link>
        </div>
      </header>

      {siteIsFetching && <Spinner />}

      {!siteIsFetching && (
        <form className="white-inputs" onSubmit={handleSubmit}>
          <fieldset>
            <label htmlFor="name_input">Unstack name</label>
            <FormControl
              id="name_input"
              type="text"
              name="name"
              value={category.name || ''}
              errors={errors.name || (isFieldsEmpty && !category.name) ? 'This field is required' : null}
              onChange={handleChange}
              autoComplete="off"
              maxLength={65}
              autoFocus
            />
            <small>This will be used in your Unstack portal only.</small>
          </fieldset>
          <fieldset>
            <label htmlFor="title_input">Title</label>
            <FormControl
              id="title_input"
              type="text"
              name="meta.title"
              value={category.meta.title || ''}
              onChange={handleChange}
              imitate={category.name}
              errors={errors.title || (isFieldsEmpty && !category.meta.title) ? 'This field is required' : null}
              autoComplete="off"
              maxLength={65}
            />
            <small>This will be used in the browser tab and should be 65 characters or less.</small>
          </fieldset>
          <fieldset>
            <FormControl
              tag="select"
              aria-label="related blog"
              name="blog"
              errors={errors.blog || (isFieldsEmpty && !category.blog) ? 'This field is required' : null}
              onChange={handleChange}
              value={category.blog || ''}
            >
              <option value="">Select related blog</option>
              {Object.values(blogsById).map((blog) => (
                <option key={blog.id} value={blog.id}>
                  {blog.name}
                </option>
              ))}
            </FormControl>
          </fieldset>
          <LinkField
            id="url_input"
            isHomePage={false}
            PrefixedInput={PrefixedInput as unknown as ReactNode}
            prefix={fullDomain + '/' + blogSlug}
            page={category as any}
            handleChange={handleChange}
            errors={{ slug: errors.slug || (isFieldsEmpty && !category.slug) ? 'This field is required' : null }}
          />
          <fieldset>
            <label htmlFor="description_input">Category description</label>
            <FormControl
              id="description_input"
              tag="textarea"
              type="text"
              name="meta.description"
              value={category.meta.description || ''}
              onChange={handleChange}
              errors={errors.description}
              autoComplete="off"
            />
            <small>This will be read by search engines.</small>
          </fieldset>
          <NonFieldErrors errors={errors} />
          <button
            type="button"
            data-test-id="create-category-with-article"
            className="button button-primary button-full"
            onClick={handleCreateBlogAndArticle}
          >
            Create category and write an article
          </button>
          <input
            type="submit"
            data-test-id="create-category-only"
            className="button button-full"
            value="Create category only"
          />
        </form>
      )}
    </div>
  );
}

export default compose(withSite, withBlogs)(NewCategory) as () => JSX.Element;
