import MediaEntity from 'components/base/Media/MediaEntity2';
import Placeholder from 'components/unstack-components/Component/elements/inline/MediaElement/Placeholder';
import React from 'react';

import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-checkbox-checked.svg';
import { ReactComponent as SvgIconDownload } from '../../../assets/images/icon-download.svg';

import styles from './ZeroState.module.scss';

export default () => {
  return (
    <section className={styles.container}>
      <div className={styles.statements}>
        <span>
          Unstack's DataTables let you use data from spreadsheets to create dynamically generated webpages in minutes.
          Here are some ideas...
        </span>
        <ul>
          <li>
            <SvgIconAdd />
            Quickly build and launch directories
          </li>
          <li>
            <SvgIconAdd />
            Build large scale content sites with thousands of pages
          </li>
          <li>
            <SvgIconAdd />
            Scale your traffic and SEO with targeted content at scale
          </li>
        </ul>
        <div className={styles.download}>
          <SvgIconDownload height={40} width={40} />
          <p>Download our CSV template</p>
        </div>
      </div>
      <div className={styles.media}>
        <Placeholder label={'Video'} />
      </div>
    </section>
  );
};
