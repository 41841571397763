import { useProducts } from 'components/Storefront/hooks';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { UProduct } from 'types/UProduct';

const withProducts = (WrappedComponent: React.FunctionComponent<any>) => {
  return (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    const [productQuery, setProductQuery] = useState('');
    const [data, isLoading] = useProducts(productQuery.trim(), 10, '', '', true);

    const setQueryThrottled = useCallback(
      throttle((query: string) => {
        setProductQuery(query);
      }, 2000),
      []
    );

    const [products, setProducts] = useState<UProduct[]>([]);

    useEffect(() => {
      if (data.data.length > 0 && !isLoading) {
        const newProducts = [...data.data, ...products].filter(
          (value, index, self) => index === self.findIndex((t) => t.id === value.id)
        );
        setProducts(newProducts);
      }
    }, [data, isLoading]);

    return (
      <WrappedComponent
        products={products}
        setProducts={setProducts}
        {...props}
        productsLoading={isLoading}
        setProductQuery={setQueryThrottled}
      />
    );
  };
};

export default withProducts;
