export class Dom {
  public static frame: HTMLIFrameElement = null;

  public static getDocument() {
    const iframe = document.getElementById("editorIframe") as HTMLIFrameElement;
    if(iframe) {
      return iframe?.contentWindow?.document
    } else {
      return document
    }
  }

  public static getContentWindow(): any {
    const iframe = document.getElementById("editorIframe") as HTMLIFrameElement
    return iframe?.contentWindow || window;
  }

  public static getBaseEl() {
    return this.getEl('unstack');
  }

  public static isRenderedPage() {
    return !!this.getBaseEl();
  }

  public static isLiquid():boolean {
    const baseEl = this.getBaseEl();
    return !(baseEl && baseEl.tagName.toLowerCase() === 'body');
  }

  public static getPathPrefix() {
    return this.getBaseEl()?.getAttribute('data-path-prefix') || '';
  }

  public static prefixPath(path: string) {
    return this.getPathPrefix() + (path || '');
  }

  public static getStaticPath() {
    return this.getBaseEl()?.getAttribute('data-static-path') || '';
  }

  public static staticPath(path: string) {
    return this.getStaticPath() + path;
  }

  public static el(e:Event, tag:string):HTMLElement {
    let el:HTMLElement = <HTMLElement>(e.target || e.srcElement);
    return tag ? Dom.prnt(el, tag) : el;
  }

  public static prnt(el:HTMLElement, tag:string):HTMLElement {
    tag = tag.toLowerCase();
    while (el.parentElement && el.tagName.toLowerCase() != tag) {
      el = <HTMLElement>el.parentElement;
    }
    return (el && el.tagName.toLowerCase() == tag) ? el : null;
  }

  public static ind(el:HTMLElement, parent:HTMLElement):number {
    if (parent) {
      let nodes = parent.querySelectorAll ? parent.querySelectorAll(el.tagName) : null;
      if (nodes && nodes.length) {
        for (let i = 0; i < nodes.length; i++) {
          if (el == nodes[i]) {
            return i + 1;
          }
        }
      }
    }
    return null;
  }

  public static getEl(idOrSelector:string):HTMLElement {
    let el = this.getDocument().getElementById(idOrSelector) || Dom.query(idOrSelector);
    return <HTMLElement>el;
  }

  public static getEls(selector: string):Array<HTMLElement> {
    return Array.from(this.queryAll(selector)).map(el=><HTMLElement>el);
  }

  public static getElsByTagName(tagName: string):Array<HTMLElement> {
    return Array.from(this.getDocument().getElementsByTagName(tagName)).map(el=><HTMLElement>el);
  }

  public static getElByDataAttr(attr: string) {
    return <HTMLElement>this.query(`[data-` + attr + `]`)
  }

  public static getElsByDataAttr(attr:string):Array<HTMLElement> {
    return Array.from(this.queryAll(`[data-${attr}]`)).map(el=><HTMLElement>el);
  }

  public static query(selector:string):HTMLElement {
    return <HTMLElement>this.getDocument().querySelector(selector);
  }

  public static queryAll(selector:string):Array<HTMLElement> {
    return Array.from(this.getDocument().querySelectorAll(selector)).map(el=><HTMLElement>el);
  }

  

  public static htmlToElement(html: string):HTMLElement {
    const template = this.createEl('template') as HTMLTemplateElement;
    template.innerHTML = html.trim();
    return template.content.firstChild as HTMLElement;

  }

  public static createEl(el: string, classes:any=null, attrs:any=null, contents:any=null):HTMLElement {
    let element:HTMLElement = <HTMLElement>this.getDocument().createElement(el);

    if (classes) {
      if (!classes.forEach) {
        classes = [classes];
      }
      classes.forEach((cls:string) => {
        element.classList.add(cls);
      });
    }

    if (attrs) {
      for (let attr in attrs) {
        element.setAttribute(attr, attrs[attr]);
      }
    }

    if (contents) {
      if (!contents.forEach) {
        contents = [contents];
      }
      contents.forEach((content:any) => {
        if (!(content instanceof Node)) {
          content = this.getDocument().createTextNode(String(content));
        }
        if (content instanceof Node) {
          element.appendChild(content);
        }
      })
    }

    return element;
  }

  public static loadExternalCSS(filename: string, onLoad?: any) {
    const fileRef = this.getDocument().createElement("link");
    fileRef.setAttribute("rel", "stylesheet");
    fileRef.setAttribute("type", "text/css");
    fileRef.setAttribute("href", filename);
    if (onLoad) {
      fileRef.onload = onLoad;
    }
    this.getElsByTagName("head")[0].prepend(fileRef);
  }

  public static createDynamicStyleSheet(css: string) {
    let sheet = this.createEl('style', null, {innerHTML: css});
    this.getDocument().body.appendChild(sheet);
  }

  public static loadExternalJS = function(path: string, id: string, onLoad?: any) {
    const script = Dom.createEl('script', null, {src: path, async: true, id: "unstack-external-script-" + id});
    if (onLoad) {
      script.onload = onLoad;
    }
    Dom.getDocument().body.appendChild(script);
  }

  public static externalScriptLoaded = function(id: string) {
    return !!Dom.query('#' + "unstack-external-script-" + id);
  }
}