import React, { useState, useEffect, Dispatch } from 'react';
import omit from 'lodash/omit';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as accountActions from '../../actions/accountActions';
import DocumentTitle from 'react-document-title';

import styles from './Signup.module.scss';

import SignupHeader from './SignupHeader';
import CreateAccount from './CreateAccount';
import ThemeSelection, { THEMES_RECOMMENDATION_MAP } from './ThemeSelection';
import { selectAccount } from '../../reducers/accountReducer';
import { selectAvailableThemes } from '../../reducers/themesReducer';
import * as siteActions from '../../actions/siteActions';
import { requestThemes } from '../../actions/themesActions';

const NUM_STEPS = 2;

function SignupUnstack() {
  const dispatch: Dispatch<any> = useDispatch();
  const account = useSelector(selectAccount);
  const availableThemes = useSelector(selectAvailableThemes);
  const meta = account.meta;

  const [step, setStep] = useState((account.meta && account.meta.onboarding_step) || 0);
  const [selectedBusiness, setSelectedBusiness] = useState((account.meta && account.meta.selected_business) || '');
  const [recommendedTheme, setRecommendedTheme] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (step === 0) dispatch(accountActions.logout());
    dispatch(requestThemes());
  }, []);

  useEffect(() => {
    if (!meta) return;
    setStep(meta.onboarding_step);
    setSelectedBusiness(meta.selected_business);
  }, [meta]);

  const gotoNextStep = (additionalMetaData = {}, newAccount?: any) => {
    const currentStep = step;
    if (currentStep < NUM_STEPS) {
      const nextStep = currentStep + 1;
      const updatedAccount = omit(newAccount || account, [
        'isLoggedIn',
        'isFetching',
        'loginErrors',
        'jwt',
        'subdomain',
      ]);
      dispatch(
        accountActions.updateOnboardingStep({
          ...updatedAccount,
          meta: {
            ...meta,
            onboarding_step: nextStep,
            selected_business: selectedBusiness,
            ...additionalMetaData,
          },
        })
      );
      setStep(nextStep);
      window.scrollTo(0, 0);
    }
  };

  const renderFlag = !account.jwt || account.id;

  return (
    <div className="admin">
      <DocumentTitle title="Create an Unstack account" />
      {renderFlag && (
        <>
          <SignupHeader
            step={step}
            lastStep={NUM_STEPS}
            gotoNextStep={gotoNextStep}
            recommendedTheme={recommendedTheme}
            createSite={() => {
              gotoNextStep();
              dispatch(
                siteActions.create({
                  account: account.id,
                  theme: recommendedTheme.id,
                })
              );
            }}
          />
          <main className={styles.main}>
            {step === 0 && (
              <CreateAccount
                gotoNextStep={(metaData, account) => {
                  dispatch(requestThemes());
                  gotoNextStep(metaData, account);
                }}
              />
            )}
            {step === 1 && !!availableThemes.length && !!meta && (
              <ThemeSelection
                availableThemes={availableThemes}
                recommendedTheme={recommendedTheme}
                accountId={account.id}
                createSite={(payload: any) => {
                  dispatch(siteActions.create(payload));
                }}
                domain={meta.domain}
                gotoNextStep={gotoNextStep}
                updateRecommendedTheme={() => {
                  setRecommendedTheme(
                    availableThemes.find((theme: { name: string }) =>
                      // @ts-ignore
                      theme.name.toLowerCase().includes(THEMES_RECOMMENDATION_MAP[selectedBusiness])
                    ) || {}
                  );
                }}
              />
            )}
            {step === 2 && null /* Render nothing at step 2 */}
          </main>
        </>
      )}
    </div>
  );
}

export default SignupUnstack;
