import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import * as api from '../../services/spark-api';
import { selectIsLoggedIn } from '../../reducers/accountReducer';

import FormControl from '../base/FormHelpers/FormControl';
import LogoBanner from '../base/LogoSimpleBanner';

import styles from './Login.module.scss';

function ForgotPassword() {
  const [state, setState] = useState({
    email: '',
    status: 'READY',
  });

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, status: 'FETCHING' }));
    const { email } = state;
    api
      .requestPasswordResetToken(email)
      .then(() => setState((prevState) => ({ ...prevState, status: 'SUCCESS' })))
      .catch(() => setState((prevState) => ({ ...prevState, status: 'ERROR' })));
  };

  const { email, status } = state;

  return (
    <div className="admin">
      <DocumentTitle title="Unstack | Forgot password" />
      <main className={styles.Login}>
        <LogoBanner />
        {/* @ts-ignore */}
        <form onSubmit={handleSubmit} disabled={status !== 'READY'}>
          <fieldset>
            <FormControl
              className={status === 'SUCCESS' || status === 'FETCHING' ? 'active' : ''}
              type="email"
              name="email"
              value={email}
              placeholder="Email"
              autoComplete="username email"
              onChange={handleInputChange}
              disabled={status !== 'READY'}
              autoFocus
            />
          </fieldset>
          {status !== 'SUCCESS' && (
            <input
              type="submit"
              className="button button-full button-primary"
              value={status === 'FETCHING' ? 'Sending...' : 'Send reset password email'}
            />
          )}
          {(status === 'READY' || status === 'FETCHING') && (
            <small className={styles.terms}>
              Enter your email address and we'll send you a link to reset your password.
            </small>
          )}
          {status === 'SUCCESS' && (
            <small className={styles.terms}>
              <span className={styles.success}>Success!</span> If there is an Elastic Path Studio account for {email},
              we have sent instructions there on how to reset your password.
            </small>
          )}
          {status === 'ERROR' && <small className="errorMessage">Connection error.</small>}
        </form>
        {!isLoggedIn && (
          <footer>
            Remember your password? <Link to="/login">Log in</Link>
          </footer>
        )}
        {isLoggedIn && (
          <footer>
            Don’t want to change it?{' '}
            <button className="clickable" onClick={() => navigate(-1)}>
              Back to Elastic Path Studio
            </button>
          </footer>
        )}
      </main>
    </div>
  );
}

export default ForgotPassword;
