import React, { useState, useRef } from 'react';
import DocumentTitle from 'components/DocumentTitle';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { authorizedGet } from 'services/spark-api-react-query';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import Spinner from 'components/base/Spinner';
import { useLocalDraft } from 'hooks/useLocalDraft';
import { FormContentEditor } from './FormContentEditor';
import iconBack from 'assets/images/icon-admin-panel-back.svg';
import { ReactComponent as SvgIconDiscard } from 'assets/images/icon-discard-changes.svg';
import styles from './FormEditor.module.scss';
import { FormData, FormMutationError, FormMutationResponse, NewFormData } from './types';
import qs from 'query-string';
import classnames from 'classnames';
import { selectNotificationBarIsOut } from 'reducers/notificationReducer';
import { authorizedPost, authorizedPut } from 'services/spark-api';
import useActiveSite from 'hooks/useActiveSite';
import useEphemeralState from 'hooks/useEphemeralState';
import flatten from 'lodash/flatten';
import FormTitle from './FormTitle';

type StrBuildOrSettings = 'build' | 'settings';

export default function FormEditor() {
  const match = useMatch('/:urlSite/forms/:formId?');
  const { urlSite, formId } = match.params;
  const location = useLocation();
  const query = qs.parse(location.search);
  const navigate = useNavigate();

  const tab: StrBuildOrSettings = (query.tab as StrBuildOrSettings) || 'build';

  const { state } = location;

  const siteId = useActiveSite((site) => site.id);

  const notificationBarIsOut = useSelector((state) => selectNotificationBarIsOut(state, urlSite));

  // If `formId` === 'new', then the user is creating a new form.
  const isNew = formId === 'new';

  // Manage state of which page is visible
  const [editorPage, setEditorPage] = useState<'build' | 'settings'>(tab);

  // Form data from API
  const { data: persistedForm, error: queryError } = useQuery<FormData>(
    ['form', formId],
    () => authorizedGet(`form/${formId}/`),
    {
      enabled: !isNew,
    }
  );

  const mutation = useMutateForm();

  // Local draft version of the form
  const {
    draft,
    isDirty,
    update: updateDraft,
    discard: _discardDraft,
  } = useLocalDraft<NewFormData | FormData>(isNew ? createSeedForm(siteId, state) : persistedForm);

  function discardDraft() {
    _discardDraft();
    setMutationError(undefined);
  }

  const [shouldShowSuccess, showSuccess] = useEphemeralState(false, true, 4000);
  const [mutationError, setMutationError] = useState<FormMutationError>();
  const titleRef = useRef<any>();

  const handleSave: React.MouseEventHandler = (e) => {
    e.preventDefault();

    if (isDirty || state) {
      // Validate
      if (!draft.name) {
        setMutationError({ non_field_errors: ['Name is required.'] });
        if (titleRef.current) {
          titleRef.current.focus();
          titleRef.current.className = styles.errorBorder;
        }
      } else {
        // Looks good. Update away.
        if (titleRef.current) {
          titleRef.current.className = '';
        }
        setMutationError(undefined);
        mutation.mutate(draft, {
          onSuccess: (response) => {
            if (response.ok) {
              const form = response.json as FormData;
              navigate(`/${urlSite}/forms/${form.id}`);
              discardDraft();
              showSuccess();
              setEditorPage('settings');
            } else {
              if (titleRef.current) {
                titleRef.current.focus();
                titleRef.current.className = styles.errorBorder;
              }
              setMutationError(response.json as FormMutationError);
            }
          },
        });
      }
    }
  };

  if (queryError) return <div>Uh oh. An error occurred.</div>;

  const statusMessage = (function () {
    if (shouldShowSuccess) {
      return (
        <span data-test-id="saved" className={`button-text mr-4 text-muted ${styles.successMessage}`}>
          Saved!
        </span>
      );
    }

    if (mutation.isLoading) {
      return (
        <span className={`button-text mr-4 text-muted ${styles.statusMessage}`} data-test-id="saving">
          Saving...
        </span>
      );
    }

    if (isDirty) {
      return (
        <button className={`button-text mr-4 text-muted ${styles.discardButton}`} onClick={discardDraft}>
          <SvgIconDiscard className={styles.discardIcon} />
          Discard changes
        </button>
      );
    }
  })();

  const saveButton = (isDirty || shouldShowSuccess || state) && (
    <button
      className="button button-primary"
      disabled={(mutation.isLoading || !isDirty) && !state}
      onClick={handleSave}
    >
      Save
    </button>
  );

  return (
    <div
      className={styles.container}
      data-test-id="form-canvas-container"
      style={{ opacity: mutation.isLoading ? 0.6 : 1 }}
    >
      {/* @ts-ignore */}
      <DocumentTitle title="Form" />
      <div
        className={classnames(styles.header, {
          [styles.notificationBarIsOut]: notificationBarIsOut,
        })}
        data-test-id="header"
      >
        <div className={styles.headerLeft}>
          <Link to={`/${urlSite}/forms`} data-test-id="back-to">
            <img src={iconBack} className={`${styles.backIcon} mr-4`} />
          </Link>
          {draft && (
            <div style={{ height: mutationError ? '46px' : 'auto' }}>
              <FormTitle isCopy={!!state} value={draft} onChange={updateDraft} titleRef={titleRef} />
              {draft && errorMessages(mutationError)}
            </div>
          )}
        </div>
        <div className={styles.headerRight}>
          {statusMessage}
          {saveButton}
        </div>
      </div>
      {!draft && <Spinner style={{ margin: '10rem auto' }} />}
      {draft ? <FormContentEditor value={draft} activeTab={query.tab as string} onChange={updateDraft} /> : null}
    </div>
  );
}

export function useMutateForm() {
  const queryClient = useQueryClient();
  // @ts-ignore
  window.queryClient = queryClient;

  return useMutation(
    (form: NewFormData | FormData) => {
      if ((form as FormData).id) return authorizedPut(`form/${(form as FormData).id}/`, form);
      else return authorizedPost('form/', form);
    },
    {
      onSuccess: (response: FormMutationResponse) => {
        if (response.ok) {
          const form = response.json as FormData;

          // Update react-query caches
          // @ts-ignore
          queryClient.invalidateQueries('forms');
          queryClient.setQueryData(['form', form.id], form);
        }
      },
    }
  );
}

function errorMessages(errors: FormMutationError | undefined) {
  if (!errors) return null;

  const messages = flatten(Object.values(errors));

  return (
    <div className={styles.errorMessages}>
      {messages.map((message, i) => {
        // Override known confusing messages from the backend.
        let modifiedMessage = (function () {
          switch (message) {
            case 'The fields site, name must make a unique set.':
              return 'Name must be unique.';
            default:
              return message;
          }
        })();

        return (
          <div key={i} className="errorMessage">
            {modifiedMessage}
          </div>
        );
      })}
    </div>
  );
}

//  Temporarily setting the state to be any type
function createSeedForm(siteId: SiteType['id'], state?: any): NewFormData {
  return {
    site: siteId,
    name: state?.name || '',
    content: state?.content || {
      fields: [
        [
          {
            id: 101,
            name: 'first_name',
            type: 'text',
            default: 'First name',
            placeholder: 'First name',
            label: '',
          },
          {
            id: 102,
            name: 'last_name',
            type: 'text',
            default: 'Last name',
            placeholder: 'Last name',
            label: '',
          },
        ],
        [
          {
            id: 104,
            name: 'email',
            type: 'email',
            label: '',
            default: 'Email',
            required: true,
            placeholder: 'Email address',
          },
        ],
        {
          id: 110,
          name: 'organization',
          type: 'text',
          default: 'Company name',
          placeholder: 'Company name',
          label: '',
        },
        [{ id: 200, type: 'submit', initial: 'Submit', name: 'submit' }],
      ],
      contact_lists: {},
    },
    action: state?.action || {
      type: 'message',
      value: 'Submit',
      message: 'Thank you for submitting this form.',
    },
  };
}
