/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useMemo, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import relativeDate from 'lib/relative-date';
import { runSyncJobs } from 'services/spark-api';

import Spinner from 'components/base/Spinner';
import SideDrawer from 'components/base/SideDrawer';
import FormControl from 'components/base/FormHelpers/FormControl';

import * as siteActions from 'actions/siteActions';
import * as sitesSelectors from 'reducers/sitesReducer';
import { selectPartner } from 'reducers/accountReducer';

import { PARTNERS } from '../../../../constants';

import styles from './Shopify.module.scss';

export default (props) => {
  const { isOpen, config, openDisconnectionModel, id } = props;

  const activeSite = useSelector(sitesSelectors.selectActiveSite);
  const partner = useSelector(selectPartner);

  const [syncStatus, setSyncStatus] = useState();
  const dispatch = useDispatch();

  const lastSyncDate = useMemo(() => {
    const timeStamp = new Date(props.lastSyncTimeStamp * 1000);
    return timeStamp;
  }, [props.lastSyncTimeStamp]);

  // TODO: Move this syncing to saga
  const sync = (e) => {
    e.preventDefault();
    setSyncStatus({ isLoading: true });
    runSyncJobs(id)
      .then((res) => {
        if (res.status === 200) setSyncStatus({ isLoading: false, message: res.json.message });
        else
          setSyncStatus({
            isLoading: false,
            message: res.json.error || 'There was a problem while syncing',
          });
      })
      .catch((_err) => {
        setSyncStatus({
          isLoading: false,
          error: _err || 'There was a problem while syncing',
        });
      });
  };

  close = () => {
    setSyncStatus();
    props.close();
  };

  return (
    <SideDrawer isOpen={isOpen} title="Shopify settings" close={close}>
      <form>
        <fieldset>
          <label>
            You're connected to <strong>{config.shop_name}</strong> on Shopify.
          </label>
        </fieldset>
        <fieldset>
          <label>Store Domain</label>
          <FormControl type="text" disabled value={`${config.shop}.myshopify.com`} />
        </fieldset>
        <fieldset>
          <label>Storefront API Key</label>
          <FormControl type="text" disabled value={config.access_token} />
        </fieldset>
        <fieldset>
          <label>Cart Language & Icon</label>
          <FormControl
            onChange={(e) => {
              const updatedSite = set(activeSite, 'metadata.cart_language', e.target.value);
              dispatch(siteActions.update(updatedSite));
            }}
            tag="select"
            value={activeSite.metadata.cart_language || 'cart'}
          >
            <option value="cart" key="cart">
              Cart
            </option>
            <option value="bag" key="bag">
              Bag
            </option>
          </FormControl>
        </fieldset>
        {/* <fieldset>
          <label>Shopify Admin API Key</label>
          <FormControl type="text" disabled value={config.storefront_key} />
        </fieldset> */}

        <fieldset>
          <label>Products</label>
          <div className={styles.syncContainer}>
            <button className="button button-primary button-small" onClick={sync} disabled={syncStatus?.isLoading}>
              {syncStatus?.isLoading ? 'Syncing...' : ' Sync now'}
            </button>
            {syncStatus === undefined ? (
              <small>Last sync {relativeDate(lastSyncDate)}</small>
            ) : syncStatus.isLoading ? (
              <Spinner />
            ) : (
              <small>{syncStatus?.message}</small>
            )}
          </div>
        </fieldset>
        {partner?.id !== PARTNERS.Shopify ? (
          <fieldset>
            <input
              value="Disconnect"
              className="button button-secondary button-full"
              readOnly
              onClick={() => openDisconnectionModel(true)}
            />
          </fieldset>
        ) : null}
      </form>
    </SideDrawer>
  );
};
