import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { arrayMove } from 'lib/SortListHoc';
import { set, del, insert, push, assign } from 'object-path-immutable';
import { ReactSortable } from 'react-sortablejs';

import { CollectionTagArgs } from 'components/unstack-components/tag-types';
import { SectionHandlersInterface, ContainerInfo } from 'components/unstack-components/types';
import { getItemKey } from '../../util/utils';
import { USectionDevices } from 'types/USection';
import { getDeviceTypeToSaveTo } from '../../util/helpers/deviceHelper';
import { useSelector } from 'react-redux';
import { getDevice } from 'reducers/uiReducer';
import { setActiveToolbar } from 'actions/toolbarActions';

interface CollectionGeneratorProps extends CollectionTagArgs {
  children?: React.ReactChildren;
  sectionHandlers?: SectionHandlersInterface;
  containerInfo?: ContainerInfo;
  defaults: any;
  content: USectionDevices;
  onChange: (content: USectionDevices, key?: string, multi?: boolean) => void;
}

function CollectionGenerator(props: CollectionGeneratorProps) {
  const {
    item,
    tagName,
    minItems,
    onChange,
    className,
    dataRef,
    contentKey,
    defaultItems,
    sectionHandlers,
    containerInfo,
    defaults = {},
    content,
  } = props;

  const [isDragging, setIsDragging] = React.useState(false);
  const dispatch = useDispatch();

  const value = props.dataRef || [];
  const key = containerInfo ? getItemKey(containerInfo, contentKey) : '';
  const splitKey = (key || contentKey).split('.');
  const device = useSelector(getDevice);

  const handleInsert = (i: number) => {
    onChange(
      set(
        content as any,
        [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)],
        insert(value, null, null, i + 1)
      ),
      `content.${splitKey[0]}`
    );
  };

  const handleRemove = (i: number) => {
    onChange(
      set(
        content as any,
        [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)],
        del(value, i as unknown as Path)
      ),
      `content.${splitKey[0]}`
    );
  };

  // If the number of collection items is less than the minimum, then add them.
  useEffect(() => {
    const minimum = minItems || 1;
    const defaultCount = Math.max(defaultItems || 1, minimum);
    if (value.length >= minimum) return;
    let newValue = value;

    if (!value.length) {
      while (newValue.length < defaultCount) {
        newValue = push(newValue, null, null);
      }

      onChange(
        set(content as any, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue),
        `content.${splitKey[0]}`
      );
      return;
    }

    while (newValue.length < minimum) {
      newValue = push(newValue, null, null);
    }

    onChange(
      set(content as any, [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)], newValue),
      `content.${splitKey[0]}`
    );
  }, [value, onChange]);

  // // ReactSortableJS requires a list of objects to store its state in.
  const sortableList = useMemo(() => value.map(() => ({})), [value]);

  let renderedCollection = (
    <ReactSortable
      tag={(tagName || defaults.tagName) as keyof React.ReactHTML}
      className={classNames(className, { 'perspective-none': isDragging })}
      animation={150}
      chosenClass="sortable-chosen"
      ghostClass="sortable-ghost"
      dragClass="sortable-drag"
      handle=".drag-handle"
      //  @ts-ignore
      list={sortableList}
      setList={() => {}}
      onEnd={(event) => {
        const newEl = event.from.children[event.newIndex] as HTMLElement;
        const boxuuid =
          newEl?.dataset.boxUuid || (newEl.querySelector('[data-box-uuid]') as HTMLElement)?.dataset.boxUuid;
        dispatch(setActiveToolbar(boxuuid));
        onChange(
          set(
            content as any,
            [getDeviceTypeToSaveTo(device, true), ...splitKey.slice(1)],
            arrayMove(value, event.oldIndex, event.newIndex)
          ),
          `content.${splitKey[0]}`
        );
        setIsDragging(false);
      }}
      onStart={() => setIsDragging(true)}
    >
      {value.map((listItem: any, i: number) => {
        const newSectionHandlers = {
          ...sectionHandlers,
          collectionHandleInsert: () => handleInsert(i),
          collectionHandleRemove: () => handleRemove(i),
        };

        const childProps = {
          onChange,
          sectionHandlers: { ...newSectionHandlers },
          containerInfo: { contentKey: key || contentKey, index: i },
        };

        return React.cloneElement(item(listItem, i), childProps);
      })}
    </ReactSortable>
  );

  return renderedCollection;
}
export default CollectionGenerator;
