import React, { useRef, useEffect, useState, ReactElement } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import Toolbar from './Toolbar';

import AOS from '../../../lib/aos';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { getDevice } from 'reducers/uiReducer';
import { selectOne } from 'reducers/mediaReducer';

import { requestIfNeeded } from 'actions/mediaActions';

import { IS_VIDEO_REGEX, MEDIA_URL_REGEXP } from '../../../constants';

import noMediaSvg from 'assets/images/icon-media-removed.svg';

import styles from './Box.module.scss';
import { getNumAndSuffix } from '../Toolbars2/components/UpDownCounter';
import { setActiveToolbar, setHoverToolbar } from 'actions/toolbarActions';
import { getActiveToolbar, getHoverToolbar, expandedActionMenu, isCursorOnToolbar } from 'reducers/toolbarReducer';
import usePrevious from 'hooks/usePrevious';
import { capitalizeFirstLetter, reverseStr } from 'lib/reverse';
import { OnMount } from 'hooks/mountUnmountHooks';
import { isBoxDeviceActive } from 'components/unstack-components/Component/elements/block/Section';

const mapDispatchToProps = {
  requestIfNeeded,
};

let lastMouseMoveEv: React.MouseEvent = null;

function mapStateToProps(state: any, ownProps: any) {
  let mediaMatch, mediaId, media;
  if (ownProps?.backgroundImage?.url && (mediaMatch = ownProps?.backgroundImage?.url.match(MEDIA_URL_REGEXP))) {
    mediaId = mediaMatch[1];
    media = mediaId ? selectOne(state, mediaId) : null;
  }
  return {
    mediaId,
    parsedSrc: media ? (media.file ? media.file : noMediaSvg) : null,
    category: media ? media.category_id : 'image',
    poster: media ? (media.poster ? media.poster : noMediaSvg) : null,
    device: getDevice(state),
  };
}

function formatLabelFromContentKey(str: string) {
  let tempLabel = reverseStr(str.replace('box', 'Box'));
  tempLabel = capitalizeFirstLetter(reverseStr(tempLabel.substring(0, tempLabel.indexOf('.'))));
  let newLabel = '';
  let lastChar = '';
  tempLabel.split('').forEach((char) => {
    if (lastChar && lastChar === lastChar.toLowerCase() && char === char.toUpperCase()) {
      newLabel = newLabel + ' ';
    }
    newLabel = newLabel + char;
    lastChar = char;
  });
  return newLabel;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function Box(props: any) {
  const {
    className,
    children,
    renderToolbarItems,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    maxWidth,
    align,
    boxShadow,
    borderRadius,
    backgroundColor,
    borderColor,
    borderWidth,
    borderStyle,
    backgroundImage,
    showBackgroundLayers,
    // foregroundImage,
    textAlign,
    animate,
    shouldTriggerToolbar,
    mediaId,
    parsedSrc,
    requestIfNeeded,
    isProductComponent,
    enableVerticalAlign,
    verticalAlign,
    anchor,
    classes,
    hideTrayTop,
    overrideTag,
    dataAttributes,
    device,
    sectionName,
    boxUUid: overrideBoxUUid,
    shouldOnlyShowHover,
    dataRef,
    backgroundSize,
    backgroundPositionX,
    backgroundPositionY,
    backgroundRepeat,
  } = props;

  useEffect(() => {
    if (mediaId) requestIfNeeded(mediaId);
  }, [requestIfNeeded, mediaId]);

  // Refs
  const boxRef = useRef<HTMLElement>();
  const boxContentRef = useRef<HTMLDivElement>();
  const [boxUUid, setBoxUUid] = useState<string>(overrideBoxUUid || uuidv4());

  const dispatch = useDispatch();

  // Background layers
  const backgroundLayers = [];

  if (backgroundImage && backgroundImage.url) {
    const isVideo = IS_VIDEO_REGEX.test(parsedSrc);

    if (isVideo) {
      backgroundLayers.push(
        <div key="box-background-video" className="box-background box-background-media">
          <div style={{ width: '100%', height: '100%' }}>
            <video
              src={parsedSrc}
              preload="auto"
              autoPlay
              playsInline
              muted
              {...(backgroundImage.loop ? { loop: true } : {})}
              webkit-playsinline
              x5-playsinline
              style={{ width: '100%', height: '100%', borderRadius: `${borderRadius}` }}
            ></video>
          </div>
          <style>{`
            .box-background .box-background-media video {
              object-fit: ${backgroundSize};
              object-position: ${backgroundPositionX} ${backgroundPositionY};
            }
          `}</style>
        </div>
      );
    } else {
      backgroundLayers.push(
        <div
          key="background-image"
          className="box-background box-background-media"
          style={{
            backgroundImage: `url(${parsedSrc})`,
            backgroundSize: backgroundSize,
            backgroundRepeat: backgroundRepeat,
            backgroundPositionX: backgroundPositionX,
            backgroundPositionY: backgroundPositionY,
            borderRadius: `${borderRadius}`,
          }}
        />
      );
    }
  }

  // Background-opacity
  if (backgroundColor || borderStyle || boxShadow) {
    backgroundLayers.push(
      <div
        key="background-color"
        className={`box-background shadow-${boxShadow}`}
        style={{
          backgroundColor: backgroundColor,
          opacity: 1,
          borderColor: borderColor,
          borderWidth: borderWidth ? `${borderWidth}` : '0px',
          borderStyle: borderStyle,
          borderRadius: `${borderRadius}`,
        }}
      />
    );
  }

  const renderedBackgroundLayers = <div className="box-background">{backgroundLayers}</div>;

  // Style logic
  // --------------------------------------------------------------------------

  // Container classnames and styles
  const boxClassNames = [
    { box: !props.overrideTag },
    ...[
      styles.box,
      {
        [styles.selected]: props.isSelected,
      },
    ],
    className,
  ];
  const boxContentClassNames = ['box-content', styles.boxContent];
  const boxStyles: { [key: string]: string } = {};
  const boxContentStyle: { [key: string]: string } = {};
  const boxContentProps: { [key: string]: string } = {};

  if (classes) {
    boxClassNames.push(classes);
  }

  // Set box padding styles
  [
    ['paddingTop', paddingTop],
    ['paddingRight', paddingRight],
    ['paddingBottom', paddingBottom],
    ['paddingLeft', paddingLeft],
  ].forEach(([side, value]) => {
    const valueWithUnits = isNaN(value) ? value : value + 'rem';
    boxStyles[side] = `${value === 'default' ? props.defaultPadding[side] + 'rem' : valueWithUnits}`;
  });

  // Set brightnessTheme classes
  if (props.brightnessTheme) {
    boxClassNames.push(props.brightnessTheme);
  }

  // Set maxWidth properties
  if (maxWidth === 'auto' || maxWidth === 'none' || !maxWidth) {
    boxContentStyle.maxWidth = 'none';
  } else if (maxWidth === 'default') {
    boxContentStyle.maxWidth = props.defaultMaxWidth;
  } else if (maxWidth !== null && ((!isNaN(maxWidth) && Number.isFinite(Number(maxWidth))) || isNaN(maxWidth))) {
    const { numericValue, suffix } = getNumAndSuffix(maxWidth);
    boxContentStyle.maxWidth = `${numericValue}${suffix || 'px'}`;
  }

  // Set align properties
  switch (align) {
    case 'left':
      boxContentStyle.marginRight = 'auto';
      break;
    case 'center':
      boxContentStyle.marginRight = 'auto';
      boxContentStyle.marginLeft = 'auto';
      break;
    case 'right':
      boxContentStyle.marginLeft = 'auto';
      break;
    default:
  }

  if (enableVerticalAlign && verticalAlign) {
    boxClassNames.push(`vertical-align ${verticalAlign}`);
  }

  // Set position
  if (isProductComponent) boxContentStyle.position = `initial`;

  // Text align
  if (textAlign === 'center') boxContentClassNames.push('text-center');
  else if (textAlign === 'left') boxContentClassNames.push('text-left');
  else if (textAlign === 'right') boxContentClassNames.push('text-right');

  // Animate
  if (animate) {
    boxContentProps['data-aos'] = animate;

    // HACK: Preserve the aos classnames that the aos library mutates directly.
    // We should really implement those classes and functionality ourselves,
    // but this is the quick fix to play nice with the library.
    if (boxContentRef.current) {
      const el = boxContentRef.current;
      ['aos-init', 'aos-animate'].forEach((cn) => {
        if (el.classList.contains(cn)) boxContentClassNames.push(cn);
      });
    }
  }

  const prevAnimate = useRef(animate);
  useEffect(() => {
    if (animate !== prevAnimate.current) {
      resetAnimation();
    }
    prevAnimate.current = animate;
  }, [animate]);

  // Toolbar logic
  // --------------------------------------------------------------------------

  const hasToolbar = !!renderToolbarItems;

  const cursorOnToolbar = useSelector(isCursorOnToolbar);
  const cursorOnToolbarRef = useRef(cursorOnToolbar);
  useEffect(() => {
    cursorOnToolbarRef.current = cursorOnToolbar;
  }, [cursorOnToolbar]);

  const actionMenuExpanded = useSelector(expandedActionMenu) !== undefined;
  const hoverToolbar = useSelector(getHoverToolbar);
  const activeToolbar = useSelector(getActiveToolbar);
  const hoverToolbarRef = useRef(hoverToolbar);
  const activeToolbarRef = useRef(activeToolbar);
  const prevActiveToolbar = usePrevious(activeToolbar);
  const toolbarActive = activeToolbar === boxUUid;
  const isBorderShown = hoverToolbar === boxUUid;
  const [hideToolbarTimeout, setHideToolbarTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    hoverToolbarRef.current = hoverToolbar;
    activeToolbarRef.current = activeToolbar;

    if (toolbarActive && hoverToolbar !== activeToolbar && hoverToolbar !== undefined && !hideToolbarTimeout) {
      const toolbarTimeout = setTimeout(() => {
        if (
          !cursorOnToolbarRef.current &&
          hoverToolbarRef.current !== activeToolbarRef.current &&
          hoverToolbar !== undefined
        ) {
          dispatch(setActiveToolbar(undefined));
        }
        setHideToolbarTimeout(undefined);
      }, 2000);
      setHideToolbarTimeout(toolbarTimeout);
    } else if (hideToolbarTimeout && (hoverToolbar === activeToolbar || hoverToolbar === undefined)) {
      clearTimeout(hideToolbarTimeout);
      setHideToolbarTimeout(undefined);
    }
  }, [hoverToolbar, toolbarActive, activeToolbar]);

  // update opacity of toolbar based on active toolbar and if quill is open (isTextSelected)
  useEffect(() => {
    if (props.updateOpacity) {
      if (prevActiveToolbar === boxUUid && !toolbarActive) {
        props.updateOpacity(false);
      } else if (prevActiveToolbar !== boxUUid && toolbarActive) {
        props.updateOpacity(true);
      }
    }
  }, [prevActiveToolbar, toolbarActive, props.updateOpacity]);
  useEffect(() => {
    if (cursorOnToolbar && activeToolbar !== hoverToolbar) dispatch(setHoverToolbar(activeToolbar));
  }, [cursorOnToolbar]);

  function resetAnimation() {
    if (boxContentRef.current) boxContentRef.current.classList.remove('aos-animate');
    setTimeout(() => {
      AOS.refreshHard();
      boxContentRef.current.classList.add('aos-animate');
    }, 550);
  }

  OnMount(() => {
    localStorage.removeItem('firstBubble');
    if (animate) {
      resetAnimation();
    }
  });

  // Event handlers should only be attached if we're showing the toolbar.
  let showBorder: () => void, hideBorder: () => void;
  if (hasToolbar || overrideBoxUUid) {
    showBorder = () => {
      if (boxUUid !== hoverToolbar) {
        dispatch(setHoverToolbar(boxUUid));
      }
    };
    hideBorder = () => {
      const doHide = () => {
        if (!cursorOnToolbarRef.current) {
          dispatch(setHoverToolbar(undefined));
        }
      };
      iframe ? setTimeout(doHide, 100) : doHide();
    };
  }

  let label = <></>;
  if (boxRef.current && boxRef.current.classList.contains('section-header-box')) {
    label = (
      <>
        <span className={styles.labelElement}>Header</span>
      </>
    );
  } else if (props.contentKey || sectionName) {
    const labels: ReactElement[] = [];
    let box: HTMLElement = boxRef.current;
    if (box) {
      while (box) {
        let tempLabel = '';
        if (['mobile', 'tablet'].includes(device) && box.classList.contains('section-box')) {
          tempLabel = box.dataset['sectionName'] ? box.dataset['sectionName'] + ' Section' : 'Section';
        } else {
          tempLabel = formatLabelFromContentKey(box.dataset['contentKey'] || '');
        }
        const elUUid = box.dataset['boxUuid'];
        labels.push(
          <span
            className={styles.labelElement}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setActiveToolbar(elUUid));
              dispatch(setHoverToolbar(elUUid));
            }}
          >
            {tempLabel}
          </span>
        );
        labels.push(<span> -{'>'} </span>);
        // only show breadcrumbs if toolbar active
        if (isBorderShown && !toolbarActive) {
          box = undefined;
        } else {
          box =
            box.parentElement.closest('[data-content-key]') ||
            (['mobile', 'tablet'].includes(device) && box.parentElement.closest('.section-box'));
        }
      }
      labels.pop();
    }
    label = <>{labels.reverse()}</>;
  } else {
    label = (
      <>
        <span
          className={styles.labelElement}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setActiveToolbar(boxRef.current.dataset['boxUuid']));
            dispatch(setHoverToolbar(boxRef.current.dataset['boxUuid']));
          }}
        >
          Section
        </span>
      </>
    );
  }

  // Render
  // --------------------------------------------------------------------------

  function isSectionHeader(node: HTMLElement) {
    return node.classList.contains('section-header-box');
  }

  const getParentBox = (node: any) => {
    let requiredNode = node;

    while (requiredNode.getAttribute) {
      const isBoxElement = requiredNode.getAttribute('data-test-id') === 'box';
      const hasContentKey = Boolean(requiredNode.getAttribute('data-content-key')) || isSectionHeader(requiredNode);

      if (isBoxElement && hasContentKey) {
        return requiredNode;
      }

      requiredNode = requiredNode.parentNode;
    }

    return null;
  };

  const isSelfChild = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (!props.contentKey) {
      return true;
    }

    const parentBox = getParentBox(e.target);
    return parentBox
      ? parentBox.getAttribute('data-content-key') === props.contentKey || isSectionHeader(parentBox)
      : false;
  };

  const Tag = overrideTag || 'div';
  const iframe = document.getElementById('editorIframe');
  const isDeviceMode = window.location.href.includes('editor') && !window.location.href.includes('legacyeditor');

  if (props?.visibility && !isBoxDeviceActive(isDeviceMode, toolbarActive, device, props.visibility)) {
    return <></>;
  }

  return (
    <Tag
      id={anchor || undefined}
      className={classnames(boxClassNames)}
      data-test-id="box"
      data-content-key={props.contentKey}
      data-box-uuid={boxUUid}
      data-section-name={sectionName}
      style={{ ...boxStyles, ...props.style }}
      onClick={() => {
        const box = boxRef.current as HTMLElement;
        if (
          box.classList.contains('section-box') &&
          !(['mobile', 'tablet'].includes(device) && !localStorage.getItem('firstBubble'))
        ) {
          if (localStorage.getItem('firstBubble')) {
            localStorage.setItem('firstBubble', '');
          } else {
            dispatch(setActiveToolbar(undefined));
          }
        } else if (!localStorage.getItem('firstBubble')) {
          if (!box.classList.contains('section-box')) localStorage.setItem('firstBubble', 'true');
          else if (!['mobile', 'tablet'].includes(device)) {
            dispatch(setActiveToolbar(undefined));
            dispatch(setHoverToolbar(undefined));
          }
          dispatch(setActiveToolbar(boxUUid));
        }
      }}
      onMouseMove={(e: React.MouseEvent) => {
        if (e !== lastMouseMoveEv) {
          if (overrideBoxUUid && !['mobile', 'tablet'].includes(device)) return;

          if (!isBorderShown && showBorder && isSelfChild(e)) {
            showBorder();
          }
          lastMouseMoveEv = e;
        }
      }}
      onMouseLeave={() => {
        if (hideBorder) {
          hideBorder();
        }
      }}
      ref={boxRef}
      {...dataAttributes}
    >
      {showBackgroundLayers && renderedBackgroundLayers}
      <div
        {...boxContentProps}
        data-test-id="box-content"
        className={classnames(boxContentClassNames)}
        style={boxContentStyle}
        ref={boxContentRef}
      >
        {children}
      </div>
      {Boolean(shouldTriggerToolbar) && (
        <Toolbar
          hideTrayTop={hideTrayTop}
          isBorderShown={isBorderShown}
          renderItems={renderToolbarItems}
          toolbarActive={toolbarActive}
          label={label}
          drawToolbar={!overrideBoxUUid}
          shouldOnlyShowHover={shouldOnlyShowHover || actionMenuExpanded}
        />
      )}
    </Tag>
  );
});
