import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withSiteId from '../../../../containers/withSiteId';

import * as accountUserActions from '../../../../actions/accountUserActions';
import * as accountUserSelectors from '../../../../reducers/accountUsersReducer';
import * as mediaActions from '../../../../actions/mediaActions';
import { patchOnboarding } from 'actions/accountActions';
import { selectAccount } from 'reducers/accountReducer';
import { selectHasPermission } from '../../../../reducers/policyReducer';

import FormControl from '../../../base/FormHelpers/FormControl';
import ValidationMessage from '../../../base/FormHelpers/ValidationMessage';
import SlugFormControl from '../../../base/FormHelpers/SlugFormControl';
import PrefixedInput from '../../../base/FormHelpers/PrefixedInput';

import idx from 'lodash/get';
import slugify from '../../../../lib/slugify';

import { roleOptions, roleMap } from './Roles';
import { updateSubmittingState } from '../../../../actions/uiSubmitButtonsStateActions';
import { selectSubmissionStateAndEvent } from '../../../../reducers/uiSubmitButtonsStateReducer';
import { updateAccountOwner } from '../../../../services/spark-api';
import styles from '../Users.module.scss';
import { INVITE_COLLABORATORS_COMPLETE } from 'components/AdminPanel/Quickstart/constants';

function mapStateToProps(state, ownProps) {
  const { accountUserId } = ownProps;

  return {
    accountUser: accountUserSelectors.selectAccountUser(state, accountUserId),
    hasFetched: accountUserSelectors.selectHasFetched(state),
    errors: accountUserSelectors.selectError(state),
    hasPermission: selectHasPermission(state),
    submissionStateAndEvent: selectSubmissionStateAndEvent(state),
    account: selectAccount(state),
  };
}

const mapDispatchToProps = {
  createUser: accountUserActions.create,
  createMedia: mediaActions.create,
  updateUser: accountUserActions.update,
  removeUser: accountUserActions.remove,
  sendInvite: accountUserActions.sendInvite,
  patchOnboarding,
  updateSubmittingState,
};

class EditUser extends React.PureComponent {
  uploaderRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      social_media: props.accountUser && props.accountUser.social_media,
      settings: props.accountUser && props.accountUser.settings,
      inviteSent: false,
    };
  }

  getCurrentUser = () => {
    const { accountUser } = this.props;
    return Object.assign({}, accountUser, this.state);
  };

  handleChange = (e) => {
    let update = {};
    update[e.target.name] = e.target.value;
    this.setState(update);
  };

  resizeTextArea = (e) => {
    e.target.style.minHeight = e.target.scrollHeight + 'px';
  };

  handleTextAreaChange = (e) => {
    this.handleChange(e);
    this.resizeTextArea(e);
  };

  handleRoleChange = (role) => {
    if (role === 'owner') this.setState({ makeOwner: true });
    else this.setState({ role: role, makeOwner: false });
  };

  handleMetaUpdate = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'social_media.twitter')
      this.setState({
        social_media: { ...this.state.social_media, twitter: value },
      });
    else if (name === 'social_media.telegram')
      this.setState({
        social_media: { ...this.state.social_media, telegram: value },
      });
    else if (name === 'settings.dynamic_author_page')
      this.setState({
        settings: {
          ...this.state.settings,
          dynamic_author_page: Boolean(value),
        },
        slug: Boolean(value) ? slugify(this.getCurrentUser().name) : null,
      });
    else this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    const { makeOwner } = this.state;
    const accountUser = this.getCurrentUser();
    const profile_image = accountUser.profile_image || {};
    const old_image = this.props.accountUser ? this.props.accountUser.profile_image : {};
    e.preventDefault();
    if (this.props.hasFetched) {
      if (
        profile_image &&
        (profile_image.id || profile_image.name) &&
        (!old_image || !old_image.id || profile_image.id !== old_image.id)
      ) {
        const action = this.props.createMedia(profile_image, 'profile');
        action.promise
          .then((media) => {
            const { id } = media;
            if (accountUser.id) {
              this.props.updateUser({ ...accountUser, profile_image_id: id });
            } else {
              this.props.createUser(this.props.siteId, {
                ...accountUser,
                profile_image_id: id,
              });
            }
          })
          .catch((errors) => console.error(errors));
      } else {
        if (accountUser.id) {
          if (
            makeOwner &&
            window.confirm('Are you sure you want to transfer ownership to this user? This cannot be undone')
          ) {
            const promise = new Promise((resolve, reject) => {
              this.props.updateUser(accountUser, resolve, reject);
            });
            promise.then(() => {
              if (this.state.makeOwner) {
                updateAccountOwner(this.getCurrentUser().id).then((response) => {
                  if (response.ok) window.location.reload();
                });
              }
            });
          } else if (!makeOwner) this.props.updateUser(accountUser);
        } else {
          this.props.createUser(this.props.siteId, accountUser);
          this.props.patchOnboarding({
            id: this.props.account.id,
            step: INVITE_COLLABORATORS_COMPLETE,
          });
        }
      }
    }
  };

  handleRemoveClick = (e) => {
    e.preventDefault();
    const accountUser = this.getCurrentUser();
    let name = accountUser.name ? accountUser.name : accountUser.email;

    if (window.top.Cypress) {
      this.props.removeUser(accountUser);
    } else {
      window.confirm(`Are you sure you want to delete ${name}?`) && this.props.removeUser(accountUser);
    }
  };

  render() {
    const { hasFetched, errors, hasPermission, submissionStateAndEvent, sendInvite, close } = this.props;

    // Assemble accountUser attributes for use in the form
    const accountUser = this.getCurrentUser();
    accountUser.social_media = accountUser.social_media || {};

    const id = accountUser && accountUser.id ? accountUser.id : null,
      name = accountUser && accountUser.name ? accountUser.name : '',
      email = accountUser && accountUser.email ? accountUser.email : '',
      role = this.state.makeOwner ? 'owner' : accountUser && accountUser.role ? accountUser.role : 'admin', // Default role to Admin
      bio = accountUser && accountUser.bio ? accountUser.bio : '',
      title = accountUser && accountUser.title ? accountUser.title : '',
      twitter = accountUser.social_media.twitter || '',
      telegram = accountUser.social_media.telegram || '',
      profile_image = accountUser.profile_image || null,
      status = accountUser.status || null,
      slug = accountUser.slug || '',
      settings = accountUser.settings || {},
      dynamic_author_page = idx(settings, 'dynamic_author_page');

    const roleDescription = roleMap.get(role) ? roleMap.get(role).description : '';

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          {this.props.accountUserId && (
            <fieldset>
              <label htmlFor="user_name_input">Name</label>
              <FormControl
                id="user_name_input"
                type="text"
                name="name"
                value={name}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength={65}
                autoFocus={!id}
                className="ph-redact-input"
              />
            </fieldset>
          )}
          <fieldset>
            <label htmlFor="user_email_input">Email</label>
            <FormControl
              id="user_email_input"
              type="text"
              name="email"
              value={email}
              onChange={this.handleChange}
              autoComplete="off"
              maxLength={65}
              disabled={!!id}
              className="ph-redact-input"
            />
            <ValidationMessage message={idx(errors, 'username')} />
          </fieldset>
          {hasPermission('AccountUser:create') && (
            <fieldset>
              <label htmlFor="user_role_input">Role</label>
              {role === 'owner' && !this.state.makeOwner ? (
                <input id="user_role_input" type="text" maxLength="65" value="Owner" disabled="disabled" />
              ) : (
                <select id="user_role_input" value={role} onChange={(e) => this.handleRoleChange(e.target.value)}>
                  {[
                    ...(hasPermission('AccountUser:update_owner', 'role') ? [roleOptions[0]] : []),
                    ...roleOptions.slice(1),
                  ].map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              )}
              <ValidationMessage message={this.state.rolePermissionError} />
              {roleDescription && <small className="role-description">{roleDescription}</small>}
            </fieldset>
          )}
          <fieldset>
            <label htmlFor="user_title_input">Title</label>
            <FormControl
              id="user_title_input"
              type="text"
              name="title"
              value={title}
              onChange={this.handleMetaUpdate}
              autoComplete="off"
            />
          </fieldset>
          <fieldset className={styles.imageUploader}>
            <label htmlFor="user_profile_image_input">Profile image</label>
            <div
              onClick={() => {
                if (this.uploaderRef.current) this.uploaderRef.current.click();
              }}
            >
              <span className={styles.fileName}>
                {profile_image
                  ? `Change image - ${profile_image.id ? profile_image.metadata.file_name : profile_image.name}`
                  : 'Choose image'}
              </span>
              <span
                className={styles.imageViewer + ' ph-no-capture'}
                style={{
                  ...(profile_image && {
                    backgroundImage: profile_image.id
                      ? `url(${profile_image.icon})`
                      : `url(${URL.createObjectURL(profile_image)})`,
                  }),
                }}
              />
            </div>
            {/* Invisible upload form */}
            <form>
              <input
                id="user_profile_image_input"
                className="fileInput"
                ref={this.uploaderRef}
                type="file"
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    profile_image: e.target.files[0],
                  });
                }}
                accept=".png,.gif,.jpg,.jpeg,.bmp,.x-icon,.svg,.svg+xml,"
              />
            </form>
          </fieldset>
          <fieldset>
            <label htmlFor="user_dynamic_author_page_input">Generate an author page</label>
            <FormControl
              id="user_dynamic_author_page_input"
              tag="select"
              name="settings.dynamic_author_page"
              value={dynamic_author_page || ''}
              onChange={this.handleMetaUpdate}
            >
              <option value="">No, do not create an author page</option>
              <option value="true">Yes, create author page and show my articles</option>
            </FormControl>
          </fieldset>
          <fieldset>
            <label htmlFor="user_author_page_slug_input">Set your author page slug</label>
            <SlugFormControl
              id="user_author_page_slug_input"
              tag={PrefixedInput}
              prefix={'/author/'}
              type="text"
              name="slug"
              value={slug}
              errors={dynamic_author_page ? idx(errors, 'slug') : null}
              onChange={(name, value) => this.setState({ [name]: value })}
              disabled={!dynamic_author_page}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="user_twitter_link_input">Twitter link</label>
            <FormControl
              id="user_twitter_link_input"
              type="text"
              name="social_media.twitter"
              value={twitter}
              onChange={this.handleMetaUpdate}
              autoComplete="off"
              className="ph-redact-input"
            />
          </fieldset>
          <fieldset>
            <label htmlFor="user_telegram_link_input">Telegram link</label>
            <FormControl
              id="user_telegram_link_input"
              type="text"
              name="social_media.telegram"
              value={telegram}
              onChange={this.handleMetaUpdate}
              autoComplete="off"
              className="ph-redact-input"
            />
          </fieldset>
          <fieldset>
            <label htmlFor="user_bio_input">Author bio</label>
            <FormControl
              id="user_bio_input"
              tag="textarea"
              name="bio"
              value={bio}
              onChange={this.handleTextAreaChange}
              onFocus={this.resizeTextArea}
              autoComplete="off"
              cols={65}
              rows={3}
              className="ph-redact-input"
            />
            <small>This will be displayed on blog posts published by this user.</small>
          </fieldset>
          <fieldset>
            {id ? (
              <input
                type="submit"
                aria-label="update user"
                className="button button-primary button-full"
                value={
                  submissionStateAndEvent.isSubmitting && submissionStateAndEvent.updating
                    ? 'Updating user...'
                    : 'Update user'
                }
                disabled={!hasFetched}
              />
            ) : (
              <>
                <input
                  aria-label="submit"
                  type="submit"
                  className="button button-success button-full"
                  value={
                    submissionStateAndEvent.isSubmitting && submissionStateAndEvent.creating
                      ? 'Sending invite...'
                      : 'Send invite'
                  }
                  disabled={!hasFetched}
                />
                <small>An email will be sent inviting them to join your Unstack account.</small>
              </>
            )}
            {hasPermission('AccountUser:discard') && id && role !== 'owner' && (
              <input
                type="button"
                className="button button-secondary button-full"
                value={
                  submissionStateAndEvent.isSubmitting && submissionStateAndEvent.removing
                    ? 'Removing user...'
                    : 'Remove user'
                }
                disabled={!hasFetched}
                onClick={this.handleRemoveClick}
              />
            )}
          </fieldset>
        </form>
        {status && status !== 'active' && hasPermission('Invitation:create') && (
          <div className={styles.resendActionLink}>
            <p>
              <a
                onClick={() => {
                  this.setState({ inviteSent: true });
                  sendInvite(id);
                }}
              >
                {this.state.inviteSent ? 'Invite resent to user!' : 'Re-send this invite'}
              </a>
            </p>
          </div>
        )}
      </>
    );
  }
}

export default compose(withSiteId, connect(mapStateToProps, mapDispatchToProps))(EditUser);
