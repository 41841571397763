import React, { LegacyRef, ChangeEvent, useState } from 'react';

import Select from './Select';
import classnames from 'classnames';
import MarginField from './MarginField';
import ColorSelector from './StyleGuideEditor/ColorSelector';
import CapitalizeSelect from './CapitalizeSelect';
import LetterSpacingField from './LetterSpacingField';
import { FontOptions, ColorOptions, Heading } from './types';

import { ReactComponent as SvgIconBigQuote } from '../../../assets/images/icon-quote.svg';
import { ReactComponent as SvgIconDesktopThumbnail } from '../../../assets/images/icon-desktop-styleguide.svg';
import { ReactComponent as SvgIconMobileThumbnail } from '../../../assets/images/icon-mobile-styleguide.svg';

import styles from './StyleGuideEditor.module.scss';

export default React.memo(
  ({
    fontFamilyOptions,
    styleGuide,
    fontWeightOptions,
    handleChange,
    handleChangeEvent,
    darkBgColor,
    ...props
  }: Heading) => {
    const fontStyleOptions = [
      { value: 'normal', label: 'Normal' },
      { value: 'italic', label: 'Italic' },
    ];
    const [activeStyleTabs, setActiveStyleTabs] = useState({
      quotes: 'text',
    });

    const [activeBackground, setActiveBackground] = useState({
      paragraph: 'light',
      highlight: 'light',
      quotes: 'light',
    });

    return (
      <>
        <header ref={props.scrollRef}>
          <h1>Text</h1>
          <small>Set the text styles used across your site.</small>
        </header>

        {/* Paragraph */}
        <article className={styles.StyleGuideEditor}>
          <section className={styles.styleSection}>
            <div className={styles.styleSectionHeader}>
              <p>Paragraph</p>
              <div className={styles.bgToggles}>
                <div className={styles.background}>
                  <span
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.paragraph === 'light',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, paragraph: 'light' })}
                  >
                    Light
                  </span>
                  <span
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.paragraph === 'dark',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, paragraph: 'dark' })}
                  >
                    Dark
                  </span>
                </div>

                <div className={styles.device}>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, paragraph: 'light' })}
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.paragraph === 'light',
                    })}
                  >
                    <SvgIconDesktopThumbnail />
                  </span>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, paragraph: 'mobile' })}
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.paragraph === 'mobile',
                    })}
                  >
                    <SvgIconMobileThumbnail />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`sriracha ${
                  activeBackground.paragraph === 'dark'
                    ? 'dark-bg p'
                    : activeBackground.paragraph === 'mobile'
                    ? 'mobile p'
                    : ''
                }`}
                style={{
                  padding: activeBackground.paragraph === 'dark' ? '1rem 2rem 0.5rem' : '0',
                  backgroundColor: activeBackground.paragraph === 'dark' ? darkBgColor.code : 'inherit',
                }}
              >
                <p
                  style={{
                    color:
                      activeBackground.paragraph === 'dark'
                        ? styleGuide.paragraph_dark_bg_color.code
                        : styleGuide.paragraph_color.code,
                  }}
                >
                  This is an example of a paragraph that can go anywhere. Make sure it looks good with both dark and
                  light backgrounds.
                </p>
              </div>
            </div>
            <div className={styles.styleSectionOptions}>
              {activeBackground.paragraph === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.paragraph_font_family_index]}
                      value={fontFamilyOptions[styleGuide.paragraph_font_family_index]}
                      onChange={(option: FontOptions) => handleChange(['paragraph', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight) => weight.value === styleGuide.paragraph_font_weight
                      )}
                      value={fontWeightOptions.find((weight) => weight.value === styleGuide.paragraph_font_weight)}
                      onChange={(option: FontOptions) => handleChange(['paragraph', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="paragraph_font_size"
                      value={styleGuide.paragraph_font_size}
                      min="0.8"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['paragraph', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="paragraph_line_height"
                      value={styleGuide.paragraph_line_height}
                      step="0.1"
                      min="0.8"
                      max="2"
                      onChange={(e) => handleChangeEvent(e, ['paragraph', 'line_height'])}
                    />
                  </div>
                  <MarginField
                    name="paragraph_margin"
                    value={styleGuide.paragraph_margin}
                    handleChange={(value) => handleChange(['paragraph', 'margin'], value)}
                  />
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="paragraph_color"
                      onChange={(value: ColorOptions) => handleChange(['paragraph', 'color'], value)}
                      value={styleGuide.paragraph_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) => handleChange(['paragraph', 'color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.paragraph === 'dark' ? (
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name="paragraph_dark_bg_color"
                    onChange={(value: ColorOptions) => handleChange(['paragraph', 'dark_bg_color'], value)}
                    value={styleGuide.paragraph_dark_bg_color}
                    placeholder="Color"
                    handleColorSelection={(value: ColorOptions) => handleChange(['paragraph', 'dark_bg_color'], value)}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="paragraph_font_size_mobile"
                      value={styleGuide.paragraph_font_size_mobile}
                      min="0.8"
                      max="2.5"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['paragraph', 'mobile', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="paragraph_line_height_mobile"
                      value={styleGuide.paragraph_line_height_mobile || styleGuide.paragraph_line_height}
                      step="0.1"
                      min="0.8"
                      max="2"
                      onChange={(e) => handleChangeEvent(e, ['paragraph', 'mobile', 'line_height'])}
                    />
                  </div>
                  <LetterSpacingField
                    name="paragraph_letter_spacing_mobile"
                    value={styleGuide.paragraph_letter_spacing_mobile || styleGuide.paragraph_letter_spacing}
                    handleChange={(value) => handleChange(['paragraph', 'mobile', 'letter_spacing'], value)}
                  />
                  <MarginField
                    name="paragraph_margin_mobile"
                    value={styleGuide.paragraph_margin_mobile || styleGuide.paragraph_margin}
                    handleChange={(value) => handleChange(['paragraph', 'mobile', 'margin'], value)}
                  />
                </>
              )}
            </div>
          </section>
        </article>

        {/* Highlight */}
        <article className={styles.StyleGuideEditor}>
          <section className={styles.styleSection}>
            <div className={styles.styleSectionHeader}>
              <p>Highlight</p>
              <div className={styles.background}>
                <span
                  className={classnames(styles.lightBackground, {
                    [styles.active]: activeBackground.highlight === 'light',
                  })}
                  onClick={() => setActiveBackground({ ...activeBackground, highlight: 'light' })}
                >
                  Light
                </span>
                <span
                  className={classnames(styles.darkBackground, {
                    [styles.active]: activeBackground.highlight === 'dark',
                  })}
                  onClick={() => setActiveBackground({ ...activeBackground, highlight: 'dark' })}
                >
                  Dark
                </span>
              </div>
            </div>
            <div>
              <div className="eps sriracha">
                <h2
                  className={activeBackground.highlight === 'dark' ? 'dark-bg' : undefined}
                  style={{
                    ...(activeBackground.highlight === 'dark' && {
                      backgroundColor: darkBgColor.code,
                      padding: '1rem 2rem 1rem',
                      color: '#FFF',
                      borderRadius: '2px',
                    }),
                  }}
                >
                  This is a header example with <mark>highlighted text</mark>
                </h2>
              </div>
            </div>
            <div className={styles.styleSectionOptions}>
              <div className={styles.styleOption}>
                <span>Color</span>
                {activeBackground.highlight === 'light' ? (
                  <ColorSelector
                    name="highlight_color"
                    onChange={(value: ColorOptions) => handleChange(['highlight_color'], value)}
                    value={styleGuide.highlight_color}
                    placeholder="Color"
                    handleColorSelection={(value: ColorOptions) => handleChange(['highlight_color'], value)}
                  />
                ) : (
                  <ColorSelector
                    name="highlight_color_dark_bg"
                    onChange={(value: ColorOptions) => handleChange(['highlight_color_dark_bg'], value)}
                    value={styleGuide.highlight_color_dark_bg}
                    placeholder="Color"
                    handleColorSelection={(value: ColorOptions) => handleChange(['highlight_color_dark_bg'], value)}
                  />
                )}
              </div>
            </div>
          </section>
        </article>

        {/* Big quote */}
        <article className={styles.StyleGuideEditor}>
          <section className={styles.styleSection}>
            <div className={styles.styleSectionHeader}>
              <p>Large quote</p>
              <div className={styles.bgToggles}>
                <div className={styles.background}>
                  <span
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.quotes === 'light',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, quotes: 'light' })}
                  >
                    Light
                  </span>
                  <span
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.quotes === 'dark',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, quotes: 'dark' })}
                  >
                    Dark
                  </span>
                </div>

                <div className={styles.device}>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, quotes: 'light' })}
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.quotes === 'light',
                    })}
                  >
                    <SvgIconDesktopThumbnail />
                  </span>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, quotes: 'mobile' })}
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.quotes === 'mobile',
                    })}
                  >
                    <SvgIconMobileThumbnail />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  ...(activeBackground.quotes === 'dark' && {
                    backgroundColor: darkBgColor.code,
                    padding: '1rem 2rem 0.5rem',
                  }),
                }}
                className="eps sriracha"
              >
                <section
                  className={
                    activeBackground.quotes === 'dark'
                      ? 'dark-bg'
                      : activeBackground.quotes === 'mobile'
                      ? 'mobile'
                      : ''
                  }
                >
                  <div className="quotes">
                    <blockquote style={{ position: 'relative', paddingLeft: '6rem' }}>
                      <SvgIconBigQuote className="large-quote-icon" style={{ position: 'absolute', left: 0 }} />
                      <p className="large-quote">This is an example quote.</p>
                    </blockquote>
                  </div>
                </section>
              </div>
            </div>
            <div className={styles.optionsHeader}>
              <span
                onClick={() => setActiveStyleTabs({ ...activeStyleTabs, quotes: 'text' })}
                className={classnames({
                  [styles.activeStyleTab]: activeStyleTabs.quotes === 'text',
                })}
              >
                Text
              </span>
              <span
                onClick={() => setActiveStyleTabs({ ...activeStyleTabs, quotes: 'mark' })}
                className={classnames({
                  [styles.activeStyleTab]: activeStyleTabs.quotes === 'mark',
                })}
              >
                Mark
              </span>
            </div>
            <div className={styles.styleSectionOptions}>
              {activeStyleTabs.quotes === 'text' ? (
                activeBackground.quotes === 'light' ? (
                  <>
                    <div className={styles.styleOption}>
                      <span>Font family</span>
                      <Select
                        options={fontFamilyOptions}
                        defaultValue={fontFamilyOptions[styleGuide.big_quote_font_family_index]}
                        value={fontFamilyOptions[styleGuide.big_quote_font_family_index]}
                        onChange={(option: FontOptions) =>
                          handleChange(['big_quote', 'font_family_index'], option.value)
                        }
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font Weight</span>
                      <Select
                        options={fontWeightOptions}
                        defaultValue={fontWeightOptions.find(
                          (weight) => weight.value === styleGuide.big_quote_font_weight
                        )}
                        value={fontWeightOptions.find((weight) => weight.value === styleGuide.big_quote_font_weight)}
                        onChange={(option: FontOptions) => handleChange(['big_quote', 'font_weight'], option.value)}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font size</span>
                      <input
                        type="number"
                        name="big_quote_font_size"
                        value={styleGuide.big_quote_font_size}
                        min="0.8"
                        max="10"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['big_quote', 'font_size'])}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font Style</span>
                      <Select
                        options={fontStyleOptions}
                        defaultValue={fontStyleOptions.find((style) => style.value === styleGuide.big_quote_font_style)}
                        value={fontStyleOptions.find((style) => style.value === styleGuide.big_quote_font_style)}
                        onChange={(option: FontOptions) => handleChange(['big_quote', 'font_style'], option.value)}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Line height</span>
                      <input
                        type="number"
                        name="big_quote_line_height"
                        value={styleGuide.big_quote_line_height}
                        min="0.8"
                        max="2"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['big_quote', 'line_height'])}
                      />
                    </div>
                    <CapitalizeSelect
                      value={styleGuide.big_quote_text_transform}
                      handleChange={(value) => handleChange(['big_quote', 'text_transform'], value)}
                    />
                    <LetterSpacingField
                      name="big_quote_letter_spacing"
                      value={styleGuide.big_quote_letter_spacing}
                      handleChange={(value) => handleChange(['big_quote', 'letter_spacing'], value)}
                    />
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="big_quote_color"
                        onChange={(value: ColorOptions) => handleChange(['big_quote', 'color'], value)}
                        value={styleGuide.big_quote_color}
                        placeholder="Color"
                        handleColorSelection={(value: ColorOptions) => handleChange(['big_quote', 'color'], value)}
                      />
                    </div>
                  </>
                ) : activeBackground.quotes === 'dark' ? (
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="big_quote_dark_bg_color"
                      onChange={(value: ColorOptions) => handleChange(['big_quote', 'dark_bg_color'], value)}
                      value={styleGuide.big_quote_dark_bg_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) =>
                        handleChange(['big_quote', 'dark_bg_color'], value)
                      }
                    />
                  </div>
                ) : (
                  <>
                    <div className={styles.styleOption}>
                      <span>Font size</span>
                      <input
                        type="number"
                        name="big_quote_font_size_mobile"
                        value={styleGuide.big_quote_font_size_mobile}
                        min="0.8"
                        max="10"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['big_quote', 'mobile', 'font_size'])}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Line height</span>
                      <input
                        type="number"
                        name="big_quote_line_height_mobile"
                        value={styleGuide.big_quote_line_height_mobile || styleGuide.big_quote_line_height}
                        min="0.8"
                        max="2"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['big_quote', 'mobile', 'line_height'])}
                      />
                    </div>
                    <LetterSpacingField
                      name="big_quote_letter_spacing_mobile"
                      value={styleGuide.big_quote_letter_spacing_mobile || styleGuide.big_quote_letter_spacing}
                      handleChange={(value) => handleChange(['big_quote', 'mobile', 'letter_spacing'], value)}
                    />
                  </>
                )
              ) : activeBackground.quotes === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Size</span>
                    <input
                      type="number"
                      name="big_quote_mark_size"
                      value={styleGuide.big_quote_mark_size}
                      min="0.8"
                      max="8"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['big_quote', 'mark_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="big_quote_mark_color"
                      onChange={(value: ColorOptions) => handleChange(['big_quote', 'mark_color'], value)}
                      value={styleGuide.big_quote_mark_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) => handleChange(['big_quote', 'mark_color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.quotes === 'dark' ? (
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name="big_quote_dark_bg_mark_color"
                    onChange={(value: ColorOptions) => handleChange(['big_quote', 'dark_bg_mark_color'], value)}
                    value={styleGuide.big_quote_dark_bg_mark_color}
                    placeholder="Color"
                    handleColorSelection={(value: ColorOptions) =>
                      handleChange(['big_quote', 'dark_bg_mark_color'], value)
                    }
                  />
                </div>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Size</span>
                    <input
                      type="number"
                      name="big_quote_mark_size_mobile"
                      value={styleGuide.big_quote_mark_size_mobile || styleGuide.big_quote_mark_size}
                      min="0.8"
                      max="8"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['big_quote', 'mobile', 'mark_size'])}
                    />
                  </div>
                </>
              )}
            </div>
          </section>
        </article>
      </>
    );
  }
);
