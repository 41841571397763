import React, { useEffect } from 'react';
import { compose } from 'redux';
import withSubscription from '../../containers/withSubscription';
import withUrlSite from '../../containers/withUrlSite';
import { connect } from 'react-redux';
import * as uiSubscriptionManagerActions from '../../actions/uiSubscriptionManagerActions';
import { selectLocalState as selectSubscriptionManagerProps } from '../../reducers/uiSubscriptionManagerReducer';
import { selectFeature } from '../../reducers/permissionsSelectors';
import * as subscriptionSelectors from '../../reducers/subscriptionReducer';
import * as subscriptionActions from '../../actions/subscriptionActions';
import { selectHasPermission } from '../../reducers/policyReducer';
import { Link } from 'react-router-dom';

import SideDrawer from '../base/SideDrawer';
import PaymentForm from './PaymentForm';

import styles from './SubscriptionManager.module.scss';
import { ReactComponent as SvgIconCheckmark } from '../../assets/images/icon-checkmark.svg';

function mapStateToProps(state) {
  return {
    getFeature: selectFeature,
    getPlanByTier: subscriptionSelectors.selectPlanByTier,
    currentPlanName: subscriptionSelectors.selectCurrentPlanName(state),
    hasPermission: selectHasPermission(state),
    ...selectSubscriptionManagerProps(state),
  };
}

const mapDispatchToProps = {
  close: uiSubscriptionManagerActions.close,
  requestPlan: subscriptionActions.requestSubscriptionProductsIfNeeded,
};

function SubscriptionDrawer(props) {
  useEffect(() => {
    props.requestPlan();
  }, []);
  const {
    isOpen,
    close,
    requestedAction,
    getFeature,
    getPlanByTier,
    currentPlanName,
    hasSubscription,
    hasPermission,
    upgradeSuccess,
    urlSite,
  } = props;

  if (upgradeSuccess) {
    return (
      <SideDrawer
        isOpen={isOpen}
        close={close}
        title={
          <span data-test-id="payment-success-label">
            <SvgIconCheckmark className={styles.checkmark} />
            Payment received
          </span>
        }
        titleTheme={upgradeSuccess ? 'success' : null}
        render={() => (
          <>
            <p className={styles.description} data-test-id="current-plan-message">
              You have successfully upgraded the payment plan on your Unstack account to{' '}
              <strong>{currentPlanName}</strong>.
            </p>
            <div>
              <button className="button button-primary button-full" onClick={(e) => close()}>
                Close
              </button>
              <Link className="button button-full" to={`/${urlSite}/settings`}>
                View payment details
              </Link>
            </div>
          </>
        )}
      />
    );
  } else {
    let title, description;

    if (requestedAction) {
      let feature = getFeature(requestedAction) || {
        name: 'this feature',
        actionDescription: 'use this feature',
        subscriptionTier: 2,
      };
      const subscriptionPlan = getPlanByTier(feature.subscriptionTier);

      title = `Upgrade to unlock ${feature.name}`;
      description = (
        <p className={styles.description}>
          You are currently on the {currentPlanName} plan. Upgrade to <strong>{subscriptionPlan.name}</strong> to{' '}
          {feature.actionDescription}.
        </p>
      );
    } else {
      title = 'Upgrade';
      if (hasSubscription) {
        description = <p className={styles.description}>You are currently on the {currentPlanName} plan.</p>;
      }
    }

    return (
      <SideDrawer
        isOpen={isOpen}
        close={close}
        title={title}
        titleTheme={upgradeSuccess ? 'success' : null}
        render={() => (
          <>
            {description && description}

            {hasPermission('AccountSubscription:list') ? (
              hasSubscription ? (
                <a
                  className="button button-full"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.unstack.com/hc/en-us/requests/new?tf_subject=Requesting+Subscription+Change"
                >
                  Contact us to change your plan
                </a>
              ) : (
                <PaymentForm />
              )
            ) : (
              <p className={styles.description}>Ask your Unstack administrator to upgrade.</p>
            )}
          </>
        )}
      />
    );
  }
}

export default compose(withSubscription, withUrlSite, connect(mapStateToProps, mapDispatchToProps))(SubscriptionDrawer);
